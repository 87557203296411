.planning_course {
  margin: 0 0 15px 0;
  padding: 15px;
  display: flex;
  flex-direction: column;
  width: calc(50% - 30px);
  flex: 0 0 calc(50% - 30px);
  min-width: 240px;
  border-radius: 30px;
  background: #1D1E25;

  .title_index {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .h1 {
      font-size: 28px;
      font-weight: 500;
    }

    .buttons {
      display: flex;
      align-items: center;

      .btn {
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 49px;
        height: 50px;
        border-radius: 50%;
        background: var(--dark);
        cursor: pointer;

        &.remove {
          background: #CF1A1A;
        }

        &:hover, &.active {
          svg {
            path {
              fill: #FFD23B;
              stroke: #FFD23B;
            }
          }
        }
      }
    }
  }

  .difficulty_level {
    padding: 15px;
    margin-bottom: 15px;
    display: flex;
    border-radius: 24px;
    background: var(--dark);

    div {
      margin-right: 15px;
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
      }

      span {
        font-size: 16px;

        &:nth-child(2) {
          margin-right: 3px;
        }

        &:last-child {
          color: var(--text_color_dark);
        }
      }
    }
  }

  .steps {
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 24px;
    background: var(--dark);

    pre {
      max-height: 400px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  &.btn {
    position: relative;
    min-height: 645px;
    align-items: center;
    justify-content: center;

    .title_index {
      position: absolute;
      top: 20px;
      left: 15px;
    }
  }

  &:nth-child(2n+2) {
    margin-right: 0;
  }
}

.ant-modal-body .planning_course {
  width: 100%;
  .difficulty_level span {
    font-size: 25px;
  }
  pre {
    font-size: 25px;
  }
}
@media (max-width: 820px) {
  .planning_course {
    margin: 0 auto 15px auto !important;
    width: 100%;
    max-width: 100%;
    min-width: 350px;
    border-radius: 24px;

    .buttons {
      .btn {
        max-width: 30px;
        max-height: 30px;

        svg {
          max-width: 15px;
          max-height: 15px;
        }
      }
    }

    .title_index {
      .h1 {
        font-size: 22px;
      }
    }

    .difficulty_level, .steps {
      border-radius: 16px;
    }

    &.full_width {
      max-width: 100% !important;
      min-width: calc(100% - 10px) !important;
    }
  }
}
