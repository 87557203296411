@use '../../../../styles/Variables';

@media (max-width: 820px) {
  .reuse_modal.course {
    .title {
      font-size: 24px !important;
      line-height: 29px !important;
      margin-bottom: 10px !important;

      &_info {
        font-size: 12px !important;
        border: none !important;
      }
    }

    .sort {
      display: none !important;
    }

    .sort_mobile {
      display: block !important;
    }

    .traektory {
      &_title {
        margin-top: 15px !important;
        font-size: 16px !important;
        line-height: 16px !important;
      }
    }

    .close_title {
      width: 24px;
      height: 24px;
    }

    .ant-modal-body {
      padding: 20px 15px !important;
    }
  }
}

@media (max-width: 710px) {
  .reuse_modal.course {
    .course_list {
      li {
        margin-right: 0 !important
      }
    }
  }
}

.reuse_modal.course {

  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }

  .ant-modal-body {
    margin: 0 15px !important;
    background: #201E26 !important;
    box-shadow: none;
  }

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    font-size: 40px;
    line-height: 48px;

    &_info {
      width: 100%;
      padding-bottom: 20px;
      font-size: 16px;
      line-height: 19px;
      color: rgba(255, 255, 255, 0.5);
      border-bottom: 1px solid #464C5A;
    }

    img {
      cursor: pointer;
    }
  }

  .sort {
    width: 100%;
    margin-top: 14px;
    margin-bottom: 14px;
    padding-bottom: 20px;
    font-size: 16px;
    border-bottom: 1px solid #464C5A;

    ul, li {
      padding: 0;
      margin: 0;
    }

    li {
      display: inline-block;
      cursor: pointer;

      &:not(first-child) {
        margin-left: 20px;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.5)
      }

      &.active {
        color: #B3DAFF;
      }
    }
  }

  .sort_mobile {
    position: relative;
    padding: 8px 15px;
    background: #343843;
    border-radius: 17px;
    cursor: pointer;

    svg {
      position: absolute;
      top: 17px;
      right: 15px;

      &.active {
        transform: rotate(180deg);
      }
    }

    &.active {
      border-radius: 17px 17px 0 0;
    }
  }

  .sort_mobile-drop-down {
    max-height: 0;
    opacity: 0;
    // transform: scale(0);
    transition: all .1s ease;

    ul, li {
      padding: 0;
      margin: 0;
    }

    li {
      padding: 6px 15px;
      background-color: #4B505C;

      &:hover {
        background-color: lighten(#4B505C, 5%);
      }

      &:last-child {
        border-radius: 0 0 16px 16px;
      }
    }

    &.active {
      max-height: fit-content;
      opacity: 1;

      li {
        cursor: pointer;
      }

      // transform: scale(1);
    }
  }

  .traektory {

    &_title {
      margin-bottom: 15px;
      font-size: 24px;
      line-height: 29px;
    }

    &_basket {
      padding: 12px 12px 2px 12px;
      margin-bottom: 30px;
      max-height: 140px;
      overflow: auto;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      background: #343843;
      border-radius: 20px;

      .skills {
        padding: 4px 5px 5px 12px;
        margin: 0 5px 10px 0;
        position: relative;
        display: flex;
        align-items: center;
        width: fit-content;
        background: rgba(22, 21, 27, 0.65);
        border-radius: 29px;

        span {
          color: white;
        }

        img {
          margin-left: 5px;
          cursor: pointer;
        }
      }
    }
  }

  .course_list_modal {
    margin-left: 0;
    max-width: 1304px;
    width: 100% !important;

    ul, li {
      padding: 0;
      margin: 0;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }

    .reuse_course-item {
      margin: 0 20px 10px 0;
      min-height: 550px;
    }

    .pagination_all {
      margin: 0 auto;

      ul {
        display: block;
      }

      .ant-pagination-item {
        margin-right: 5px;
        background-color: transparent;
        border: none;

        a {
          border-radius: 8px !important;
          color: white;
          background-color: var(--dark);
        }

        &-active {
          a {
            background-color: lighten(#343843, 20%);
          }

          border-color: transparent;
        }
      }

      li.ant-pagination-options {
        display: none;
      }

      li.ant-pagination-prev,
      li.ant-pagination-next {
        display: none;
      }

      span.ant-pagination-item-ellipsis,
      span.anticon.anticon-double-right.ant-pagination-item-link-icon,
      span.anticon.anticon-double-left.ant-pagination-item-link-icon {
        color: #61687c;
      }
    }

    .ant-spin.ant-spin-spinning {
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}