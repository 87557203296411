@use 'src/styles/Variables';

@media (max-width: 950px) {
  .parent_resume,
  .parent_resume-pdf {
    .resume {
      padding-bottom: 60px !important;
      flex-direction: column !important;

      .left_block,
      .right_block {
        max-width: 100% !important;
        margin: 0 auto !important;
      }
    }
  }
}

@media (max-width: 420px) {
  .parent_resume,
  .parent_resume-pdf {
    .resume {
      flex-direction: column !important;

      .right_block {
        .title {
          padding-bottom: 15px !important;
          margin-bottom: 15px !important;
          font-size: 26px !important;
          line-height: 30px !important;
        }
      }

      .photo_block {
        display: flex;
        flex-direction: column;

        .img {
          margin-left: auto !important;
          margin-right: auto !important;
        }
      }
    }
  }
}

.parent_resume {
  margin: 0 auto;
  padding: 0;
  max-width: 1310px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--dark);

  .resume {
    display: flex;
    color: white;
    max-width: 1310px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;

    .left_block {
      padding: 15px;
      width: 100%;
      max-width: 310px;
      background: #201E26;
      overflow-x: hidden;
      overflow-y: hidden;

      .roadmap {
        margin-bottom: 20px;
        display: flex;

        img {
          margin-right: 10px;
        }

        span {
          margin-right: 10px;
          font-size: 12px;
          line-height: 14px;
          white-space: nowrap;
          color: #757A88;
        }

        svg {
          margin-right: 10px;
          align-self: center;

          path {
            stroke: #757A88;
          }
        }

        span:nth-child(2) {
          cursor: pointer;
        }

        span:last-child {
          color: white;
        }
      }

      .h4 {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 24px;
      }

      .photo_block {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--dark);

        .img {
          margin-bottom: 20px;
          width: 280px;
          height: 280px;
          border-radius: 25px;
        }

        div {
          display: flex;
          margin-bottom: 20px;

          img {
            margin-right: 15px;
            align-self: center;
          }

          span {
            font-size: 16px;
            line-height: 19px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .info_block {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--dark);

        div {
          display: flex;
          margin-bottom: 15px;

          img {
            max-width: 23px;
            height: 23px;
            margin-right: 15px;
            align-self: center;
            border-radius: 6px;
          }

          span {
            font-size: 16px;
            line-height: 19px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .languages_block {
        margin-bottom: 20px;
        padding-bottom: 30px;
        border-bottom: 1px solid var(--dark);

        .lang {
          display: flex;
          margin-bottom: 25px;
          position: relative;

          .span {
            margin-right: 30px;
            max-width: 75px;
            min-width: 62px;
            width: 100%;
            font-size: 16px;
            line-height: 19px;
          }

          .level {
            margin-top: 5px;
            margin-left: auto;

            .figure_block {
              position: relative;
              display: flex;

              .figure_off {
                margin-right: 4px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: var(--background_lighten-dark);
              }

              .figure_on {
                position: absolute;
                top: 0;
                left: 0;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: white;
              }
            }
          }

          .current_label-level {
            position: absolute;
            top: 16px;
            right: 2px;
            font-size: 12px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .skills_block {
        .parent_skills {
          display: flex;
          flex-wrap: wrap;

          .skill {
            padding: 6px 12px 4px 12px;
            margin-right: 5px;
            margin-bottom: 5px;
            height: 31px;
            font-size: 14px;
            line-height: 17px;
            color: var(--text_color_dark);
            background: rgba(162, 170, 202, 0.2);
            border-radius: 34px;
          }
        }
      }
    }

    .right_block {
      padding: 20px 15px 5px 25px;
      background-color: var(--dark);
      max-width: 960px;
      width: 100%;

      .title {
        margin-bottom: 30px;
        padding-bottom: 30px;
        font-weight: 600;
        font-size: 48px;
        line-height: 57px;
        overflow-wrap: break-word;
        border-bottom: 1px solid var(--background_lighten-dark);
      }

      .name_resume,
      .experience_title,
      .experience_date {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;

        span:first-child {
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
        }

        span:last-child {
          align-self: end;
          font-size: 16px;
          line-height: 19px;
        }
      }

      .name_resume {
        margin-bottom: 5px;
      }

      .experience_title {
        margin-bottom: 20px;
      }

      .experience_group {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .experience {
          display: flex;
          flex-direction: column;
          margin-right: 25px;
          max-width: 30%;
          width: 100%;

          span {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;

            &:last-child {
              font-size: 18px;
              color: var(--text_color_dark);
            }
          }

          span:first-child {
            margin-bottom: 10px;
          }
        }

        .function_job {
          display: flex;
          flex-direction: column;
          max-width: 70%;
          width: 100%;

          span {
            margin-bottom: 10px;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
          }

          span:nth-child(2) {
            margin-bottom: 0;
            font-size: 16px;
            line-height: 19px;
            color: var(--text_color_dark);
          }

          .h4 {
            margin: 15px 0;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
          }

          .list_job-funtions {
            margin: 0;
            padding: 0;

            li {
              margin-bottom: 10px;
              padding: 0;
              font-size: 16px;
              line-height: 19px;
              color: var(--text_color_dark);
            }
          }
        }
      }

      .h4 {
        margin-bottom: 30px;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
      }

      .education_group {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .type_education {
          display: flex;
          flex-direction: column;
          margin-right: 25px;
          max-width: 30%;
          width: 100%;

          span {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;

            &:last-child {
              margin-bottom: 10px;
              font-size: 18px;
              color: var(--text_color_dark);
            }
          }
        }

        .info_education {
          display: flex;
          flex-direction: column;
          max-width: 70%;
          width: 100%;

          span {
            margin-bottom: 10px;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
          }

          span:nth-child(2),
          span:nth-child(3) {
            font-size: 16px;
            line-height: 19px;
            color: var(--text_color_dark);
          }

          span:nth-child(3) {
            margin-bottom: 0;
          }
        }
      }

      .course_group {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .type_course {
          display: flex;
          flex-direction: column;
          margin-right: 25px;
          max-width: 30%;
          width: 100%;

          span {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
          }
        }

        .info_course {
          display: flex;
          flex-direction: column;
          max-width: 70%;
          width: 100%;

          span {
            margin-bottom: 10px;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
          }

          span:nth-child(2) {
            margin-bottom: 0;
            font-size: 16px;
            line-height: 19px;
            color: var(--text_color_dark);
          }
        }
      }

      .portfolio_group {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;

        span {
          margin-bottom: 15px;
          color: var(--text_color_dark);
          font-size: 20px;
          line-height: 24px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .about_group {
        span {
          color: var(--text_color_dark);
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }
}

.parent_resume-pdf {
  padding: 0;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: var(--dark);

  .resume {
    display: flex;
    color: white;
    margin: 0 auto;
    max-width: 1300px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;

    .left_block {
      padding: 15px;
      width: 100%;
      max-width: 310px;
      background: #201E26;
      overflow-x: hidden;
      overflow-y: hidden;

      .roadmap {
        margin-bottom: 20px;
        display: flex;

        img {
          margin-right: 10px;
        }

        span {
          margin-right: 10px;
          font-size: 12px;
          line-height: 14px;
          white-space: nowrap;
          color: #757A88;
        }

        svg {
          margin-right: 10px;
          align-self: center;

          path {
            stroke: #757A88;
          }
        }

        span:nth-child(2) {
          cursor: pointer;
        }

        span:last-child {
          color: white;
        }
      }

      .photo_block {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--dark);

        .img {
          margin-bottom: 20px;
          width: 280px;
          height: 280px;
          border-radius: 25px;
        }

        div {
          display: flex;
          margin-bottom: 20px;

          img {
            margin-right: 15px;
            align-self: center;
          }

          span {
            font-size: 16px;
            line-height: 19px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .info_block {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--dark);

        div {
          display: flex;
          margin-bottom: 15px;

          img {
            max-width: 23px;
            height: 23px;
            margin-right: 15px;
            align-self: center;
            border-radius: 6px;
          }

          span {
            font-size: 16px;
            line-height: 19px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .languages_block {
        margin-bottom: 20px;
        padding-bottom: 30px;
        border-bottom: 1px solid var(--dark);

        .lang {
          display: flex;
          margin-bottom: 25px;
          position: relative;

          .span {
            margin-right: 30px;
            max-width: 75px;
            min-width: 62px;
            width: 100%;
            font-size: 16px;
            line-height: 19px;
          }

          .level {
            margin-top: 5px;
            margin-left: auto;

            .figure_block {
              position: relative;
              display: flex;

              .figure_off {
                margin-right: 4px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: var(--background_lighten-dark);
              }

              .figure_on {
                position: absolute;
                top: 0;
                left: 0;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: white;
              }
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }


      }

      .skills_block {
        .parent_skills {
          display: flex;
          flex-wrap: wrap;

          .skill {
            padding: 6px 12px 4px 12px;
            margin-right: 5px;
            margin-bottom: 5px;
            height: 31px;
            font-size: 14px;
            line-height: 17px;
            color: var(--text_color_dark);
            background: rgba(162, 170, 202, 0.2);
            border-radius: 34px;
          }
        }
      }

      .h4 {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 24px;
      }
    }

    .right_block {
      padding: 20px 15px 5px 25px;
      background-color: var(--dark);
      max-width: 960px;
      width: 100%;

      .title {
        margin-bottom: 30px;
        padding-bottom: 30px;
        font-weight: 600;
        font-size: 48px;
        line-height: 57px;
        overflow-wrap: break-word;
        border-bottom: 1px solid var(--background_lighten-dark);
      }

      .name_resume,
      .experience_title,
      .experience_date {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;

        span:first-child {
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
        }

        span:last-child {
          align-self: end;
          font-size: 16px;
          line-height: 19px;
        }
      }

      .name_resume {
        margin-bottom: 5px;
      }

      .experience_title {
        margin-bottom: 20px;
      }

      .experience_group {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .experience {
          display: flex;
          flex-direction: column;
          margin-right: 25px;
          max-width: 30%;
          width: 100%;

          span {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;

            &:last-child {
              font-size: 18px;
              color: var(--text_color_dark);
            }
          }

          span:first-child {
            margin-bottom: 10px;
          }
        }

        .function_job {
          display: flex;
          flex-direction: column;
          max-width: 70%;
          width: 100%;

          span {
            margin-bottom: 10px;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
          }

          span:nth-child(2) {
            margin-bottom: 0;
            font-size: 16px;
            line-height: 19px;
            color: var(--text_color_dark);
          }

          .h4 {
            margin: 15px 0;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
          }

          .list_job-funtions {
            margin: 0;
            padding: 0;

            li {
              margin-bottom: 10px;
              padding: 0;
              font-size: 16px;
              line-height: 19px;
              color: var(--text_color_dark);
            }
          }
        }
      }

      .h4 {
        margin-bottom: 30px;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
      }

      .education_group {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .type_education {
          display: flex;
          flex-direction: column;
          margin-right: 25px;
          max-width: 30%;
          width: 100%;

          span {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;

            &:last-child {
              margin-bottom: 10px;
              font-size: 18px;
              color: var(--text_color_dark);
            }
          }
        }

        .info_education {
          display: flex;
          flex-direction: column;
          max-width: 70%;
          width: 100%;

          span {
            margin-bottom: 10px;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
          }

          span:nth-child(2),
          span:nth-child(3) {
            font-size: 16px;
            line-height: 19px;
            color: var(--text_color_dark);
          }

          span:nth-child(3) {
            margin-bottom: 0;
          }
        }
      }

      .course_group {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .type_course {
          display: flex;
          flex-direction: column;
          margin-right: 25px;
          max-width: 30%;
          width: 100%;

          span {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
          }
        }

        .info_course {
          display: flex;
          flex-direction: column;
          max-width: 70%;
          width: 100%;

          span {
            margin-bottom: 10px;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
          }

          span:nth-child(2) {
            margin-bottom: 0;
            font-size: 16px;
            line-height: 19px;
            color: var(--text_color_dark);
          }
        }
      }

      .portfolio_group {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;

        span {
          margin-bottom: 15px;
          color: var(--text_color_dark);
          font-size: 20px;
          line-height: 24px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .about_group {
        span {
          color: var(--text_color_dark);
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }
}
