@use '../../../styles/Variables';

@media (max-width: 1495px) {
  .new_profile {
    .btn_save-resume {
      right: 5% !important;
    }
  }
}

@media (max-width: 1350px) {
  .new_profile {
    .resume_page {
      flex-direction: column;

      .freelance_section {
        margin-left: 0 !important;
        margin-bottom: 30px !important;
        max-width: 580px !important;
        width: 100% !important;
        order: -1;

        .category_list,
        .freelancers_list {
          margin-left: auto !important;
          margin-right: auto !important;
          max-width: 320px !important;
          width: 100% !important;
        }

        .freelancers_list {
          max-width: 320px !important;
          width: 100% !important;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .new_profile {
    .info_profile {
      min-width: 354px !important;
      max-width: 354px !important;
    }

    .buttons_group {
      .btn {
        &:not(:first-child) {
          margin-left: 10px !important;
        }
      }
    }
  }
}

@media (max-width: 820px) {
  .new_profile {
    flex-direction: column !important;

    .info_profile {
      margin-left: 0 !important;
      margin-top: 15px !important;
      padding: 15px 15px 100px 15px !important;
      min-width: inherit !important;
      /* display: none !important;*/

      &.mobile {
        display: block !important;
        padding: 15px !important;
        min-height: inherit !important;
        min-width: inherit !important;

        .category {
          .title {
            justify-content: initial !important;
          }
        }
      }
    }

    .top_filter-mobile {
      max-height: 62px !important;
      padding: 5px 5px !important;
      display: flex !important;
      max-width: 100% !important;
    }

    .resume_page {
      margin: 0 !important;
      max-height: initial !important;
      min-height: initial !important;
      overflow-y: initial !important;

      .h1 {
        margin-top: 10px !important;
        padding-bottom: 15px !important;
      }

      .h2 {
        text-align: center !important;
      }

      .resume_section, .freelance_section {
        margin: 0 auto !important;
      }

      .resume_section {
        margin-top: 30px !important;
      }

      .portfolio,
      .profession_skills,
      .about {
        margin-left: auto !important;
        margin-right: auto !important;
      }

      .direction_activity {
        margin: 0 auto !important;

        .freelancers_list {
          margin-top: 15px;

          li {
            margin: 0 auto !important;
          }
        }
      }

      .save_resume {
        max-width: 580px !important;
        width: 100% !important;
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }

    .btn_save-resume {
      bottom: 100px !important;
    }
  }
}

@media (max-width: 620px) {
  .new_profile {
    .resume_page {

      .reuse_input-parent,
      .reuse_select-parent {
        max-width: 100% !important;

        .drop_down {
          max-width: 100% !important;
        }

        .top_row {
          .reuse_input-parent {
            &:first-child {
              min-width: 265px !important;
            }
          }
        }
      }

      .h2 {
        margin: 20px 15px !important
      }

      .resume {
        padding: 15px !important;
        border-radius: 20px !important;

        .top_row {
          flex-direction: column !important;

          .reuse_input-parent {
            &:first-child {
              margin-bottom: 20px !important;
            }
          }
        }

        .photo_row {
          display: block !important;
          margin-bottom: 0 !important;

          .photo {
            margin-bottom: 20px;

            img {
              width: 100% !important;
              height: auto !important;
            }
          }

          .right_block {
            margin-left: 0 !important;

            .reuse_input-parent {
              margin-bottom: 20px !important;

              &:last-child {
                margin-bottom: 20px !important;
              }
            }
          }
        }

        .birthday_row {
          margin-bottom: 20px !important;

          .month_dropdown {
            left: 0 !important;
          }
        }

        .salary_row {
          margin-bottom: 20px !important;
          display: block !important;

          .salary {
            margin-bottom: 20px !important;
            max-width: inherit !important;

          }
        }

        .employment_row {
          display: block !important;
          margin-bottom: 20px !important;
          max-width: 100% !important;

          .reuse_select-parent {
            margin-bottom: 20px !important;
          }
        }

        .relocation_mobile-group {
          display: block !important;

          .relocation_row-mobile,
          .driver_license-row-mobile,
          .marital_status-row-mobile {
            margin-bottom: 20px !important;

            .drop_down {
              max-width: 100% !important;
            }
          }

          .checkbox_group {
            margin: 0 0 20px 0 !important;
            padding-left: 0 !important;
            max-width: 100% !important;

            .form-group {
              margin-bottom: 0 !important;
            }

            &:last-child {
              margin-bottom: 0 !important;
            }
          }
        }

        .relocation_row,
        .driver_license-row,
        .marital_status-row {
          display: none !important;
        }
      }

      .profession_skills {
        padding: 15px !important;
        margin-bottom: 20px !important;
        border-radius: 20px !important;

        .input_classic {
          margin-bottom: 20px !important;
        }

        .profession_skills-row {
          margin-bottom: 20px !important;
        }

        .entered_profession-skills-row {
          display: block !important;
        }

        .reuse_select-parent {
          margin-bottom: 20px !important;
        }
      }

      .portfolio {
        padding: 15px !important;
        margin-bottom: 20px !important;
        border-radius: 20px !important;

        .input_classic {
          margin-bottom: 20px !important;
        }
      }

      .about {
        padding: 15px !important;
        margin-bottom: 20px !important;
        border-radius: 20px !important;
      }
    }
  }
}

@media (max-width: 500px) {
  .new_profile {
    .resume_page {

      .checkbox_group {
        max-width: initial !important;

        &:last-child {
          margin-bottom: 10px !important;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .new_profile {
    .buttons_group {
      img {
        display: none !important;
      }

      .btn_drop {
        padding: 5px !important;
      }

      .drop_share,
      .btn {
        span {
          margin: 0 auto !important;
        }
      }
    }
  }
  .modal_profile-mobile {
    margin: -75px 15px 120px 15px !important;
    max-width: calc(100% - 30px) !important;
  }
}

@media (max-width: 383px) {
  .new_profile {
    .resume_page {
      .photo_row {
        .reuse_input-parent {
          .title {
            max-width: 125px !important;
          }
        }

        .city_parent {
          .title_city {
            max-width: 125px !important;
            white-space: initial !important;
          }
        }
      }
    }

    .photo {
      img {
        width: 100% !important;
        height: auto !important;
      }

      label {
        padding: 3px !important;
        width: calc(100% - 30px) !important;
        text-align: center;

      }
    }


    .top_filter-mobile {
      .filters_mobile {
        .btn:not(:first-child) {
          margin-left: 5px !important;
        }
      }
    }

    .birthday_row {

      .day,
      .month,
      .year {
        svg {
          right: 0 !important;
        }
      }
    }
  }
}

@media (max-width: 376px) {
  .new_profile {
    .salary {
      .currencies {
        margin-top: 38px !important;
      }
    }
  }
}

@keyframes height {
  0% {
    display: block;
    max-height: 20px;
    opacity: 0.3;
    transform: scale(0.5);
  }

  50% {
    max-height: 100px;
    opacity: 0.7;
    transform: scale(1);
    display: block;
  }

  100% {
    max-height: 150px;
    opacity: 1;
    display: block;
  }
}

@keyframes hidden {
  0% {
    max-height: 120px;
    opacity: 0.7;
    transform: scale(1);
  }

  50% {
    max-height: 80px;
    opacity: 0.7;
  }

  100% {
    max-height: 0;
    opacity: 0;
    transform: scale(0);
  }
}

.new_profile {
  display: flex;
  width: 100%;
  color: white;

  .title {
    line-height: 16px;
  }

  .info_profile {
    padding: 20px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    min-width: 404px;
    max-width: 404px;
    background: #201E26;
    box-shadow: 0 20px 100px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    overflow-y: auto;

    .h3 {
      margin-top: auto;
      margin-bottom: 15px;
      font-size: 20px;
      line-height: 24px;
      color: var(--text_color_dark);
    }

    .skill_section {
      position: relative;
      padding-bottom: 5px;
      margin-bottom: 15px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      z-index: 0;

      .title {
        display: flex;
        margin-bottom: 15px;

        img {
          margin-right: 15px;
          align-self: center;
        }

        span {
          font-size: 16px;
          line-height: 19px;
        }
      }

      .category {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: 10px;
        max-width: 360px;
        width: 100%;
        cursor: pointer;
        transition: all .3s;
        z-index: 6;

        .title {
          display: flex;
          align-items: center;

          .img_block {
            margin-right: 15px;
            position: relative;
            width: 40px;
            height: 24px;
            border-radius: 20px;

            img {
              position: absolute;
              top: 4px;
              left: 12px;
              vertical-align: middle;
            }
          }

          span {
            font-size: 16px;
            line-height: 21px;
          }

          svg {
            position: absolute;
            right: 0;
            top: 8px;
            transition: all .3s;

            &.arrow {
              circle {
                fill: white;
              }
            }

            &.open {
              transform: rotate(180deg);
            }
          }

        }

        .select_block {
          width: 100%;
          position: relative;
          z-index: 5;

          .parent_tag {
            position: absolute;
            width: 100%;
            padding-top: 15px;
            top: 20px;
            left: 0;
            opacity: 0;
            max-height: 0;
            visibility: hidden;
            z-index: -1;
            background-color: var(--background_lighten-dark);
            border-radius: 0 0 10px 10px;
            transition: all .3s;
            overflow-y: auto;

            .tag {
              padding: 3px 0 3px 15px;
              cursor: pointer;

              &:hover {
                background-color: darken(#474c5a, 10%);
              }

              &:last-child {
                &:hover {
                  border-radius: 0 0 10px 10px;
                }
              }
            }

            &.open {
              transition: all .3s;
              opacity: 1;

              max-height: 155px;
              visibility: initial;
              z-index: 4;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 9em;
              background: var(--dark);
            }
          }

          .profile_icon {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            top: 50%;
            transform: translateY(-50%);
            left: 5px;
            background: #1D1E25;
            border-radius: 10px;
            z-index: 10;
          }

          .spin_loading {
            position: absolute;
            top: 7px;
            right: 10px;
            z-index: 10;
          }

          .img_add {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 5px;
            cursor: pointer;
          }

          &.open {
            position: relative;
            opacity: 1;
            max-height: inherit;
            visibility: initial;

          }
        }
      }

      .dropdown_skills {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        line-height: 17px;
        overflow-y: scroll;
        color: white;
        background: var(--dark);
        border: 1px solid var(--background_lighten-dark);
        border-radius: 20px;
        transition: max-height .3s, padding .5s, margin .8s, opacity .3s;

        .tag {
          padding: 3px 12px 5px 12px;
          margin: 0 5px 5px 0;
          width: fit-content;
          background: rgba(22, 21, 27, 0.65);
          border: 1px solid transparent;
          /*border-radius: 34px;*/
          border-radius: 12px;

          &.added {
            position: relative;
            padding-right: 33px;
            border-color: #FFD23B;

            img {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 2px;
              z-index: 4;
            }
          }
        }

        &.open {
          padding: 15px 15px 10px 15px;
          margin-bottom: 25px;
          opacity: 1;
          transition: max-height .3s, margin .5s, opacity .3s;
          max-height: 285px;
          overflow-y: scroll;
        }
      }

      &.z_index {
        z-index: 10;
      }

      &.open {
        z-index: 6;
      }
    }

    .radar_parent {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      background: var(--dark);
      border-radius: 20px;

      .chart_block {
        padding: 0 10px;
      }

      canvas {
        position: relative;
        z-index: 4;
      }

      .category_list {
        padding: 20px 15px;
        background: #16151B;
        border-radius: 20px;

        .list {
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:not(:last-child) {
            margin-bottom: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid var(--background_lighten-dark);
          }

        }

        .category {
          display: flex;
          align-items: center;

          .figure {
            margin-right: 8px;
            width: 11px;
            height: 11px;
            border-radius: 50%;
          }

          span {
            font-size: 13px;
            line-height: 16px;
          }

          &:not(:last-child) {
            margin-right: 14px;
          }
        }
      }
    }
  }

  .resume_page {
    display: flex;
    //max-width: 1310px;
    width: calc(100% - 404px);
    max-height: calc(100vh - 30px) !important;
    min-height: calc(100vh - 30px) !important;
    overflow-y: auto;

    .h1 {
      padding-bottom: 20px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      font-size: 24px;
      line-height: 29px;
      border-bottom: 1px solid var(--background_lighten-dark);

      div {
        margin-left: 10px;
        color: #757A88;
        font-size: 22px;
        line-height: 26px;
      }
    }

    .resume_section {
      //max-width: 580px;
      width: calc(100% - 360px);
    }

    .freelance_section {
      margin-left: 15px;
      max-width: 360px;
      width: 100%;

      .category_list {
        padding: 20px;
        position: relative;
        border-radius: 30px;
        background: #1D1E25;

        .title {
          margin-bottom: 17px;
          font-size: 16px;
          line-height: 19px;
        }

        .active_category {
          padding: 5px 18px 5px 15px;
          display: flex;
          position: relative;
          // box-shadow: 0px 9px 20px rgba(129, 70, 255, 0.45);
          border-radius: 20px;
          cursor: pointer;
          transition: all .3s;

          .img_block {
            margin-right: 15px;
            min-width: 26px;
            display: flex;
            align-self: center;
          }

          span {
            font-size: 14px;
            line-height: 17px;
          }

          svg {
            position: absolute;
            top: 10px;
            right: 15px;
            transition: all .3s;

            circle {
              fill: white;
            }

            &.open {
              transform: rotate(180deg);
            }
          }

          &.open {
            border-radius: 20px 20px 0 0;
          }
        }

        .dropdown_block {
          padding: 5px 15px 10px 15px;
          position: absolute;
          /* top: 15px;
           left: 15px;*/
          width: calc(100% - 30px);
          opacity: 0;
          max-height: 0;
          overflow: hidden;
          border-radius: 16px;
          transition: max-height .5s, opacity .5s, visibility .3s;
          background: var(--dark);
          visibility: hidden;
          z-index: 6;

          ul, li {
            margin: 0;
            padding: 0;
          }

          .dropdown_elem {
            padding: 5px 5px 5px 0;
            display: flex;
            border-top: 1px solid rgba(255, 255, 255, 0.2);
            cursor: pointer;

            .img_block {
              margin-right: 15px;
              min-width: 26px;
              display: flex;
              align-self: center;
            }

            span {
              align-self: center;
            }

            &:last-child {
              padding-bottom: 0;
            }
          }

          &.open {
            position: relative;
            margin-bottom: 15px;
            transition: max-height .5s, opacity .5s, margin .4s, visibility .3s;
            border-radius: 0 0 16px 16px;
            width: 100%;
            opacity: 1;
            max-height: 265px;
            visibility: initial;
          }
        }

        .select_block {
          position: relative;

          .input_select-skill {
            width: 100%;
            background: #757A88;
            border-radius: 16px;

            .ant-select-selector {
              padding: 0 40px 0 15px;
              background: #757A88;
              height: 40px;
              border: 1px solid transparent;
              border-radius: 16px;
              color: #FFFFFF;
              box-shadow: none;

              input {
                margin-top: 3px;
              }
            }

            span.ant-select-selection-item {
              top: 3px;
              height: fit-content;
              color: white !important;
            }

            .ant-select-selection-placeholder {
              margin-top: 9px;
              font-size: 14px;
              line-height: 17px;
              color: white;
            }

            .ant-select-open {
              .ant-select-selector {
                border-radius: 16px 16px 0 0;
                border-color: transparent;
              }
            }

            .ant-select-arrow {
              display: none;
            }

            &.active {
              border-radius: 16px 16px 0 0;
              border-color: #9B99A7;

              .ant-select-selector {
                border-radius: 16px 16px 0 0;
              }
            }

          }

          .ant-select.input_select-skill.ant-select-focused.ant-select-show-arrow.ant-select-show-search {
            border-radius: 16px 16px 0 0 !important;
          }

          span.ant-select-selection-search {
            left: 13px;
          }

          .img_add {
            position: absolute;
            top: 5px;
            right: 5px;
            cursor: pointer;
          }
        }
      }

      .btn {
        margin: 25px 0;
        padding: 3px 15px 4px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        width: 100%;
        font-size: 14px;
        line-height: 17px;
        background: var(--dark);
        border-radius: 20px;
        cursor: pointer;

        &.active {
          background: #3077FF;
          box-shadow: 0 9px 20px rgba(48, 119, 255, 0.2);
        }
      }

      .placement {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;


        span {
          color: var(--text_color_dark);
          font-size: 14px;
          line-height: 17px;
        }

        .switch input:checked + .slider {
          background-color: #3077FF;
        }
      }
    }

    .buttons_group {
      margin: 25px 0;
      display: flex;
      user-select: none;

      a {
        max-width: 170px;
        width: 100%;
      }

      .input_classic {
        margin: 5px 0 0 0;
        min-width: 170px;
        max-width: 170px;
      }

      .btn {
        display: flex;
        align-self: end;
        margin-left: 20px;
        padding: 4px;
        max-width: 170px;
        width: 100%;
        border-radius: 17px;
        cursor: pointer;

        span {
          align-self: center;
          margin-left: 10px;
        }

        &:first-child {
          margin-left: 0;
        }
      }

      .btn_pdf {
        background: #FF3434;
        color: white;
        margin-right: 0 !important;
      }

      .btn_share {
        position: relative;
        background: #30E0A1;
        z-index: 2;

        span {
          color: var(--dark);
        }

        .drop_share {
          position: absolute;
          padding-top: 40px;
          top: 0;
          left: 0;
          width: 100%;
          background: #30E0A1;
          border-radius: 17px;
          opacity: 0;
          max-height: 1px;
          visibility: hidden;
          z-index: -1;
          transition: max-height .6s linear, opacity .3s linear, visibility .3s linear;

          .btn_drop {
            padding: 5px 5px 5px 10px;
            display: flex;
            align-items: center;

            img {
              max-width: 20px;
              max-height: 20px;
            }

            &:hover {
              background: #3077FF;

              span {
                color: white;
              }
            }

            &:last-child {
              border-radius: 0 0 17px 17px;
            }
          }

          &.open {
            transition: max-height .1s linear, opacity .3s linear, visibility .3s linear;
            opacity: 1;
            max-height: 104px;
            visibility: initial;
          }
        }
      }

      .btn_view {
        background: #3077FF;
      }
    }

    .buttons_group-mobile {
      display: none;
      justify-content: space-around;

      .group {
        display: flex;
        flex-direction: column;


        .input_classic {
          margin: 5px 0 0 0;
          min-width: 170px;
          max-width: 170px;
        }

        .btn {
          display: flex;
          align-self: end;
          padding: 4px;
          max-width: 170px;
          min-width: 170px;
          width: 100%;
          border-radius: 17px;
          cursor: pointer;

          span {
            align-self: center;
            margin-left: 10px;
          }
        }

        .btn_pdf {
          margin-top: 10px;
          background: #FF3434;
        }

        .btn_share {
          background: #30E0A1;

          span {
            color: var(--dark);
          }
        }

        .btn_view {
          margin-top: 10px;
          background: #3077FF;
        }

        &:last-child {
          align-self: end;
        }
      }
    }

    .h2 {
      margin: 25px 0 15px 15px;
      font-size: 20px;
      line-height: 16px;
    }

    .freelancers_list {
      margin: 15px 0 0 0;

      .salary {
        max-width: 90px;
      }

      li {
        margin: 0;
      }

      &.disabled {
        .open_resume {
          opacity: .5;
          cursor: auto;
        }
      }
    }

    .profession_skills {
      max-width: 580px;
      margin-bottom: 25px;
      padding: 20px;
      width: 100%;
      background: #201F26;
      border-radius: 25px;
      transition: all .3s linear;

      .reuse_select-parent,
      .reuse_input-parent {
        max-width: 265px;
        width: 100%;
      }

      .languages_row,
      .profession_skills-row {
        display: flex;
        position: relative;
        padding: 10px 11px 10px 15px;
        margin-bottom: 25px;
        max-width: 540px;
        width: 100%;
        background: var(--dark);
        border: 1px solid var(--background_lighten-dark);
        border-radius: 16px;

        .img {
          align-self: center;
          margin-right: 15px;
        }

        .group_icon {
          margin-left: auto;

          img {
            cursor: pointer;
          }

          img:first-child {
            margin-right: 14px;
            margin-top: 1px;
          }
        }
      }

      .profession_skills-row {
        .remove_icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 15px;
          cursor: pointer;
        }
      }

      .native_language {
        margin-bottom: 20px;
      }

      .entered_language-row,
      .entered_language-row-edit,
      .entered_profession-skills-row,
      .entered_profession_skills-row-edit {
        display: flex;

        .reuse_input-parent,
        .reuse_select-parent {
          .input_classic {
            margin-bottom: 0;
          }

          &:first-child {
            margin-right: 10px;
          }
        }

        .left {
          margin-right: 10px;
          max-width: 265px;
          width: 100%;

          .title {
            margin-left: 15px;
            margin-bottom: 5px;
            line-height: 17px;
            font-size: 14px;
            color: var(--text_color_dark);
          }

          .language_select,
          .profession_skills-select {
            display: flex;
            align-items: center;
            max-width: 265px;
            width: 100%;

            &::after {
              content: url('../../../images/arrow.svg');
              position: absolute;
              top: 8px;
              right: 14px;
              cursor: auto;
            }

            &.active {
              .ant-select-selector {
                border-radius: 16px 16px 0 0 !important;
              }

              &::after {
                content: url('../../../images/arrow.svg');
                position: absolute;
                top: 11px;
                right: 14px;
                transform: rotate(180deg);
              }
            }

          }

          .ant-select-selector {
            align-items: center;
            height: 41px;
            background: var(--dark);
            border: 1px solid var(--background_lighten-dark);
            border-radius: 16px;
            box-shadow: none !important;
            color: white;

            input {
              margin-top: 4px;
            }

            span.ant-select-selection-placeholder {
              font-size: 14px;
              line-height: 19px;
              color: #757A88;
            }

            span.ant-select-selection-item {
              color: white !important;
            }
          }

          .ant-select-open {
            .ant-select-selector {
              border-radius: 16px 16px 0 0;
              border-color: transparent;
            }
          }
        }
      }

      .title {
        margin: 0 0 5px 15px;
        color: var(--text_color_dark);
      }

      .entered_profession-skills-row {
        margin-bottom: 0;

        .select_block {
          position: relative;
          width: 100%;

          .input_classic {
            z-index: 4;
            height: 40px;
            padding-top: 1px;
          }

          .parent_tag {
            position: absolute;
            width: 100%;
            padding-top: 23px;
            top: 20px;
            left: 0;
            opacity: 0;
            max-height: 0;
            z-index: -1;
            background-color: var(--background_lighten-dark);
            border-radius: 0 0 10px 10px;
            transition: all .3s;

            .tag {
              padding: 3px 0 3px 15px;
              cursor: pointer;

              &:hover {
                background-color: darken(#474c5a, 10%);
              }

              &:last-child {
                &:hover {
                  border-radius: 0 0 10px 10px;
                }
              }
            }

            &.open {
              transition: all .3s;
              opacity: 1;
              overflow-y: auto;
              max-height: 155px;
              z-index: 3;
            }
          }
        }
      }

      .entered_profession_skills-row-edit {
        .input_classic {
          margin-top: 0;
        }
      }

      .parent_tags {
        padding: 15px 15px 10px 15px;
        display: flex;
        flex-wrap: wrap;
        background: var(--dark);
        border: 1px solid var(--background_lighten-dark);
        border-radius: 20px;

        .tag {
          margin: 0 5px 5px 0;
          padding: 4px 10px 6px 10px;
          text-align: center;
          color: #30E0A1;
          font-size: 12px;
          line-height: 14px;
          background: rgba(48, 224, 161, 0.2);
          border-radius: 16px;

          &.yellow {
            position: relative;
            padding-right: 30px;
            color: #FFD23B;
            background: rgba(255, 210, 59, 0.2);
            border-radius: 16px;

            img {
              position: absolute;
              top: 50%;
              right: 10px;
              transform: translateY(-50%);
              cursor: pointer;
            }
          }
        }
      }

      &.error {
        border: 1px solid red;
      }
    }

    .portfolio,
    .about {
      max-width: 580px;
      margin-bottom: 25px;
      padding: 20px;
      width: 100%;
      background: #201F26;
      border-radius: 25px;

      .textarea_row {
        .textarea_antd {
          padding: 12px 15px;
          max-height: 115px;
          min-height: 115px;
          overflow-y: auto;
          resize: none;
          font-size: 16px;
          line-height: 19px;
          color: white;
          background: var(--dark);
          border: 1px solid var(--background_lighten-dark);
          border-radius: 16px;

          &::placeholder {
            color: #757A88;
            font-size: 14px;
          }

          &:active {
            border-color: var(--text_color_dark);
          }

          &:focus {
            border-color: var(--text_color_dark);
          }
        }
      }

      .link_row {
        position: relative;

        .group_icon {
          position: absolute;
          right: 10px;
          top: 10px;
          z-index: 2;

          img:first-child {
            margin-right: 14px;
            margin-top: 1px;
          }

          img {
            cursor: pointer;
          }
        }

        .group {
          position: relative;
          margin-bottom: 10px;

          .input_classic {
            margin-bottom: 0;
            padding-right: 30px;
          }

        }
      }
    }

    .portfolio {
      .reuse_input-parent {
        margin-bottom: 15px;
      }

      .btn_add {
        margin-top: 0;
      }

      .input_classic {
        z-index: 2;
      }
    }

    .input_classic {

      &.error {
        border-color: red;

        &:hover {
          border-color: red !important
        }
      }
    }
  }

  .top_filter-mobile {
    display: none;
    justify-content: space-between;
    padding: 4px 6px;
    margin: 0 auto;
    max-width: 555px;
    width: 100%;
    max-height: 42px;
    background: var(--dark);
    border-radius: 34px;
    transition: all .2s ease;

    .icon {
      display: flex;

      svg {
        width: 34px;
        height: 34px;
      }
    }

    .filters_mobile {
      display: flex;
      margin: 0 auto;
      align-self: center;
      transition: all .2s ease;

      .btn {
        position: relative;
        width: 62px;
        height: 34px;
        background: #201E26;
        border-radius: 38px;

        img {
          position: absolute;
          top: 4px;
          left: 16px;
        }
      }

      .btn_pdf {
        border: 4px solid #FF3434;

        img {
          top: 4px;
          left: 21px;
        }
      }

      .btn_share {
        border: 4px solid #30E0A1;
      }

      .btn_view {
        border: 4px solid #3077FF;

        img {
          top: 6px;
          left: 17px;
        }
      }

      .btn:not(:first-child) {
        margin-left: 15px;
      }
    }

    .settings {
      padding: 4px 10px 4px 4px;
      align-self: center;
      display: flex;
      align-items: center;
      width: fit-content;
      background: #3077FF;
      border-radius: 15px;

      .img {
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        background: var(--dark);
        border-radius: 10px;

        img {
          width: 16px;
          height: 16px;
        }
      }

      span {
        margin-top: -3px;
        font-size: 14px;
        line-height: 17px;
      }
    }

    &.hidden {
      .filters_mobile {
        display: none;
        height: 0;
        transform: scale(0);
      }
    }
  }

  // переиспользуемые компоненты
  .reuse_input-parent {
    .title {
      margin-left: 15px;
      color: var(--text_color_dark);
      font-size: 14px;
      line-height: 17px;
    }

    .input_classic {
      margin-top: 5px;
      margin-bottom: 25px;
      padding: 9px 15px 9px 15px;
      font-size: 14px;
      line-height: 19px;

      &::placeholder {
        font-size: 14px;
        color: #757A88;
      }

      &:focus {
        border: 1px solid #9B99A7 !important;
      }
    }
  }

  .reuse_select-parent {
    max-width: 265px;
    width: 100%;

    .title {
      margin: 0 0 5px 15px;
      font-size: 14px;
      line-height: 17px;
      color: var(--text_color_dark);
    }

    img {
      margin-right: 11px;
      margin-bottom: 1px;
      max-width: 20px;
      max-height: 20px;
      min-width: 20px;
      min-height: 20px;
      border-radius: 7px;
    }

    .drop_down {
      padding: 7px 15px 9px 15px;
      max-width: 265px;
      width: 100%;
      height: 40px;
      position: relative;
      background-color: var(--dark);
      border-radius: 17px;
      border: 1px solid var(--background_lighten-dark);
      transition: all .3s ease;
      cursor: pointer;

      span {
        color: white;
        vertical-align: middle;
      }

      svg {
        position: absolute;
        top: 17px;
        right: 15px;

        &.active {
          transform: rotate(180deg);
        }
      }

      .placeholder {
        font-size: 14px;
        color: #757A88;
      }

      &.active {
        border-color: transparent;
        border-radius: 17px 17px 0 0;
      }

      .drop_down-block {
        max-height: 0;
        position: absolute;
        top: 39px;
        left: -1px;
        width: calc(100% + 2px);
        opacity: 0;
        transform: scale(0);
        background-color: darken(#474c5a, 10%);
        border: 1px solid transparent;
        border-radius: 0 0 17px 17px;
        transition: all .2s ease;
        z-index: 4;
        overflow-y: auto;

        ul {
          padding: 0;
          margin: 0 auto;
        }

        li {
          padding: 5px 15px 5px 10px;
          margin: 0;
          cursor: pointer;

          &:hover {
            background-color: darken(#474c5a, 5%);
          }

          &:last-child {
            padding-top: 0;
            padding-bottom: 5px;
            border-radius: 0 0 17px 17px;
          }
        }

        &.active {
          min-width: 100px;
          max-height: 200px;
          transform: scale(1);
          opacity: 1;
          overflow-y: auto;
        }
      }

      &.active {
        border-color: transparent;
      }
    }
  }

  .checkbox_group {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    padding-left: 14px;
    align-self: end;
    max-width: 265px;
    width: 100%;

    span {
      font-size: 14px;
      line-height: 17px;
      color: var(--text_color_dark);
    }

    .form-group {
      margin-bottom: 0;
    }

    .form-group label:before {
      margin-top: -3px;
      background: rgba(162, 170, 202, 0.2);
      border-color: var(--text_color_dark);
    }

    .form-group input:checked + label:after {
      top: 2.5px;
      border-color: var(--text_color_dark);
    }
  }

  .btn_add {
    margin-top: 20px;
    display: flex;
    width: fit-content;
    cursor: pointer;

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      align-self: center;
    }

    span {
      font-size: 14px;
      color: #3077FF;
    }
  }

  .btn_save-parent {
    padding-bottom: 20px;

    .btn_save {
      padding: 6px;
      max-width: 165px;
      width: 100%;
      text-align: center;
      background: #30E0A1;
      box-shadow: 0 9px 10px rgba(48, 224, 161, 0.2);
      border-radius: 13px;
      color: var(--dark);
      cursor: pointer;
    }
  }

  .save_resume {
    max-width: 200px;
    padding: 8px 12px;
    width: 100%;
    text-align: center;

  }

  .btn_save-resume {
    position: fixed;
    padding: 8px 12px;
    height: 40px;
    bottom: 50px;
    right: 20%;
    text-align: center;
    opacity: 1;
    z-index: 4;

    /* &:hover {
       transition: all .3s;
       opacity: 1;
     }*/
  }

  .overlay {
    position: fixed;
    display: none;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1;

    &.open {
      display: block;
    }
  }

  .btn_scroll-up {
    bottom: 30% !important;
  }
}

.modal_edit-image {
  .edit_image {
    margin: auto;
    display: flex;
    flex-direction: column;

    .ReactCrop {
      margin: 0 auto;
      /*border-radius: 20px;*/
    }

    .canvas,
    canvas {
      position: absolute;
      width: 1px;
      height: 1px;
      opacity: 0;
    }

    .group_btn {
      display: flex;
      margin: 15px 0 0 0;

      .btn {
        padding: 3px 16px;
        width: 100%;
        text-align: center;
        background-color: #3077FF;
        border-radius: 16px;
        color: white;
        cursor: pointer;

        &.cansel {
          margin-left: 10px;
          max-width: 100px;
          background-color: var(--background_lighten-dark);
        }
      }
    }
  }
}

.modal_profile-mobile {
  padding-bottom: 0;
  margin: -75px auto 15px auto;
  max-width: 420px;
  width: 100% !important;
  background: #27292F;
  border-radius: 20px;
  color: white;

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .h2 {
      color: white;
      font-size: 24px;
      line-height: 29px;
    }

    img {
      align-self: center;
      width: 24px;
      height: 24px;
    }
  }

  .info_profile {
    overflow-y: auto;

    .category {
      .title {
        span {
          margin-right: 20px;
          align-self: flex-start;
        }
      }
    }

    .skill_section {
      position: relative;
      z-index: 0;

      .title {
        display: flex;
        margin-bottom: 15px;

        img {
          margin-right: 15px;
          align-self: center;
        }

        span {
          font-size: 16px;
          line-height: 19px;
        }
      }

      .category {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: 10px;
        max-width: 360px;
        width: 100%;
        cursor: pointer;
        transition: all .3s;
        z-index: 6;

        .title {
          display: flex;
          align-items: center;

          .img_block {
            margin-right: 15px;
            position: relative;
            width: 40px;
            height: 24px;
            border-radius: 20px;

            img {
              position: absolute;
              top: 4px;
              left: 12px;
              vertical-align: middle;
            }
          }

          span {
            font-size: 16px;
            line-height: 21px;
          }

          svg {
            position: absolute;
            right: 0;
            top: 8px;
            transition: all .3s;

            &.arrow {
              circle {
                fill: white;
              }
            }

            &.open {
              transform: rotate(180deg);
            }
          }

        }

        .select_block {
          width: 100%;
          position: relative;
          z-index: 5;

          .parent_tag {
            position: absolute;
            width: 100%;
            padding-top: 15px;
            top: 20px;
            left: 0;
            opacity: 0;
            max-height: 0;
            visibility: hidden;
            z-index: -1;
            background-color: var(--background_lighten-dark);
            border-radius: 0 0 10px 10px;
            transition: all .3s;
            overflow-y: auto;

            .tag {
              padding: 3px 0 3px 15px;
              cursor: pointer;

              &:hover {
                background-color: darken(#474c5a, 10%);
              }

              &:last-child {
                &:hover {
                  border-radius: 0 0 10px 10px;
                }
              }
            }

            &.open {
              transition: all .3s;
              opacity: 1;

              max-height: 155px;
              visibility: initial;
              z-index: 4;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 9em;
              background: var(--dark);
            }
          }

          .profile_icon {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            top: 50%;
            transform: translateY(-50%);
            left: 5px;
            background: #1D1E25;
            border-radius: 10px;
            z-index: 10;
          }

          .spin_loading {
            position: absolute;
            top: 7px;
            right: 10px;
            z-index: 10;
          }

          .img_add {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 5px;
            cursor: pointer;
          }

          &.open {
            position: relative;
            opacity: 1;
            max-height: inherit;
            visibility: initial;
          }
        }
      }

      .dropdown_skills {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        line-height: 17px;
        overflow-y: scroll;
        color: white;
        background: var(--dark);
        border: 1px solid var(--background_lighten-dark);
        border-radius: 20px;
        transition: max-height .3s, padding .5s, margin .8s, opacity .3s;

        .tag {
          padding: 3px 12px 5px 12px;
          margin: 0 5px 5px 0;
          width: fit-content;
          background: rgba(22, 21, 27, 0.65);
          border: 1px solid transparent;
          /*border-radius: 34px;*/
          border-radius: 12px;

          &.added {
            position: relative;
            padding-right: 33px;
            border-color: #FFD23B;

            img {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 2px;
              z-index: 4;
            }
          }
        }

        &.open {
          padding: 15px 15px 10px 15px;
          margin-bottom: 25px;
          opacity: 1;
          transition: max-height .3s, margin .5s, opacity .3s;
          max-height: 285px;
          overflow-y: scroll;
        }
      }

      &.z_index {
        z-index: 10;
      }

      &.open {
        z-index: 6;
      }
    }

    .reuse_input-parent {
      margin-top: 15px;

      .title {
        margin-bottom: 0;
        margin-left: 15px;
      }
    }

    .h3 {
      margin-top: auto;
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 18px;
    }

    .radar_parent {
      margin-left: auto;
      margin-right: auto;
      /* max-width: 305px;*/
      width: 100%;
      min-width: 305px;
      padding-top: 15px;
      background: var(--dark);
      border-radius: 20px;

      canvas {
        min-width: 305px;
        position: relative;
        z-index: 4;
      }

      .chart_block {
        min-width: 305px;
        padding: 0 10px;
      }

      .category_list {
        padding: 20px 15px;
        background: #16151B;
        border-radius: 20px;

        .list {
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:not(:last-child) {
            margin-bottom: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid var(--background_lighten-dark);
          }

        }

        .category {
          display: flex;
          align-items: center;

          .figure {
            margin-right: 8px;
            width: 11px;
            height: 11px;
            border-radius: 50%;
          }

          span {
            font-size: 13px;
            line-height: 16px;
          }

          &:not(:last-child) {
            margin-right: 14px;
          }
        }
      }
    }
  }

  .form_inputs {
    .reuse_input-parent {
      margin-bottom: 20px;

      .title {
        margin-left: 15px;
        color: var(--text_color_dark);
      }
    }

    .phone_parent {
      margin-bottom: 20px;
      position: relative;

      .title {
        margin-left: 15px;
        color: var(--text_color_dark);
      }

      .icon_country {
        top: 28px;
      }

      .arrow_phone {
        top: 22px;
      }
    }

    .language {
      margin-bottom: 25px;

      .drop_down-language {
        width: auto;
        border: none;

        .drop_down-block {
          left: 0;
          border: 1px solid transparent;
        }
      }

      svg {
        &.active {
          transform: rotate(180deg);
        }
      }
    }

    .mailing {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      span {
        color: var(--text_color_dark);
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}