.freelancers_list {
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;

  li {
    margin: 0 0 25px 0;
    padding-bottom: 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 309px;
    width: calc(25% - 20px);
    background: #1D1E25;
    border-radius: 30px;

    .info {
      padding: 25px 22px 0 22px;
      margin-bottom: 18px;
      text-align: center;

      .favorite_freelancer {
        position: absolute;
        top: 13px;
        left: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 35px;
        background: var(--dark);
        border-radius: 20px;
        cursor: pointer;

        svg {
          width: 16px;
          height: 16px;

          path {
            transition: all .1s linear;
          }
        }

        &:hover,
        &.active {
          svg {
            path {
              fill: orange;
            }
          }
        }
      }

      img {
        margin: 0 auto 15px auto;
        max-width: 80px;
        max-height: 80px;
        min-width: 80px;
        min-height: 80px;
        border-radius: 50%;
      }

      .salary {
        position: absolute;
        top: 24px;
        right: 22px;
        font-size: 12px;
        line-height: 14px;
      }

      .name {
        margin-bottom: 10px;
        font-size: 24px;
        line-height: 29px;
        overflow-wrap: break-word;
      }

      .position {
        font-size: 15px;
        line-height: 18px;
        overflow-wrap: break-word;
      }
    }

    .skills_list-parent {
      padding: 20px 15px 15px 15px;
      margin-bottom: 25px;
      position: relative;
      background: var(--dark);
      border-radius: 20px;
      transition: all .3s linear;

      .skills_list {
        max-height: 112px;
        overflow-y: hidden;
        display: flex;
        flex-wrap: wrap;
        transition: all .3s linear;

        .skill {
          margin: 4px 5px 5px 6px;
          padding: 0 10px 2px 10px;
          width: fit-content;
          text-align: center;
          background: rgba(22, 21, 27, 0.65);
          border-radius: 29px;

          span {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }

      .arrow_drop {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
        background: var(--dark);
        cursor: pointer;
        transition: all .3s linear;
      }

      &.open {
        .skills_list {
          max-height: 169px;
          transition: all .3s linear;
        }

        .arrow_drop {
          transform: translateX(-50%) rotate(180deg);
        }
      }
    }

    .open_resume {
      padding: 1px 20px 4px 20px;
      margin: auto auto 0 auto;
      max-width: 240px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      width: 100%;
      background: #3077FF;
      box-shadow: 0 9px 20px rgba(48, 119, 255, 0.2);
      border-radius: 20px;
      white-space: nowrap;
      cursor: pointer;
      user-select: none;

      span {
        font-size: 14px;
        line-height: 17px;
      }
    }

    &:nth-child(4n+4) {
      margin-right: 0;
    }
  }
}