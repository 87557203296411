.select_block {
  position: relative;
  width: 100%;
  height: 34px;
  z-index: 11;

  .parent_tag {
    position: absolute;
    width: 100%;
    padding-top: 15px;
    top: 20px;
    left: 0;
    opacity: 0;
    transform: scale(0);
    max-height: 0;
    background-color: var(--background_lighten-dark);
    border-radius: 0 0 10px 10px;
    transition: opacity .3s ease, max-height .6s ease;
    z-index: 11;

    .tag_ {
      padding: 3px 0 3px 15px;
      cursor: pointer;
      z-index: 3;

      &:hover {
        background-color: darken(#474c5a, 10%);
      }

      &:last-child {
        &:hover {
          border-radius: 0 0 10px 10px;
        }
      }
    }

    &.open {
      transition: opacity .3s ease, max-height .3s ease;
      transform: scale(1);
      opacity: 1;
      overflow-y: auto;
      max-height: 155px;
      z-index: 3;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 9em;
      background: var(--dark);
    }
  }

  .spin_loading {
    position: absolute;
    top: 8px;
    right: 10px;
    z-index: 5;
  }
}