.custom_checkbox {
  margin-top: 15px;
  display: flex;

  input {
    display: none;

    &:checked + label:after {
      content: '';
      display: block;
      position: absolute;
      top: 5px;
      left: 10px;
      width: 4px;
      height: 10px;
      border: solid var(--dark);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    &:checked + label:before {
      background: #30E0A1 !important;
      border-color: #30E0A1 !important;
    }
  }

  label {
    margin-right: 15px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    transition: all .3s linear;

    &:before {
      content: '';
      margin-right: 10px;
      padding: 9px;
      position: relative;
      cursor: pointer;
      border-radius: 50%;
      border: 2px solid var(--text_color_dark);
      background: transparent;
    }

    &:hover {
      color: var(--text_color_dark)
    }
  }
}