.quiz_mob {
  padding: 15px;
  display: flex;
  flex-direction: column;

  .history_questions {
    margin: 0 0 0 auto;
    padding: 4px 6px 4px 8px;
    width: fit-content;
    display: flex;
    align-items: center;
    border-radius: 19px;
    background: #353947;
    color: rgba(255, 255, 255, 0.60);
    cursor: pointer;

    span {
      font-size: 15px;

      &:nth-child(1) {
        color: white;
      }

      &:nth-child(2) {
        margin: 0 2px;
      }
    }

    .arrow {
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      height: 23px;
      background: #3077FF;
      border-radius: 12px;

      img {
        transition: all .3s linear;
      }

      &.active {
        img {
          transform: rotate(180deg);
        }
      }
    }
  }

  .drop_list-history {
    margin: 20px 0 0 0;
    display: flex;
    flex-wrap: wrap;

    .elem_list {
      margin: 0 9px 9px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      border-radius: 8px;
      background: var(--dark);

      span {
        line-height: 12px;
      }

      &.active {
        border-radius: 8px;
        background: var(--blue);
        box-shadow: 0 4px 6px 0 rgba(1, 103, 255, 0.30);
      }
    }

    &.entering {
      animation: show .4s forwards linear;
    }

    &.entered {
      animation: show .4s forwards linear;
    }

    &.exiting {
      animation: hide .4s forwards linear;
    }

    &.exited {
      animation: hide .4s forwards linear;
    }
  }

  .quiz_content {
    .name_quiz {
      margin: 15px 0;
      font-size: 20px;
      padding-bottom: 15px;
      border-bottom: 1px solid #353947;
    }

    .title_question {
      margin-bottom: 20px;
      font-size: 18px;
    }

    .info {
      margin-bottom: 5px;
      color: var(--text_color_dark);
      font-size: 16px;
    }

    .answers_list {
      display: flex;
      flex-direction: column;

      .answer {
        display: flex;
        align-items: center;
      }
    }
  }

  .quiz_result {
    margin-top: 25px;
    padding: 15px;
    width: 100%;
    border-radius: 18px;
    background: #1D1E25;

    .top_row {
      display: flex;
      padding-bottom: 15px;
      border-bottom: 1px solid #353947;

      .info {
        font-size: 18px;
        width: 100%;
        max-width: 65%;
      }

      .value {
        width: 100%;
        max-width: 35%;
        font-size: 18px;
      }
    }

    .info_row {
      display: flex;
      margin-top: 15px;

      .label {
        max-width: 65%;
        width: 100%;
        font-size: 14px;
      }

      .value {
        font-size: 14px;
        max-width: 35%;
        width: 100%;
        color: #30E0A1;

        &.error {
          color: #CF1A1A;
        }
      }

      &.time {
        .value {
          color: #CF1A1A;
        }
      }
    }
  }

  .quiz_answers {
    margin-top: 15px;
    padding: 0 15px 15px 15px;
    border-radius: 20px;
    background: #242630;

    .answer {
      margin-top: 15px;
      display: flex;
      align-items: center;

      .num {
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        min-width: 25px;
        height: 25px;
        font-size: 14px;
        color: var(--dark);
        background: #30E0A1;
        border-radius: 50%;

        &.error {
          color: white;
          background: #CF1A1A;
        }
      }

      .label {
        font-size: 14px;
      }
    }
  }

  .result_attemps-denied {
    margin-top: 30px;

    span {
      text-align: center;
    }
  }
}