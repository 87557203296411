.code_editor-parent {
  position: relative;
  display: flex;

  .block_info_lesson {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 20px) !important;
    min-height: calc(100vh - 20px) !important;
    width: calc(100% - 100px);
    overflow-y: auto;

    .title_lesson {
      margin: 10px 0 20px 0;
      font-size: 32px;
      line-height: 38px;
    }

    .lesson_detail {
      margin-bottom: 20px;
      font-size: 20px;
      line-height: 24px;
    }

    .will_be-answer {
      font-size: 18px;
      line-height: 21px;
      color: #A2AACA;
    }

    .player-wrapper {
      margin-bottom: 0;
    }

    .tasks_history {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      padding: 15px;
      overflow: hidden;
      width: 100%;
      background: #343843;
      border: 1px solid #30E0A1;
      border-radius: 24px;

      .boxes {
        margin-top: 10px;
        margin-bottom: 10px;

        span.ant-typography {
          color: var(--text_color_dark);
        }
      }

      li.ant-list-item {
        border-bottom: none;
      }

      @media (max-width: 575px) {
        box-shadow: none;

        .checkTime {
          display: none;
        }

      }

      @media (min-width: 576px) {
        .mobileCheckTime {
          display: none;
        }
      }
    }

    section {
      height: 200px !important;

      .editor {
        width: 100%;
        border-radius: 20px 20px 0px 0px;
      }

      .monaco-editor.no-user-select.showUnused.showDeprecated {
        border-radius: 10px;
      }
    }

    .overflow-guard {
      border-radius: 10px;
    }

    .comment_title {
      margin: 10px 0 0 0;
    }

    .comment_input {
      margin-bottom: 40px;
    }
  }

  .block_editor {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 40px) !important;
    min-height: calc(100vh - 40px) !important;
    width: 100%;
    overflow-y: auto;

    .code_container {
      margin-top: 20px;
      position: relative;
      min-height: calc(100vh - 500px) !important;
      height: 100%;

      .full_screen-icon {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--dark);
        border-radius: 13px;
        cursor: pointer;
        z-index: 1;

        img {
          width: 14px;
          height: 14px;
        }
      }

      section {
        height: 100%;
      }

      .editor {
        width: 100%;
        border-radius: 20px 20px 0px 0px;
      }

      .monaco-editor.no-user-select.showUnused.showDeprecated {
        border-radius: 15px 15px 0 0;

        .overflow-guard {
          border-radius: 15px 15px 0 0;
        }
      }
    }

    .output_container {
      position: relative;
      /*   padding: 20px;*/
      height: 100%;
      overflow-y: hidden;
      overflow-x: hidden;
      background: var(--dark);
      border-radius: 0px 0px 20px 20px;

      .result_title {
        margin: 1px auto 2px auto;
        width: fit-content;
        font-size: 14px;

      }

      .output {
        overflow-y: hidden;
      }

      pre {
        padding: 10px;
        margin-bottom: 30px;
        overflow: hidden !important;
        white-space: pre-wrap;
      }
    }

    .buttons_editor {
      margin: 20px auto 20px auto;
      display: flex;
      width: fit-content;

      .btn {
        padding: 5px 15px;
        margin: 0 15px 0 0;
        text-align: center;
        max-width: 120px;
        width: 100%;
        height: 32px;
        white-space: nowrap;
        user-select: none;
        background: #3077FF;
        box-shadow: 0px 9px 30px rgba(48, 119, 255, 0.2);
        border-radius: 23px;
        cursor: pointer;

        &.send {
          margin-right: 0;
          max-width: 180px;
          background: #30E0A1;
          color: var(--dark);
        }
      }
    }
  }
}