.gpt_ {
  margin-top: 0;
  top: 0;

  .ant-modal-body {
    padding: 0;
    background: transparent !important;
  }

  .title_ {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 40px;
    line-height: 48px;

    .close_icon {
      cursor: pointer;
    }
  }

  .info {
    padding-bottom: 15px;
    margin-bottom: 15px;
    width: 100%;
    font-size: 16px;
    line-height: 19px;
    color: rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid #464C5A;
  }

  .top_section {
    padding: 30px;
    margin-bottom: 30px;
    background: var(--dark);
    border: 1px solid var(--background_lighten-dark);
    box-shadow: 0 30px 120px rgba(0, 0, 0, 0.99);
    border-radius: 30px;
  }

  .title_info {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .tags_section {
    padding: 15px 15px 5px 15px;
    background: var(--background_lighten-dark);
    border: 1px solid var(--background_lighten-dark);
    border-radius: 16px;

    .h2 {
      margin-bottom: 10px;
      font-size: 24px;
      line-height: 29px;
      color: #FFFFFF;
    }

    .label_info {
      margin-bottom: 15px;
      font-size: 16px;
      line-height: 19px;
      color: var(--text_color_dark);
    }

    .list_tags {
      overflow: auto;
      max-height: 170px;
      display: flex;
      flex-wrap: wrap;

      .tag {
        padding: 4px 15px 5px 15px;
        margin: 0 5px 10px 0;
        position: relative;
        display: flex;
        align-items: center;
        width: fit-content;
        background: rgba(22, 21, 27, 0.65);
        border: 1px solid transparent;
        border-radius: 29px;
        cursor: pointer;
        transition: all .3s linear;
        user-select: none;

        img {
          display: none;
          width: 20px;
          height: 20px;
        }

        &.active {
          background: rgba(48, 224, 161, 0.2);
          border-color: rgb(48, 224, 161);
          color: rgb(48, 224, 161);
        }

        &.exist_quest {
          padding-right: 30px;

          img {
            margin-left: 5px;
            display: block;
          }
        }
      }

      &::-webkit-scrollbar-thumb {
        background: var(--dark);
      }
    }
  }

  .result_section {
    padding: 30px;
    width: 100%;
    background: #1D1E25;
    border: 1px solid #343843;
    box-shadow: 0 30px 120px rgba(0, 0, 0, 0.99);
    border-radius: 30px;

    .buttons_row {
      margin-bottom: 20px;
      display: flex;
      flex-wrap: wrap;

      .button:first-child {

      }

      .btn_reload {
        padding: 12px 30px;
        position: relative;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 162px;
        border-radius: 15px;
        font-size: 14px;
        background: #3077FF;
        box-shadow: 0 9px 10px rgba(48, 119, 255, 0.27);
        user-select: none;
        cursor: pointer;

        img {
          margin-left: 10px;
        }

        span {
          position: absolute;
          bottom: -25px;
          color: var(--text_color_dark);
        }
      }

      .attempts {
        margin-left: auto;
        display: flex;
        color: var(--text_color_dark);
        user-select: none;

        svg {
          margin-top: 4px;
          cursor: pointer;
        }

        svg:first-of-type {
          transform: rotate(90deg);
          margin-right: 3px;
        }

        svg:last-of-type {
          transform: rotate(-90deg);
          margin-left: 3px;
        }

        div {
          display: flex;
          align-items: center;
          font-size: 20px;
        }

        .label {
          margin-right: 10px;
        }

        .current {
          margin-top: 3px;
          display: flex;
          align-items: center;
          font-size: 18px;
        }
      }
    }

    .h1 {
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      span {
        font-weight: 400;
        font-size: 40px;
        line-height: 48px;
      }
    }

    .info_row {
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;

      span {
        font-size: 16px;
        line-height: 19px;
        color: #A2A1A4;
      }
    }

    .message_loading {
      width: fit-content;
      margin: 0 auto;
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      font-size: 16px;
    }

    pre {
      margin: 0;
      overflow-y: auto;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      white-space: break-spaces;
      transition: all .3s linear;

      &::-webkit-scrollbar-thumb {
        border-radius: 9em;
        background: var(--background_lighten-dark);
      }
    }

    .download_btn {
      margin-top: 30px;
      padding: 7px 10px 9px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 16px;
      background: var(--3077-ff, #3077FF);
      box-shadow: 0 9px 20px 0 rgba(48, 119, 255, 0.45);

      span {
        margin-right: 10px;
      }
    }
  }

  .btn_save-course {
    position: fixed;
    right: 10%;
    bottom: 17%;
    opacity: .7;

    .text_in {
      white-space: break-spaces;
    }

    &:hover {
      opacity: 1;
    }
  }
}

.buy_education {
  padding-bottom: 0 !important;
  max-width: 460px !important;
  width: 100% !important;
  background: #201E26;
  border: 1px solid var(--dark);
  box-shadow: 0 30px 120px rgba(0, 0, 0, 0.99);
  border-radius: 40px;
  color: white !important;

  .ant-modal-body {
    padding: 29px;
  }

  .ant-modal-content,
  .ant-modal-body {
    background: #201E26;
    border-radius: 40px;
    box-shadow: none;

    .close {
      cursor: pointer;

      img {
        width: 25px;
        height: 25px;
      }
    }

    .title {
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
      font-size: 24px;
      line-height: 29px;
    }

    .main {
      padding: 13px 11px;
      margin-bottom: 30px;
      width: 100%;
      border-radius: 14px;
      border: 1px solid #757A88;
      background: var(--background_lighten-dark);

      .price_row {
        display: flex;
        justify-content: space-between;

        .label {
          font-size: 16px;
        }

        .value {
          color: var(--text_color_dark);
          font-size: 16px;
          text-decoration: line-through;

          &.green {
            text-decoration: initial;
            color: #30E0A1;
          }
        }

        &:first-child {
          margin-bottom: 15px;
          padding-bottom: 15px;
          border-bottom: 1px solid var(--dark);
        }
      }
    }

    .slider_row {
      display: flex;
      align-items: center;

      .slider_antd {
        width: 100%;
      }
    }

    .slider_antd {
      .ant-slider-track {
        background: var(--blue);
      }

      .ant-slider-rail {
        background: #474C5A;
      }

      .ant-slider-handle {
        width: 18px;
        height: 18px;
        margin-top: -7px;
        border-color: var(--blue);
        background: var(--blue);
      }
    }
  }
}

@media (max-width: 990px) {
  .gpt_ {
    .result_section {
      .buttons_row {
        flex-direction: column;
        align-items: center;

        .button {
          white-space: initial;
        }

        .attempts {
          margin: 15px 0 !important;
        }

        .btn_reload {
          margin: 0 0 15px 0 !important;
        }
      }
    }
  }
}

@media (max-width: 820px) {
  .gpt_ {
    .title_ {
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      font-size: 24px;
      line-height: 29px;
    }

    .top_section {
      padding: 15px;
      margin-bottom: 30px;
      background: var(--dark);
      border: 1px solid var(--background_lighten-dark);
      box-shadow: 0 30px 120px rgba(0, 0, 0, 0.99);
      border-radius: 30px;
    }

    .info {
      margin-bottom: 15px;
      font-size: 12px;
      line-height: 14px;
      color: #757A88;
    }

    .tags_section {
      padding: 10px 10px 0 10px;
      background: var(--background_lighten-dark);
      border: 1px solid var(--background_lighten-dark);
      border-radius: 16px;

      .h2 {
        margin-bottom: 10px;
        font-size: 24px;
        line-height: 29px;
        color: #FFFFFF;
      }

      .label_info {
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 19px;
        color: var(--text_color_dark);
      }

      .list_tags {
        overflow: auto;
        max-height: 280px;
        display: flex;
        flex-wrap: wrap;

        .tag {
          padding: 2px 10px 4px 10px;
          margin: 0 5px 10px 0;
          position: relative;
          display: flex;
          align-items: center;
          width: fit-content;
          background: rgba(22, 21, 27, 0.65);
          border: 1px solid transparent;
          border-radius: 29px;
          cursor: pointer;
          transition: all .3s linear;

          img {
            cursor: pointer;
          }

          &.active {
            background: rgba(48, 224, 161, 0.2);
            border-color: rgb(48, 224, 161);
            color: rgb(48, 224, 161);
          }
        }
      }
    }

    .result_section {
      padding: 15px;
      width: 100%;
      background: #1D1E25;
      border: 1px solid #343843;
      box-shadow: 0 30px 120px rgba(0, 0, 0, 0.99);
      border-radius: 24px;

      .h1 {
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: 24px;
          line-height: 29px;
        }

      }

      .info_row {
        margin-bottom: 40px;
        display: flex;
        justify-content: space-between;

        span {
          font-size: 16px;
          line-height: 19px;
          color: #A2A1A4;
        }
      }

      .message_loading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 16px;
      }

      pre {
        margin: 0;
        overflow-y: auto;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        white-space: break-spaces;
        transition: all .3s linear;

        &::-webkit-scrollbar-thumb {
          border-radius: 9em;
          background: var(--background_lighten-dark);
        }
      }

      .download_btn {
        margin-top: 30px;
        padding: 5px 10px 7px 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          margin-right: 10px;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .buy_education {
    max-width: calc(100% - 30px) !important;
    top: 80px !important;

    .ant-modal-body {
      padding: 20px !important;

      .title {
        font-size: 21px;
      }
    }
  }
}