@use "./src/styles/Variables";

@media (max-width: 1435px) {
  .freelancers_page {
    .freelancers_list {
      justify-content: center;
    }
  }
}

@media (max-width: 1410px) {
  .freelancers_page {
    margin: 0 15px !important;
  }
}


@media (max-width: 820px) {
  .freelancers_page {

    .filters_row {
      flex-direction: column;

      .search,
      .category {
        max-width: 100% !important;
        margin-top: 10px !important;
        margin-right: 0 !important;
      }
    }

    .freelancers_list {
      padding-bottom: 120px !important;
      justify-content: center !important;
    }
  }
}

@media (max-width: 650px) {
  .freelancers_page {
    .main {
      .h1 {
        flex-direction: column !important;

        .favorite {
          margin-top: 10px !important;
        }
      }
    }
  }
}

.freelancers_page {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;

  &.not_auth {
    margin: 0 auto;
    max-width: 1310px;
    width: 100%;

  }

  .roadmap {
    margin-bottom: 20px;
    display: flex;

    img {
      margin-right: 10px;
    }

    span {
      margin-right: 10px;
      font-size: 12px;
      line-height: 14px;
      color: #757A88;
    }

    svg {
      margin-right: 10px;
      align-self: center;

      path {
        stroke: #757A88;
      }
    }

    span:nth-child(2) {
      cursor: pointer;
    }

    span:last-child {
      color: white;
    }
  }

  .h1 {
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 32px;
    line-height: 38px;
    border-bottom: 1px solid var(--dark);
  }

  .favorite {
    padding: 5px 15px 5px 5px;
    display: flex;
    max-width: fit-content;
    width: 100%;
    background: #FFD23B;
    border-radius: 15px;
    cursor: pointer;

    .svg {
      margin-right: 10px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #27292F;
      border-radius: 10px;
      transition: all .3s linear;

      svg {
        path {
          transition: all .3s linear;
          fill: white;
        }
      }
    }

    span {
      margin-top: 3px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: var(--dark);
    }

    &.active {
      svg {
        path {
          fill: orange;
        }
      }
    }
  }

  .filters_row {
    margin-bottom: 60px;
    display: flex;
    align-items: center;

    .search {
      margin-right: 25px;
      position: relative;
      max-width: 512px;
      width: 100%;


    }

    .category {
      margin-top: 5px;
      display: flex;
      position: relative;
      max-width: 391px;
      width: 100%;
      align-self: center;
      user-select: none;

      span {
        margin-right: 5px;
        font-size: 16px;
        line-height: 19px;
        white-space: nowrap;
      }

      .select_block {
        margin-top: -10px;
        padding: 10px 10px 5px 15px;
        width: 100%;
        position: relative;
        border-radius: 17px;
        transition: all .3s ease;
        cursor: pointer;
        z-index: 1;

        span {
          font-size: 16px;
          line-height: 19px;
          white-space: nowrap;
          color: #757A88;

          &:first-child {
            color: #B3DAFF;
          }
        }

        svg {
          position: absolute;
          top: 15px;
          right: 15px;
          transition: all .3s ease;
        }

        .drop_down-category {
          position: absolute;
          top: 30px;
          left: 0;
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: column;
          height: 0;
          opacity: 0;
          transform: scale(0);
          background-color: var(--dark);
          border-radius: 0 0 17px 17px;
          transition: all .3s ease;

          li {
            padding: 8px 0 8px 15px;
            margin-right: 0;
            font-size: 16px;
            line-height: 19px;

            span {
              color: #757A88;

              &.active {
                color: #B3DAFF;
              }
            }

            &:hover {
              background-color: #4B505C;
            }

            &:last-child {
              border-radius: 0 0 17px 17px;
            }

            &:first-child {
              margin-top: 10px;
            }
          }

          &.active {
            width: 100%;
            height: fit-content;
            opacity: 1;
            transform: scale(1);
            z-index: 2;
            span {
              z-index: 4;
            }
          }
        }

        &.active {
          border-radius: 17px 17px 0 0;
          background-color: var(--dark);
          z-index: 2;
          svg {
            transform: rotate(180deg);
          }
        }

        &:hover {
          /* border: 1px solid #A2AACA;
           border-radius: 15px;*/
        }
      }

      &:nth-child(2) {
        margin-right: 25px;
      }
    }
  }

  .pagination_all {
    margin: 0 auto;

    .ant-pagination {
      display: block;
    }

    .ant-pagination-item {
      margin-right: 5px;
      background-color: transparent;
      border: none;

      a {
        border-radius: 8px !important;
        color: white;
        background-color: var(--dark);
      }

      &-active {
        a {
          background-color: lighten(#343843, 20%);
        }

        border-color: transparent;
      }
    }

    li.ant-pagination-options {
      display: none;
    }

    li.ant-pagination-prev,
    li.ant-pagination-next {
      display: none;
    }

    span.ant-pagination-item-ellipsis,
    span.anticon.anticon-double-right.ant-pagination-item-link-icon,
    span.anticon.anticon-double-left.ant-pagination-item-link-icon {
      color: #61687c;
    }
  }
}
