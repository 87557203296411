
.content_mobile {
  display: none;
  flex-direction: column;
  position: relative;
  width: 100%;

  .prev_button {
    position: absolute;
    padding: 0 !important;
    top: 15px;
    left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: var(--dark);
    border-radius: 12px;
    z-index: 1;

    img {
      transform: rotate(90deg);
    }
  }

  .preview_section {

    .progress {
      position: absolute;
      display: flex;
      align-items: center;
      top: 15px;
      right: 15px;
      font-size: 12px;

      span, .ant-typography {
        margin-right: 15px;
        color: white;
      }

      z-index: 1;
    }

    .preview_image {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      aspect-ratio: 1.77;
      left: -15px;

      img {
        width: 100%;
        /*min-height: 228px;*/
      }
    }
  }

  .stages_section {
    margin-top: -15px;
    padding: 25px 25px 0 25px;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    background: #16151B;
    z-index: 1;

    .course_name {
      margin-bottom: 15px;
      font-size: 18px;
      width: 100%;
    }

    .certificate {
      margin-bottom: 15px;
      padding: 5px 15px;
      text-align: center;
      border-radius: 16px;
      background-color: var(--blue);
      font-size: 14px;
      width: 100%;
      opacity: 0.7;
      cursor: pointer;
      transition: all .3s linear;

      &:hover {
        opacity: 1;
      }
    }

    .matherial_count {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .count_row {
        max-width: 90%;
        display: flex;
        flex-wrap: wrap;

        span {
          margin-right: 10px;
          color: #9197A8;
          font-size: 13px;
        }
      }

      .percent {
        font-size: 13px;
        font-weight: 500;

        &.fiolet {
          color: #8146FF;
        }

        &.yellow {
          color: #FFD23B;
        }

        &.red {
          color: #FF3434;
        }
      }
    }

    .list_stages {
      margin: 15px 0;
      padding-bottom: 5px;
      width: 100%;
      display: flex;
      overflow-y: hidden;
      overflow-x: auto;
      white-space: nowrap;

      .stage {
        margin-right: 10px;
        padding: 5px 17px 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        font-size: 13px;
        border-radius: 22px;
        background: var(--dark);
        transition: all .3s linear;

        span {
          max-width: 200px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        img {
          margin-right: 5px;
        }

        &.active {
          background: var(--3077-ff, #3077FF);
        }
      }
    }

    .list_matherials {
      display: flex;
      flex-direction: column;

      .stage_item {
        margin-bottom: 30px;
        position: relative;
        font-size: 13px;

        svg {
          margin-right: 10px;
          min-width: 35px;
        }

        &:not(:last-child):after {
          content: "";
          position: absolute;
          top: 41px;
          left: 17px;
          width: 1px;
          background: var(--text_color_dark);
          height: 20px;
        }
      }
    }
  }

  .content_page {
    padding: 0 3px;
    /*height: 100%;*/

    .content_lesson {
      padding-right: 0;

      .image_file {
        width: 100%;
      }
    }

    .title {
      margin-bottom: 15px !important;
      margin-top: 55px;
      font-size: 20px !important;
    }

    .h2 {
      margin: 15px 0 10px 0;
      font-size: 18px !important;
    }

    pre {
      font-size: 14px !important;
      margin-bottom: 10px !important;
    }

    .student_answer {
      .title {
        margin-top: 0;
        font-size: 18px !important;
      }
    }
  }
}

@media (max-width: 820px) {
  .content_mobile {
    display: flex;
  }

}