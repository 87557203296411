
.added_generation {
  position: fixed;
  padding: 5px;
  width: 100%;
  display: none;
  bottom: 85px;
  left: 0;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  align-content: center;
  justify-content: center;
  border-radius: 15px 15px 0 0;
  background: var(--blue);
  transition: all .3s linear;

  img {
    margin-right: 10px;
  }

  &.show {
    visibility: initial;
    opacity: 1;
    z-index: 5;
  }
}

.sidebar_mobile {
  padding: 10px 15px 11px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  display: none;
  align-items: center;
  background: var(--dark);
  overflow-x: auto;
  transition: all .3s linear;

  .item {
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    min-height: 60px;

    &_icon {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #1D1E25;
      border-radius: 12px;
      width: 38px;
      height: 38px;

      img {
        width: 38px;
        height: 38px;
        border-radius: 12px;
      }

      &.none_background {
        background: transparent;
      }
    }

    &_label {
      margin-top: auto;
      color: #9197A8;
      white-space: nowrap;
    }
  }

  &.show {
    visibility: initial;
    opacity: 1;
    z-index: 5;
  }
}

@media (max-width: 820px) {
  .sidebar_mobile {
    display: flex;
  }
  .added_generation {
    display: flex;
  }
}