.AuthNew {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #16151B;
  color: #A2AACA;
  .container {
    position: relative;
    padding: 40px 100px;
    //height: 100%;
    //width: 700px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 40px;
    box-shadow: 0 10px 60px rgba(0, 0, 0, 0.13);
    border: 1px solid #343843;
    background-color: #201E26;
    z-index: 3;
    .form {
      width: 450px;
      position: relative;
      //padding: 20px;
      //z-index: 3;
    }
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .violet {
    color: #8146FF;
  }
  .pointer {
    cursor: pointer;
  }
}