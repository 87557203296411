@use "./src/styles/Variables";

.messenger {
  display: flex;
  margin: auto;
  padding-left: 50px;
  width: 100%;
  max-height: calc(100vh - 20px) !important;
  min-height: calc(100vh - 20px) !important;
  max-width: 1425px;
  color: white;
  overflow-y: auto;

  .main {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    max-width: 580px;
    width: 100%;
    background: #1D1E25;
    border-radius: 30px;

    .private_message-list {
      margin: 30px;

      .h1 {
        margin-bottom: 20px;
        text-align: center;
        font-size: 32px;
        line-height: 38px;
      }

      .search {
        padding-bottom: 20px;
        position: relative;
        width: 100%;
        border-bottom: 1px solid var(--dark);

        img {
          position: absolute;
          top: 4px;
          left: 4px;
        }

        .input_classic {
          padding: 3px 96px 5px 46px;
          height: 38px;
          font-size: 14px;
          line-height: 17px;
          color: white;
          border: 1px solid var(--dark);
          border-radius: 17px;
          background: transparent;
          transition: all .3s linear;
        }

        .btn {
          position: absolute;
          padding: 5px 25px 7px 25px;
          display: flex;
          justify-content: center;
          top: 4px;
          right: 4px;
          max-width: 90px;
          background: var(--dark);
          border-radius: 13px;
          cursor: pointer;

          span {
            font-size: 14px;
            line-height: 17px;
          }
        }
      }

      .message_list {
        padding-right: 5px;
        max-height: calc(100vh - 190px);
        overflow: auto;

        .message_item {
          margin-top: 20px;
          padding: 5px 15px 5px 5px;
          display: flex;
          align-items: center;
          width: 100%;
          background: var(--dark);
          border-radius: 18px;
          border: 1px solid transparent;
          cursor: pointer;
          transition: all .3s linear;

          img {
            margin-right: 10px;
            width: 43px;
            height: 43px;
            border-radius: 14px;
          }

          .message_detail {
            margin-right: 10px;
            display: flex;
            flex-direction: column;
            max-width: 400px;
            width: 100%;

            .name,
            .text {
              max-width: 400px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }

            .name {
              margin-bottom: 5px;
              font-size: 14px;
              line-height: 17px;
            }

            .text {
              font-size: 12px;
              line-height: 14px;
              color: #757A88;
            }
          }

          .last_date {
            margin-left: auto;
            color: var(--text_color_dark);
          }

          &:hover {
            border-color: var(--text_color_dark);
          }
        }
      }
    }

    .chat_performer {
      display: flex;
      flex-direction: column;
      height: 100%;

      .header {
        padding: 15px 20px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: var(--dark);
        border-radius: 30px;
        width: 100%;

        .prev_btn {
          padding: 0 15px 1px 15px;
          display: flex;
          height: 25px;
          align-items: center;
          background: #1D1E25;
          border-radius: 20px;
          cursor: pointer;

          svg {
            margin: 0 10px 0 0;
            transform: rotate(90deg);
          }

          span {
            margin-top: -1px;
            font-size: 14px;
            line-height: 17px;
          }
        }

        .name {
          transform: translateX(-20px);
        }

        .more_action {
          display: flex;
          cursor: pointer;
        }
      }

      .messages_list {
        padding: 0 25px;
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 155px);
        overflow-y: auto;
        width: 100%;

        .date {
          margin: 0 auto 20px auto;
          width: fit-content;
          color: #757A88;
          font-size: 12px;
          line-height: 14px;
        }

        .message_row {
          margin-bottom: 10px;
          display: flex;
          width: 100%;

          .message_block {
            margin-right: auto;
            padding: 15px;
            position: relative;
            max-width: 300px;
            width: fit-content;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #1E1F26;
            border-radius: 25px;

            .info {
              padding-bottom: 10px;
              margin-bottom: 10px;
              display: flex;
              align-items: center;
              border-bottom: 1px solid var(--background_lighten-dark);

              img {
                margin-right: 10px;
                width: 30px;
                height: 30px;
                min-width: 30px;
                min-height: 30px;
                max-width: 30px;
                max-height: 30px;
                border-radius: 50%;
              }

              .name {
                margin-right: 10px;
                font-size: 14px;
                line-height: 17px;
              }

              .time {
                font-size: 12px;
                line-height: 14px;
                color: #757A88;
              }
            }

            .message_text {
              font-size: 14px;
              line-height: 140%;
              white-space: pre-wrap;
              font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
            }

            .corner {
              position: absolute;
              bottom: 0;
              right: 0;
              opacity: 0;
            }

            &.current {
              margin-left: auto;
              margin-right: initial;
              background: var(--dark);
              border-radius: 20px;

              .corner {
                bottom: -4px;
                right: -6px;
                opacity: 1;
              }
            }
          }
        }
      }

      .footer {
        margin-top: auto;
        padding: 20px;
        width: 100%;
        display: flex;
        background: var(--dark);
        border-radius: 30px;

        .upload_file {
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 38px;
          height: 38px;
          min-width: 38px;
          background: #1D1E25;
          border-radius: 14px;

          img {
            margin-left: 2px;
          }
        }

        .send_message {
          position: relative;
          width: 100%;
          background: #1D1E25;
          border: 1px solid var(--dark);
          border-radius: 17px;

          .border {
            position: absolute;
            width: 1px;
            height: 20px;
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
            background: #757A88;
          }

          .btn_send {
            padding: 4px 10px;
            position: absolute;
            top: 4px;
            right: 4px;
            max-width: 87px;
            width: 100%;
            background: var(--dark);
            border-radius: 13px;
            cursor: pointer;

            span {
              font-size: 14px;
              line-height: 17px;
            }
          }
        }
      }
    }
  }
}