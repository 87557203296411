//@media (max-width: 1100px) {
//  .layout {
//    &.padding_left {
//      padding-left: 410px !important;
//    }
//  }
//}
//
//@media (max-width: 820px) {
//  .layout {
//    padding: 0 !important;
//
//    &.padding_left {
//      padding-left: 0 !important;
//    }
//  }
//}
//
//.layout {
//  width: 100%;
//  display: flex;
//  color: white;
//
//  .content {
//    margin: 10px auto 10px;
//    padding-right: 5px;
//    //max-width: 1310px;
//    max-width: calc(100% - 110px);
//    max-height: calc(100vh - 20px);
//    overflow-y: auto;
//    overflow-x: hidden;
//    width: 100%;
//    display: flex;
//    justify-content: center;
//    &.skipped {
//      max-width: calc(100% - 260px);
//    }
//  }
//
//  &.padding {
//    padding: 0 15px;
//  }
//
//  &.margin_content {
//    .content {
//      margin: 0 auto 10px;
//    }
//  }
//}
.layout {
  width: 100%;
  display: flex;
  color: white;
  min-height: 100vh;

  .content {
    margin: 10px auto 10px;
    max-width: calc(100% - 110px);
    max-height: calc(100vh - 20px);
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    &.skipped {
      max-width: calc(100% - 260px);
    }
  }

  &.padding {
    padding: 0 15px;
  }

  &.margin_content {
    .content {
      margin: 0 auto 10px !important;
    }
  }
}

@media (max-width: 820px) {
  .layout {
    padding: 10px !important;

    .content {
      max-width: 100%;
      margin-top: 0 !important;
      margin-bottom: 100px !important;
      &.skipped {
        max-width: 100%;
      }
    }
  }
}