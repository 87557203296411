@use './src/styles/Variables';

@media (max-width: 1410px) {
  .notification {
    margin: 0 15px !important;
  }
}

@media (max-width: 820px) {
  .notification {
    padding: 0 0 100px 0 !important;

  }
}

@media (max-width: 540px) {
  .notification {
    .notification_items {
      margin-right: 5px !important;

      .item {
        flex-direction: column !important;

        .type {
          margin-right: 0 !important;
          max-width: 100% !important;
          width: 100% !important;
        }

        .date {
          align-self: center !important;
        }

        .border {
          display: none !important;
        }

        .message {
          margin-right: 0 !important;
          text-align: center !important;
        }

        .btn {
          max-width: 100% !important;
          width: 100% !important;
        }
      }
    }
  }
}

.notification {
  width: 100%;
  color: white;

  .inner_block {
    position: relative;
    //max-width: 1330px;
    overflow-x: hidden;
    width: 100%;

    .notification_items {
      padding: 10px 10px 0 10px;
      width: 100%;
      background: var(--dark);
      border: 1px solid var(--background_lighten-dark);
      border-radius: 24px;

      .item {
        display: flex;
        align-items: baseline;
        padding: 5px 6px;
        margin-bottom: 10px;
        width: 100%;
        background: var(--background_lighten-dark);
        box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.3);
        border-radius: 15px;

        .type {
          margin-right: 20px;
          padding: 2px;
          height: 28px;
          max-width: 160px;
          min-width: 160px;
          text-align: center;
          border: 1px solid #FFFFFF;
          border-radius: 10px;
          white-space: nowrap;
        }

        .border {
          margin: 0 20px;
          width: 1px;
          height: 20px;
          align-self: center;
          background-color: #757A88;
        }

        .date,
        .course_name,
        .phone,
        .student_name {
          white-space: nowrap;
        }

        .message {
          margin-right: 15px;
          max-width: 245px;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .btn {
          margin-left: auto;
          padding: 2px;
          max-width: 165px;
          width: 100%;
          height: 28px;
          color: #B3DAFF;
          text-align: center;
          background: rgba(179, 218, 255, 0.2);
          border: 1px solid transparent;
          border-radius: 10px;
          transition: all .3s;
          cursor: pointer;

          &.show {
            background: rgba(48, 224, 161, 0.2);
            color: #30E0A1;
          }

          &:hover {
            transition: all .3s;
            border-color: var(--text_color_dark);
          }
        }
      }
    }

    .pagination_all {
      margin: 20px auto 0 auto;

      .ant-pagination-item {
        margin-right: 5px;
        background-color: transparent;
        border: none;

        a {
          border-radius: 8px !important;
          color: white;
          background-color: var(--dark);
        }

        &-active {
          a {
            background-color: lighten(#343843, 20%);
          }

          border-color: transparent;
        }
      }

      li.ant-pagination-options {
        display: none;
      }

      li.ant-pagination-prev,
      li.ant-pagination-next {
        display: none;
      }

      span.ant-pagination-item-ellipsis,
      span.anticon.anticon-double-right.ant-pagination-item-link-icon,
      span.anticon.anticon-double-left.ant-pagination-item-link-icon {
        color: #61687c;
      }
    }
  }
}

.modal_notification {
  max-width: 460px;
  width: 100% !important;
  background: #201E26;
  border: 1px solid var(--dark);
  box-shadow: 0px 30px 120px rgba(0, 0, 0, 0.99);
  border-radius: 30px;
  color: white;

  .close_modal {
    margin-left: auto;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #A2AACA;
    cursor: pointer;
  }

  .ant-modal-content,
  .ant-modal-body {
    background: #201E26;
    border-radius: 40px;
    box-shadow: none;

    .title {
      margin-bottom: 25px;
      text-align: center;
      font-size: 24px;
      line-height: 29px;
    }

    .name,
    .phone,
    .message {
      display: flex;
      margin-bottom: 15px;
      padding: 0 15px;
      height: 46px;
      padding-bottom: 2px;
      background: var(--dark);
      border: 1px solid var(--background_lighten-dark);
      border-radius: 20px;

      span {
        align-self: center;
      }
    }

    textarea.ant-input.textarea_antd.ant-input-disabled {
      background-color: var(--dark);
      color: white;
      border: 1px solid var(--background_lighten-dark);
      cursor: auto;
    }

    .parent_skills {
      padding: 15px 15px 10px 15px;
      margin-bottom: 25px;
      opacity: 1;
      max-height: 300px;
      overflow-y: auto;
      border: 1px solid #474C5A;

      display: flex;
      flex-wrap: wrap;
      font-size: 14px;
      line-height: 17px;
      color: #30E0A1;
      background: var(--dark);
      box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.11);
      border-radius: 16px;
      transition: max-height .3s, padding .5s, margin .8s, opacity .3s;

      .tag {
        padding: 3px 12px 5px 12px;
        margin: 0 5px 5px 0;
        width: fit-content;
        background: rgba(48, 224, 161, 0.2);
        border-radius: 16px;
      }
    }

    .link_redirect {
      padding: 5px 15px 6px 15px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      max-width: 240px;
      width: 100%;
      text-align: center;
      background: #3077FF;
      text-decoration: none;
      border-radius: 8px;
      transition: all .3s;
      cursor: pointer;

      span {
        font-size: 16px;
        color: white;
      }
    }
  }

  .ant-modal-body {
    padding-bottom: 0;
  }
}

@media (max-width: 500px) {
  .modal_notification {
    width: auto !important;
    margin-left: 15px !important;
    margin-right: 15px !important;

    .title {
      margin-bottom: 15px !important;
    }

    .close_modal {
      margin-bottom: 10px !important;
    }
  }
}