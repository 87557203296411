@import '../student/pages/SearchProfession/SearchProfession';
@import '../student/pages/SearchProfession/ModalFilter/ModalFilter';
@import "../student/pages/Auth/ModalWelcome/ModalWelcome";
@import "../student/pages/SearchProfession/ModalProfessionInfo/ModalProfessionInfo";
@import "../student/pages/SearchProfession/ModalFavorite/ModalFavorite";
@import "../student/pages/SearchProfession/ModalCourse/ModalCourse";
@import "ReuseComponents";
@import "src/student/pages/Landing/Landing";
@import "../student/pages/CourseListIsAuth/CourseListIsAuth";
@import "../student/pages/Lesson/Lesson";
@import "../student/pages/MyCourse/MyCourse";
@import "../student/pages/Profile/Profile";
@import "src/student/pages/Resume/Resume";
@import "src/student/pages/Notification/Notification";
@import "../student/pages/Payment/PaymentResponse/PaymentResp";
@import "src/student/pages/SearchTalants/SearchTalants";
@import "src/student/pages/SearchTalants/FreelanceList";
@import "src/student/pages/Messenger/Messenger";


.grecaptcha-badge {
  visibility: hidden;
}

:root {
  --full-height: 100vh;
  --dark: #343843;
  --background_lighten-dark: #474c5a;
  --text_color_dark: #A2AACA;
  --blue: #3077FF;
}

* {
  box-sizing: border-box;
  scrollbar-width: thin;
}

body {
  margin: 0;
  width: 100% !important;
  overflow-x: hidden;
  scrollbar-gutter: stable;
}

.app {
  display: flex;
  min-height: 100vh;
  background-color: #16151B;
  z-index: 1;

  &.back_color-white {
    background-color: white !important;
  }
}

pre {
  padding: 1px;
  margin: 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  white-space: pre-wrap;
}

::selection {
  background: var(--text_color_dark);
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 9em;
  background: var(--background_lighten-dark);
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.buttons_intro {
  display: flex;
  align-items: center;

  div {
    padding: 5px 10px;
    border: 1px solid black;
    cursor: pointer;

    &:first-child {
      margin-right: 10px;
    }
  }
}

.introjs {
  max-width: 400px !important;
  min-width: 400px !important;

  &-overlay {
    display: none;
  }

  &-helperLayer {
    z-index: 0;
  }

  &-arrow.left {
    border-right-color: var(--blue);
  }

  &-arrow.right {
    border-left-color: var(--blue);
  }

  &-arrow.top {
    border-bottom-color: var(--blue);
  }

  &-arrow.bottom {
    border-top-color: var(--blue);
  }

  &-tooltipbuttons {
    border-top-color: transparent;

    a {
      border-radius: 15px;
      color: white;
      border-color: #474C5A;
      background: transparent;
      text-shadow: none;
      transition: all .3s linear;

      &.introjs-nextbutton {
        border-color: #3077FF;
        background: #3077FF;
        box-shadow: 0 9px 20px rgba(48, 119, 255, 0.45);
      }

      &:hover,
      &:focus {
        color: white;
        border-color: #3077FF;
        background: #3077FF;
        box-shadow: 0 9px 20px rgba(48, 119, 255, 0.45);
        text-shadow: none;
      }
    }
  }

  &-tooltiptext {
    margin: 0 auto;
    padding: 0 0 20px 0;
    max-width: 380px !important;
    min-width: 380px !important;
    /*max-width: 210px;*/
    /*font-size: 20px;*/
    border-bottom: 1px solid #474C5A;

  }

  &-tooltip {
    max-width: 400px !important;
    min-width: 400px !important;
    background: rgba(30, 31, 38, 0.8);
    border: 1px solid #1E1F26;
    border-radius: 15px 24px 24px 24px;
    font-size: 13px;
    line-height: 16px;
    color: white;

    .introjs-skipbutton {
      color: white;
    }
  }
}

.introjs-tooltipReferenceLayer {
  height: auto !important;
}

.introjs-helperLayer {
  height: auto !important;
}

.ant-spin-dot-item {
  background: var(--blue);
}

.ant-message, .ant-popover {
  z-index: 10000000200;
}

.ant-popover-inner,
span.ant-popover-arrow-content {
  user-select: none;
  background-color: var(--background_lighten-dark);

  .ant-popover-message-title {
    color: var(--text_color_dark);
  }

  button.ant-btn.ant-btn-sm {
    color: var(--background_lighten-dark);

    &.ant-btn-primary {
      background-color: var(--blue);
      border-color: transparent;
      color: white;
    }

    &:hover {
      border-color: var(--text_color_dark);
    }
  }

  &::before {
    display: none;
    background-color: transparent;
  }
}