.profile_block {
  margin-top: 45px;
  display: flex;

  .left_block {
    width: 100%;
    line-height: 13px;
    z-index: 3;

    .profile_photo {
      padding: 15px 15px 14px 15px;
      background: #343843;
      border-radius: 24px;
      border: 1px solid #474C5A;
      box-shadow: 0 15px 20px rgba(0, 0, 0, 0.11);

      .photo {
        img {
          width: 80px;
          height: 80px;
        }
      }

      .title {
        margin: 5px 0 0 15px;
        align-self: center;

        div {
          margin-bottom: 5px;
          line-height: 19px;
          font-size: 16px;
          color: white;
        }

        p {
          font-size: 11px;
          color: var(--text_color_dark);
        }
      }

      .change_button {

        label {
          display: block;
          width: 100%;
          margin-top: 15px;
          padding: 8px;
          color: white;
          font-size: 14px;
          border: none;
          text-align: center;
          background: #3077FF;
          box-shadow: 0 9px 10px rgba(48, 119, 255, 0.27);
          border-radius: 15px;
          cursor: pointer;
          z-index: 3;
        }
      }

      div:first-child {
        justify-content: normal;
      }
    }

  }

  .right_block {
    margin-left: 20px;
    width: 100%;

    .fio {
      display: flex;
      flex-direction: column;

      div:first-child {
        margin-bottom: 23px;
      }

      p {
        margin: 0 0 5px 15px;
        color: var(--text_color_dark);
        font-size: 14px;
        line-height: 17px;
      }

      input {
        padding-top: 8.5px;
        padding-bottom: 8.5px;
      }
    }

    .mailing {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      white-space: nowrap;
      z-index: 3;

      label {
        z-index: 3;
      }

      span {
        color: var(--text_color_dark);
      }
    }

    .button_next {
      display: none;
    }
  }
}