.modal_certificate {
  padding-bottom: 0 !important;
  max-width: 440px !important;
  width: 100% !important;
  background: #201E26;
  border: 1px solid var(--dark);
  box-shadow: 0 30px 120px rgba(0, 0, 0, 0.99);
  border-radius: 40px;
  color: white !important;

  .ant-modal-content,
  .ant-modal-body {
    border-radius: 30px;
    box-shadow: none;

    .title {
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .h1 {
        font-size: 18px;
        font-weight: 500;
      }

      .close {
        margin-left: auto;
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #CF1A1A;
        cursor: pointer;
      }
    }
    .label {
      margin: 0 0 5px 15px;
      color: var(--text_color_dark);
    }
  }
}