.quiz_page {
  padding: 20px;
  margin-top: 20px;
  width: 100%;
  border-radius: 24px;
  border: 1px solid var(--background_lighten-dark);
  background: var(--dark);

  .title_quiz {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--background_lighten-dark);

    span {
      font-size: 24px;
    }

    .count_quiz {
      padding: 3px 15px 5px 15px;
      display: flex;
      align-items: center;
      width: fit-content;
      border-radius: 20px;
      background: #757A88;

      span {
        font-size: 14px;
        line-height: normal;


        &:last-child {
          margin-left: 3px;
          color: var(--text_color_dark);
        }
      }
    }
  }

  .variants_list {
    margin-top: 15px;

    .variant {
      margin-bottom: 15px;

      .form-group {
        margin-bottom: 0;
        display: flex;

        label {
          color: var(--text_color_dark);
          transition: all .3s linear;

          &:hover {
            color: white;
          }
        }

        label:before {
          top: -2px;
          background: rgba(162, 170, 202, 0.20);
          border: 1px solid var(--text_color_dark);
        }

        input:checked + label:after {
          position: absolute;
          top: 2.5px;
          left: 7px;
          border: solid var(--text_color_dark);
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }

      &.active {
        .form-group {
          label {
            color: white;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .title_result {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--background_lighten-dark);

    img {
      margin-right: 15px;
    }

    span {
      font-size: 20px;
      color: white;
    }
  }

  .result_list {
    margin-top: 15px;
    padding-left: 0;

    font-size: 16px;
    line-height: 19px;

    & > div {
      margin-bottom: 10px;
      display: flex;
      text-align: right;
    }

    .index {
      margin-right: 10px;
      min-width: 26px;
      color: white;

      &.false {
        color: #FF3434;
      }

      &.true {
        color: #30E0A1;
      }
    }

    span {
      &.true {
        color: #30E0A1;
      }

      &.false {
        color: #FF3434;
      }
    }
  }
}

.recomendation_quiz {
  display: flex;
  flex-direction: column;

  .h2 {
    font-size: 20px;
    color: white;
  }

  span {
    font-size: 18px;
    color: var(--text_color_dark)
  }
}

.result_attemps-denied {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: fit-content;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: var(--text_color_dark);

  span {
    margin-top: 20px;
  }
}