@use '../../../styles/Variables';

@media (max-width: 1428px) {
  .course_list {

    .catalog {
      ul {
        flex-wrap: wrap !important;
      }
    }
  }
}

@media (max-width: 1410px) {
  .course_list {
    margin: 0 15px !important;
  }
}

@media (max-width: 1260px) {
  .course_list {
    .sort {
      flex-wrap: wrap !important;
      justify-content: start !important;
      align-items: center;

      .search {
        margin-right: 0 !important;
        margin-bottom: 15px;
      }

      .checkbox_paid {
        width: initial !important;
        height: fit-content;
      }
    }
  }
}

@media (max-width: 982px) {
  .course_list {
    .sort {
      .category {
        .select_block {
          min-width: 230px !important;
        }
      }

      .checkbox_paid {
        margin-top: 15px !important;
        margin-left: 0 !important;
        min-width: 230px !important;
      }
    }
  }
}

@media (max-width: 1100px) {
  .course_list {
    .catalog {
      ul {
        flex-wrap: wrap !important;
        justify-content: space-evenly;
      }
    }
  }
}

@media (max-width: 820px) {
  .course_list {
    padding-left: 0 !important;

    .group {
      margin-left: 0 !important;
      padding-bottom: 100px !important;
    }

    .top_block-mobile {
      display: block !important;
    }

    .sort {
      display: none !important;
    }
  }
}

@media (max-width: 720px) {
  .course_list {
    .top_block {
      flex-direction: column-reverse !important;
      padding-bottom: 15px !important;
      position: relative;

      .left {
        font-size: 20px !important;
        line-height: 24px !important;
      }

      .right {
        width: 100%;
        margin-bottom: 10px;

        div {
          width: 100% !important;
        }
      }

      .drop_down-language {
        margin: 10px 0 !important;
      }

      .btn_redirect {
        margin: 10px 0 !important;
        position: absolute;
        top: 0;
        right: 5px;
      }
    }

    .sort {
      display: none !important;
    }

    .actual_title {
      margin-top: 15px !important;
      font-size: 18px !important;
      line-height: 21px !important;
    }

    .actual_list {
      margin-bottom: 20px;

      ul {
        margin-top: 25px !important;
      }

      li {
        img {
          width: 50px;
          height: 50px;
        }

        .border {
          width: 50px !important;
          height: 50px !important;
        }
      }
    }

    .traektory_title {
      display: none !important;
    }

    .parent_traektory {
      display: none !important;
    }
  }
}

@media (max-width: 502px) {
  .course_list {
    .catalog {
      ul {
        .reuse_course-item {
          margin: 0 auto 20px auto !important;
        }
      }
    }
  }
}

.course_list {
  width: 100%;
  color: white;

  ul, li {
    margin: 0;
    padding: 0;
  }

  .top_block {
    padding-bottom: 20px;
    margin-bottom: 20px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #464C5A;

    .left {
      font-size: 32px;
      line-height: 30px;
    }

    .right {
      display: flex;
      height: fit-content;
      align-self: center;
      background: rgba(162, 170, 202, 0.2);
      border: 1px solid #A2AACA;
      border-radius: 15px;

      div {
        width: 150px;
        padding: 3px;
        color: #757A88;
        text-align: center;
        font-size: 14px;
        line-height: 17px;
        background-color: transparent;
        z-index: 0;
        transition: all .3s ease;
        cursor: pointer;

        &.active {
          color: white;
          background: #3077FF;
          border-radius: 14px;
          z-index: 1;
        }
      }
    }

    .drop_down-language {
      margin-left: auto;
      margin-right: 15px;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 95px;
      width: 100%;
      padding: 4px 9px;
      background: var(--dark);
      border: 1px solid var(--background_lighten-dark);
      border-radius: 17px;
      color: white;
      cursor: pointer;
      z-index: 5;

      img {
        max-height: 15px;
        max-width: 21px;
      }

      svg {
        transition: all .5s;
        min-height: 5px;
        min-width: 10px;
      }

      .drop_down {
        position: absolute;
        top: 18px;
        left: -1px;
        padding-top: 10px;
        opacity: 0;
        transform: scale(0);
        max-height: 0;
        border-radius: 0 0 17px 17px;
        width: calc(100% + 2px);
        border: 1px solid var(--background_lighten-dark);
        background-color: var(--dark);
        border-top: 1px solid transparent;
        transition: all .3s;
        z-index: -2;

        .language {
          display: flex;
          padding: 4px 10px 4px 9px;
          align-items: center;
          cursor: pointer;

          img {
            max-height: 15px;
            max-width: 21px;
            margin-right: 9px;
          }

          &:hover {
            background-color: var(--background_lighten-dark);
          }

          &:last-child {
            border-radius: 0 0 17px 17px;
          }
        }

        &.open {
          transition: all .3s;
          opacity: 1;
          transform: scale(1);
          max-height: 150px;
        }
      }

      &.open {
        svg {
          transition: all .5s;
          transform: rotate(180deg);
        }
      }
    }

    .btn_redirect {
      padding: 6px;
      text-align: center;
      max-width: 150px;
      height: 35px;
      width: 100%;
      font-size: 14px;
      background: #3077FF;
      box-shadow: 0 9px 20px rgb(48 119 255 / 45%);
      border-radius: 15px;
      cursor: pointer;
    }
  }

  .top_block-mobile {
    margin-bottom: -10px;
    display: none;

    .top_filter {
      padding: 4px 6px;
      margin-bottom: 20px;
      position: relative;
      display: flex;
      justify-content: space-between;
      background: var(--dark);
      border-radius: 34px;
      transition: all .2s ease;


      .icon {
        display: flex;

        svg {
          width: 34px;
          height: 34px;
        }
      }

      .btn_search {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        cursor: pointer;
        transition: opacity .5s ease, z-index .5s ease;


        &.hide {
          transition: opacity .5s ease, z-index .5s ease;
          opacity: 0;
          z-index: -1;
        }
      }

      .search {
        display: flex;
        position: relative;
        opacity: 0;
        z-index: -1;
        transition: opacity .5s ease, z-index .5s ease;

        .input_classic {
          padding-left: 40px;
          height: 34px;
          font-size: 14px;
          border: 1px solid var(--background_lighten-dark);


          &:focus {
            border-color: var(--text_color_dark) !important;
          }

          &::placeholder {
            font-size: 14px;
          }
        }

        span {
          margin-left: 10px;
          align-self: center;
        }

        img {
          position: absolute;
          top: 2px;
          left: 4px;
          z-index: 3;
        }

        &.active {
          transition: opacity .5s ease, z-index .5s ease;
          opacity: 1;
          z-index: 3;
        }
      }

      .settings {
        align-self: center;
      }

    }
  }

  .sort {
    padding: 0 0 18px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #464C5A;

    ul {
      display: flex;

      li {
        margin-right: 15px;
        transition: all .3s ease;

        &:not(:first-child) {
          cursor: pointer;
        }
      }
    }

    .search {
      margin-right: 25px;
      position: relative;
      width: 100%;

    }

    .category {
      display: flex;
      margin: 5px 25px 0 0;
      position: relative;
      align-self: center;

      span {
        font-size: 16px;
        line-height: 19px;
        white-space: nowrap;
      }

      .select_block {
        margin-top: -10px;
        position: relative;
        padding: 10px 10px 5px 15px;
        min-width: 210px;
        border-radius: 17px;
        transition: all .6s ease;
        cursor: pointer;

        span {
          font-size: 16px;
          line-height: 19px;
          white-space: nowrap;
        }

        svg {
          position: absolute;
          top: 17px;
          right: 5px;
        }

        .drop_down-category {
          position: absolute;
          top: 30px;
          left: 0;
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: column;
          height: 0;
          opacity: 0;
          transform: scale(0);
          background-color: var(--dark);
          border-radius: 0 0 17px 17px;
          transition: all .3s ease;
          z-index: 3;

          li {
            padding: 8px 0 8px 15px;
            margin-right: 0;
            font-size: 16px;
            line-height: 19px;
            color: #757A88;

            &:hover {
              background-color: #4B505C;
            }

            &:last-child {
              border-radius: 0 0 17px 17px;
            }

            &:first-child {
              margin-top: 10px;
            }
          }

          &.active {
            width: 100%;
            height: fit-content;
            opacity: 1;
            transform: scale(1);
          }
        }

        &.active {
          border-radius: 17px 17px 0 0;
          background-color: var(--dark);

          svg {
            transform: rotate(180deg);
          }
        }

        &:hover {
          /* border: 1px solid #A2AACA;
           border-radius: 15px;*/
        }
      }
    }

    .checkbox_paid {
      display: flex;
      align-items: center;
      width: fit-content;

      span {
        margin-right: 15px;
        cursor: pointer;
      }

      .form-group {
        margin-bottom: 0;

        label::before {
          border-color: var(--blue);
          background: rgba(48, 119, 255, 0.2);
        }

        label::after {
          border-color: var(--blue);
        }
      }
    }
  }

  .actual_title {
    margin: 20px 0 15px 0;
    display: flex;
    justify-content: space-between;

    span {
      font-size: 24px;
      line-height: 29px;
    }

    .btn_show {
      padding: 6px 15px;
      margin-top: 3px;
      font-size: 14px;
      line-height: 17px;
      color: white;
      align-self: center;
      background: #8146FF;
      box-shadow: 0px 9px 10px rgba(48, 119, 255, 0.27);
      border-radius: 13px;
      cursor: pointer;
    }
  }

  .actual_list {
    ul {
      margin-top: 20px;
      padding-bottom: 5px;
      overflow-x: auto;
      display: flex;

      li {
        padding: 5px;
        margin-right: 10px;
        position: relative;
        width: 86px;
        min-width: 86px;
        height: 86px;
        border: 1px solid #A2AACA;
        border-radius: 50%;
        cursor: pointer;

        .border {
          position: absolute;
          top: -5px;
          left: -5px;
          width: 85px;
          height: 85px;
          background-color: transparent;
          border: 1px solid #A2AACA;
          z-index: 2;
          border-radius: 50%;
        }

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 78px;
          height: 78px;
          border-radius: 50%;
        }
      }
    }
  }

  .traektory_title {
    margin: 20px 0;
    font-size: 24px;
    line-height: 29px;
  }

  .parent_traektory {
    padding: 12px;
    background: #343843;
    border-radius: 20px;
    transition: all .3s ease;

    .traektory {
      display: flex;
      flex-wrap: wrap;
      max-height: 80px;
      margin-bottom: 7px;
      overflow: auto;
      transition: all .3s ease;

      .skill {
        padding: 6px 32px 8px 10px;
        margin-right: 5px;
        margin-bottom: 10px;
        display: flex;
        width: fit-content;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        line-height: 17px;
        border: 1px solid transparent;
        background: rgba(22, 21, 27, 0.65);
        border-radius: 29px;
        cursor: pointer;
        user-select: none;

        span {
          font-size: 14px;
          line-height: 16px;
          color: white;
        }

        img {
          position: absolute;
          top: 50%;
          right: 4px;
          transform: translateY(-50%);
          cursor: pointer;
        }

        &.active {
          border-color: #30E0A1;
        }
      }
    }

    .btn_bottom {
      padding: 5px;
      text-align: center;
      background: #474C5A;
      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.6);
      border-radius: 24px;
      cursor: pointer;
      user-select: none;
    }
  }

  .catalog {
    margin-top: 30px;
    position: relative;

    ul, li {
      padding: 0;
      margin: 0;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
    }

    .reuse_course-item {
      min-width: 297px;
      width: calc(25% - 30px);
      margin-bottom: 20px;
      position: relative;
      display: flex;
      flex-direction: column;
      background: #343843;
      border-radius: 30px;

      .rate {
        position: absolute;
        padding: 4px 14px;
        top: 25px;
        left: 22px;
        background: rgba(32, 30, 38, 0.6);
        border-radius: 34px;

        .ant-rate {
          font-size: 10px;
        }

        li.ant-rate-star {
          margin-right: 3px;
          margin-bottom: 0;
          background-color: transparent;
        }
      }

      .popular {
        position: absolute;
        top: 23px;
        right: 22px;
        display: flex;
        padding: 2px 3px;
        background: rgba(32, 30, 38, 0.6);
        border-radius: 34px;

        span {
          margin: 0 10px;
          font-size: 14px;
        }
      }

      .category {
        display: flex;
        margin: 0 20px 15px 20px;
        padding: 4px 10px;
        width: fit-content;
        border-radius: 34px;

        img {
          margin-right: 10px;
          align-self: center;
          user-select: none;
        }

        span {
          font-size: 14px;
          user-select: none;
        }
      }

      .info {
        margin: 0 20px 7px 20px;
        max-width: 280px;
        font-size: 20px;
        line-height: 24px;
        overflow-wrap: break-word;
      }

      .stage {
        ul, li {
          padding: 0;
          margin: 0;
        }

        ul {
          margin: 0 20px 5px 20px;
          justify-content: flex-start;
        }

        li {
          white-space: nowrap;
          font-size: 16px;
          color: var(--text_color_dark);

          &:not(:last-child) {
            margin-right: 15px;
          }
        }
      }

      .title_skill {
        margin: 0 20px 10px 20px;
        font-size: 16px;
      }

      .key_skills {
        margin: 0 20px 10px 20px;
        max-height: 140px;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;

        .skills {
          display: flex;
          padding: 4px 10px;
          margin-bottom: 5px;
          position: relative;
          flex-wrap: wrap;
          color: var(--text_color_dark);
          font-size: 14px;
          background: rgba(162, 170, 202, 0.2);
          border-radius: 16px;

          img {
            position: absolute;
            top: 8px;
            right: 5px;
            cursor: pointer;
          }

          &:not(:last-child) {
            margin-right: 8px;
          }
        }
      }

      .date_course {
        padding: 3px;
        margin: 0 20px 10px 20px;
        width: fit-content;
        background: rgba(32, 30, 38, 0.6);
        border-radius: 34px;

        img {
          width: 19px;
          height: 19px;
          vertical-align: sub;
        }

        span {
          margin: 0 7px;
          font-size: 14px;
        }
      }

      .bottom_block {
        margin-top: auto;

        a {
          color: white;
        }
      }

      .cost {
        margin: 0 20px 15px 20px;
        font-size: 16px;

        span {
          margin-right: 5px;
        }
      }
    }

    .pagination_all {
      margin: 0 auto;

      ul {
        display: block;
      }

      .ant-pagination-item {
        margin-right: 5px;
        background-color: transparent;
        border: none;

        a {
          border-radius: 8px !important;
          color: white;
          background-color: var(--dark);
        }

        &-active {
          a {
            background-color: lighten(#343843, 20%);
          }

          border-color: transparent;
        }
      }

      li.ant-pagination-options {
        display: none;
      }

      li.ant-pagination-prev,
      li.ant-pagination-next {
        display: none;
      }

      span.ant-pagination-item-ellipsis,
      span.anticon.anticon-double-right.ant-pagination-item-link-icon,
      span.anticon.anticon-double-left.ant-pagination-item-link-icon {
        color: #61687c;
      }
    }

    .ant-spin.ant-spin-spinning {
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
  }

  .block_dots {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }


  &.not_authenticated {
    max-width: 1330px !important;
    padding-left: 0 !important;
    margin: 0 auto;

    .group {
      margin-left: 0 !important;
    }
  }
}

.modal_traektory-mobile {
  .h3 {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 21px;
    color: white;
  }

  .block_traektory {
    padding: 15px 15px 10px 15px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    background: var(--background_lighten-dark);
    border: 1px solid var(--text_color_dark);
    border-radius: 20px;

    .tag {
      padding: 6px 32px 8px 10px;
      margin-right: 5px;
      margin-bottom: 10px;
      display: flex;
      width: fit-content;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      line-height: 17px;
      border: 1px solid transparent;
      background: rgba(22, 21, 27, 0.65);
      border-radius: 29px;
      cursor: pointer;
      user-select: none;

      span {
        font-size: 14px;
        line-height: 16px;
        color: white;
      }

      img {
        position: absolute;
        top: 50%;
        right: 4px;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }


    &.added {
      .tag {
        position: relative;
        padding-right: 30px;
        color: var(--text_color_dark);
        background: rgba(162, 170, 202, 0.2);

        img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 10px;
          cursor: pointer;
        }
      }
    }
  }

  .btn_bottom {
    padding: 5px;
    margin-top: 7px;
    text-align: center;
    background: #474C5A;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.6);
    border-radius: 24px;
    color: white;
    cursor: pointer;
  }
}

.modal_actual {
  width: 100% !important;
  max-width: 800px;

  .ant-modal-body {
    .close {
      /*  position: absolute;
        top: 0px;
        right: -30px;*/
      cursor: pointer;
    }

    max-width: 500px;
    height: auto;
    width: 100%;
    margin: 0 auto;

    .parent_loader {
      position: relative;
      margin-bottom: 5px;
      width: 100%;
      height: 2px;
      border-radius: 2px;
      background-color: var(--background_lighten-dark);

      .loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 2px;
        border-radius: 2px;
        background-color: white;
        transition: width .0s linear;

        &.active {
          width: 100%;
          transition: width 7s linear;
        }
      }

      &:last-child {
        margin-left: 10px;
      }
    }

    .show_video {
      position: relative;

      .video {
        .react-player {
          width: 100% !important;
          height: 515px !important;
          margin-bottom: 0 !important;
        }

        video,
        iframe {
          border-radius: 20px !important;
        }
      }
    }

    .show_image {
      position: relative;

      img {
        margin: auto;
        width: 100%;
        max-width: 500px;
        height: auto;
        border-radius: 20px;
      }
    }

    .btn_redirect {
      position: absolute;
      bottom: 25%;
      left: 50%;
      transform: translateX(-50%);
      padding: 4px 15px;
      color: white;
      width: fit-content;
      text-align: center;
      white-space: nowrap;
      background: #3077FF;
      box-shadow: 0 9px 10px rgba(48, 119, 255, 0.27);
      border-radius: 13px;
      transition: all .3s linear;
      cursor: pointer;
      z-index: 1;
    }

    .block_dots {
      margin-top: 40px;
      display: flex;
      justify-content: center;
    }
  }
}
