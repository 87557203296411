.reuse_modal {
  max-width: 1310px !important;
  width: 100% !important;
  top: 30px !important;
  color: white !important;

  .ant-modal-body {
    border-radius: 20px;
    padding: 20px 30px;
  }
}