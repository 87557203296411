.modal_size {
  padding-bottom: 0 !important;
  max-width: 1330px !important;
  top: 30px !important;
  width: 100% !important;
  background: #201E26;
  border: 1px solid var(--dark);
  box-shadow: 0 30px 120px rgba(0, 0, 0, 0.99);
  border-radius: 30px;
  color: white !important;

  .ant-modal-content,
  .ant-modal-body {
    border-radius: 25px;
    box-shadow: none;

    .close {
      position: absolute;
      top: -20px;
      right: -20px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--text_color_dark);
      cursor: pointer;
    }

    .planning_course {
      padding: 0;
      margin: 0;
      max-width: 100%;

      .steps {
        pre {
          max-height: 570px;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .modal_size {
    max-width: calc(100% - 30px) !important;
    .close {
      right: 0 !important;
    }
  }
}
@media (max-width: 820px) {
  .modal_size {
    .h1 {
      font-size: 22px !important;
    }
    .buttons {
      .btn {
        max-width: 30px;
        max-height: 30px;
        svg {
          max-width: 15px;
          max-height: 15px;
        }
      }
    }
  }
}