.AuthForm {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .AuthFormBtn {
    .text_in {
      font-size: 20px;
    }
  }
  .title {
    display: flex;
    justify-content: center;
    color: white;
    font-size: 54px;
    white-space: nowrap;
    line-height: 1;
  }
  .auth-item {
    height: 45px;
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #30333D;
    border-radius: 25px;
    background-color: transparent;
    outline: none;
    color: #A2AACA;
    .auth-redirect {
      position: relative;
      display: flex;
      align-items: center;
      font-size: 20px;
      img {
        position: absolute;
        left: -40px;
      }
    }
    //&.input:-webkit-autofill, &.input:-webkit-autofill:active, &.input:-webkit-autofill:focus, &.input:-webkit-autofill:hover {
    //  -webkit-box-shadow: 0 0 0 1000px #30333D inset !important;
    //  box-shadow: 0 0 0 1000px #30333D inset !important;
    //  background-color: #30333D !important;
    //  color: #A2AACA !important;
    //  appearance: none;
    //  -webkit-appearance: none;
    //  -moz-appearance: none;
    //}
  }

  .variant {
    position: relative;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    & > div {
      width: 50%;
      padding: 0;
      color: white;
      font-size: 16px;
    }
    & > .email {
      position: relative;
      z-index: 5;
      border-right: none;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
    & > .phone {
      position: relative;
      z-index: 5;
      border-left: none;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
    .figure {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 1;
      border-radius: 25px;
      background-color: #9554FA;
      transition: 0.3s;
      &.email {
      }
      &.phone {
        transform: translateX(100%);
      }
    }
  }
}