@use '../../../styles/Variables';

@media (max-width: 1445px) {
  .my_course {
    .sort {
      .category {
        margin-top: 15px !important;
      }
    }
  }
}

@media (max-width: 1430px) {
  .my_course {
    .my_course-list {
      ul {
        max-width: calc(100% - 30px);
        flex-wrap: wrap !important;
        justify-content: space-evenly;
      }
    }
  }
}

@media (max-width: 1410px) {
  .my_course {
    margin: 0 15px !important;
  }
}

@media (max-width: 1260px) {
  .my_course {
    .group_traektory {
      flex-direction: column !important;

      .my_skills {
        margin-right: 0 !important;
        width: auto !important;
      }

      .need_skills {
        margin-top: 15px;
        width: auto !important;
      }
    }
  }
}

@media (max-width: 1150px) {
  .my_course {
    .sort {
      flex-direction: column !important;

      .list {
        margin-top: 15px !important;
      }
    }
  }
}

@media (max-width: 1035px) {
  .my_course {
    .group_traektory {
      flex-direction: column !important;

      .my_skills {
        margin-bottom: 15px;
      }
    }
  }
}

@media (max-width: 920px) {
  .my_course {
    .sort {
      flex-direction: column !important;

      .category {
        margin-top: 15px;
      }
    }
  }
}

@media (max-width: 823px) {
  .my_course {
    padding-left: 0 !important;

    .my_course-list {
      padding-bottom: 100px !important;
    }
  }
}

@media (max-width: 820px) {
  .my_course {
    .top_block-mobile {
      display: block !important;
    }

    .main {
      margin-left: 0 !important;
      padding-bottom: 100px;
    }

    .group_traektory {
      display: none !important;
    }

    .sort {
      display: none !important;
    }

    .my_course-list {
      ul {
        max-width: 100% !important;

      }
    }

  }

}

@media (max-width: 502px) {
  .my_course {
    .my_course-list {
      .reuse_course-item {
        margin: 0 auto 10px auto !important;
        margin-right: 0 !important;
        flex-grow: 1 !important;
      }
    }
  }
}

.my_course {
  width: 100%;
  color: white;

  ul, li {
    margin: 0;
    padding: 0;
  }

  .top_block-mobile {
    margin-bottom: -10px;
    display: none;

    .top_filter {
      padding: 4px 6px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      background: #343843;
      border-radius: 34px;
      transition: all .2s ease;

      .icon {
        display: flex;

        svg {
          width: 34px;
          height: 34px;
        }
      }

      .settings {
        align-self: center;
      }

    }
  }

  .group_traektory {
    display: flex;

    .my_skills,
    .need_skills {
      .title {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 24px;
        white-space: nowrap;
      }

      .skill_block {
        padding: 12px 12px 7px 12px;
        max-height: 96px;
        min-height: 96px;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        background-color: var(--dark);
        border-radius: 20px;

        .skill {
          padding: 4px 5px 5px 12px;
          margin: 0 5px 10px 0;
          position: relative;
          display: flex;
          height: 32px;
          align-items: center;
          width: fit-content;
          background: rgba(22, 21, 27, 0.65);
          border-radius: 29px;

          span {
            color: white;
          }

          img {
            margin-left: 5px;
            cursor: pointer;
          }

          &.added {
            border: 1px solid rgba(48, 224, 161, 0.6);
          }
        }
      }
    }

    .my_skills {
      width: 35%;
      margin-right: 14px;

      .skill_block {
        .skill {
          padding: 4px 12px 5px 12px;
        }
      }
    }

    .need_skills {
      width: 65%;
    }
  }

  .sort {
    padding: 28px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border: none;
    user-select: none;

    .list {
      display: flex;
      margin: 0 auto;

      li {
        font-size: 16px;
        line-height: 19px;
        margin-right: 15px;
        cursor: pointer;

        &:first-child {
          cursor: auto;
        }
      }
    }

    .category {
      display: flex;
      position: relative;

      span {
        margin-right: 15px;
        font-size: 16px;
        line-height: 19px;
      }

      .select_block {
        margin-top: -10px;
        position: relative;
        padding: 10px 10px 5px 15px;
        min-width: 240px;
        border-radius: 17px;
        border: 1px solid transparent;
        transition: all .6s ease;
        cursor: pointer;

        span {
          font-size: 16px;
          line-height: 19px;
          white-space: nowrap;
        }

        svg {
          position: absolute;
          top: 15px;
          right: 10px;
        }

        .drop_down-category {
          position: absolute;
          top: 30px;
          left: -1px;
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: column;
          height: 0;
          opacity: 0;
          transform: scale(0);
          background-color: var(--dark);
          border-radius: 0 0 17px 17px;
          transition: all .3s ease;
          border: 1px solid transparent;
          z-index: 3;

          li {
            padding: 8px 0 8px 15px;
            margin-right: 0;
            font-size: 16px;
            line-height: 19px;
            color: #757A88;

            &:hover {
              background-color: #4B505C;
            }

            &:last-child {
              border-radius: 0 0 17px 17px;
            }

            &:first-child {
              margin-top: 10px;
            }
          }

          &.active {
            width: calc(100% + 2px);
            height: fit-content;
            opacity: 1;
            transform: scale(1);
            border-color: Variables.$text_color_light;
            border-top-color: transparent;
          }
        }

        &.active {
          border-radius: 17px 17px 0px 0px;
          background-color: var(--dark);
          border-color: Variables.$text_color_light;
          border-bottom-color: transparent;

          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .my_course-list {
    margin-left: 0;
    padding: 0;
    position: relative;

    ul, li {
      padding: 0;
      margin: 0;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
    }

    .reuse_course-item {
      margin: 0 0px 20px 0px;
      min-height: 600px;

      .stage {
        ul {
          justify-content: flex-start;
        }

        li:not(:last-child) {
          margin-right: 5px;
        }
      }
    }

    .pagination_all {
      margin: 0 auto;

      .ant-pagination {
        display: block;
      }

      .ant-pagination-item {
        margin-right: 5px;
        background-color: transparent;
        border: none;

        a {
          border-radius: 8px !important;
          color: white;
          background-color: var(--dark);
        }

        &-active {
          a {
            background-color: lighten(#343843, 20%);
          }

          border-color: transparent;
        }
      }

      li.ant-pagination-options {
        display: none;
      }

      li.ant-pagination-prev,
      li.ant-pagination-next {
        display: none;
      }

      span.ant-pagination-item-ellipsis,
      span.anticon.anticon-double-right.ant-pagination-item-link-icon,
      span.anticon.anticon-double-left.ant-pagination-item-link-icon {
        color: #61687c;
      }
    }

    .ant-spin.ant-spin-spinning {
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
  }

}

.modal_feedback_course {
  width: 100% !important;
  max-width: 500px;
  background: #201E26;
  border: 1px solid var(--dark);
  box-shadow: 0px 30px 120px rgba(0, 0, 0, 0.99);
  border-radius: 25px;
  color: white;

  .title {
    margin-bottom: 20px;
    max-width: 300px;
    width: 100%;
    font-size: 24px;
    line-height: 29px;
  }

  .rate_title {
    margin: 0 0 5px 15px;
    font-size: 14px;
    line-height: 17px;
    color: var(--text_color_dark);
  }

  .row_rate {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    padding: 0 15px;
    height: 40px;
    background: var(--dark);
    border: 1px solid var(--background_lighten-dark);
    border-radius: 16px;

    .svg_feedback {
      margin-right: 7px;
      vertical-align: text-top;
      cursor: pointer;

      &:hover,
      &.active {
        path {
          fill: #FFD23B;
        }
      }
    }
  }

  .feedback_title {
    margin: 0 0 5px 15px;
    font-size: 14px;
    line-height: 17px;
    color: var(--text_color_dark);
  }

  .feedback_text {
    margin-bottom: 25px;

    .textarea_antd {
      padding: 8px 15px;
      max-height: 115px;
      min-height: 115px;
      overflow-y: auto;
      resize: none;
      font-size: 16px;
      line-height: 19px;
      color: white;
      background: var(--dark);
      border: 1px solid var(--background_lighten-dark);
      border-radius: 16px;
      box-shadow: none;

      &::placeholder {
        color: #757A88;
        font-size: 16px;
      }

      &:hover {
        border-color: transparent;
      }

      &:focus {
        border-color: var(--text_color_dark);
        box-shadow: none;
      }

      &:active {
        border-color: var(--text_color_dark);
        box-shadow: none;
      }

    }
  }

  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }

  .ant-modal-body {
    padding-bottom: 0px !important;
    background: #201E26 !important;
    box-shadow: none;
  }
}

.modal_traektory-my-course {
  color: white;

  .title {
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 22px;
    border-bottom: 1px solid var(--background_lighten-dark);

    img {
      margin-right: 10px;
    }
  }

  .h3 {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 21px;
    color: white;
  }

  .block_traektory {
    padding: 15px 15px 10px 15px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    background: var(--background_lighten-dark);
    border: 1px solid var(--text_color_dark);
    border-radius: 20px;

    .skill {
      margin-right: 7px;
      margin-bottom: 5px;
      padding: 4px 10px;
      position: relative;
      height: fit-content;
      font-size: 14px;
      line-height: 17px;
      border-radius: 34px;
      background-color: rgba(48, 224, 161, 0.2);
      color: #30E0A1;

      img {
        position: absolute;
        top: 6px;
        right: 8px;
        cursor: pointer;
      }

      &.added {
        color: #FFD23B;
        background: rgba(255, 210, 59, 0.2);
      }
    }
  }

  .close {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
}