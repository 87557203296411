@media (max-width: 820px) {
  .comment_input {
    margin-bottom: 30px;
  }
  .comment {
    margin: 15px !important;
  }
}

.comment_title {
  margin: 15px 0;
  color: white;
  font-size: 24px;
  line-height: 29px;
}

.comment {
  padding: 7px 30px 7px 53px;
  margin: 15px 0;
  position: relative;
  background-color: #201E26;
  border-radius: 15px;

  &_photo {
    position: absolute;
    top: 5px;
    left: 5px;
  }

  &_fio {
    margin-bottom: 3px;
    font-size: 14px;
    line-height: 17px;
  }

  &_date {
    margin-bottom: 14px;
    font-size: 12px;
    line-height: 14px;
    color: #474C5A;
  }

  &_text {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--background_lighten-dark);
    font-size: 16px;
    line-height: 19px;
  }

  &_like-dislike {
    display: flex;

    span {
      margin-right: 10px;
      font-size: 14px;
      line-height: 17px;
      cursor: pointer;
    }

    svg {
      margin-right: 5px;
      align-self: center;
      cursor: pointer;
    }
  }
}

.comment_input {
  position: relative;
  margin-top: auto;

  .input_classic {
    border-radius: 15px;
    padding: 9px 50px 9px 20px;

    &::placeholder {
      font-size: 16px;
      line-height: 19px;
      color: #757A88;
    }
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;
    cursor: pointer;
  }
}