.SkillSetContainer {
  //max-width: 100%;
  width: 100%;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  & > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //.nav {
    //  display: flex;
    //  max-width: 540px;
    //  margin-top: 5px;
    //  width: 100%;
    //  height: 25px;
    //  background: rgba(162, 170, 202, 0.2);
    //  border: 1px solid var(--text_color_dark);
    //  border-radius: 15px;
    //  & > div {
    //    width: 100%;
    //    text-align: center;
    //    color: #757A88;
    //    transition: all 0.7s ease;
    //    white-space: nowrap;
    //    cursor: pointer;
    //  }
    //  & > div.active {
    //    background: #3077FF;
    //    border-radius: 26px;
    //    color: white;
    //  }
    //}
    .page_title {
      margin: 0;
      margin-bottom: -15px;
      display: flex;
      align-items: center;
      font-size: 32px;
      line-height: 38px;
      min-height: 38px;
      font-weight: 400;
      color: white;
    }
  }

  .basket {
    position: relative;
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 20px;

    border-radius: 24px;
    background-color: #343843;
    .nav {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding: 5px 10px 10px 10px;
      display: flex;
      gap: 10px;
      background-color: #1D1E25;
      border-radius: 12px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      .figure {
        display: none;
        position: absolute;
        top: calc(100% + 5px);
        height: 5px;
        width: 100%;
        background-color: #8146ff;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      .figure.show {
        display: block;
      }
      .nav-item {
        position: relative;
        cursor: pointer;
      }
      .label {
        padding: 0 4px 0 0;
        .step {
          width: fit-content;
          margin: auto;
          margin-bottom: 3px;
          line-height: 1;
          color: white;
        }
        .info {
          height: 22px;
          display: flex;
          align-items: center;
          gap: 5px;
        }
        & > svg {
          width: 22px;
          height: 22px;
        }
      }
    }
    & > .info {
      margin-top: 42px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;
      .top {
        display: flex;
        align-items: center;
        gap: 20px;
        text-wrap: nowrap;
        @media (max-width: 1000px) {
          & > div:first-child {
            display: none;
          }
        }
        .generate_course-input {
          width: 100%;
          max-width: 480px;
          padding: 7px 15px;

          position: relative;
          border-radius: 16px;
          font-size: 14px;
          border: 1px solid #474c5a;
          outline: none;
          transition: all 0.3s linear;
          color: white;
          background-color: #474c5a;
          z-index: 4;
        }
        .card {
          margin-left: auto;
          padding: 0 12px;
          height: 40px;
          display: flex;
          align-items: center;
          gap: 15px;
          border-radius: 16px;
          background-color: #475067;
          .button {
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .bottom_part {
            display: flex;
            gap: 15px;
            line-height: 1;
            & > div {
              //width: 50%;
            }
            .courses_count {
              display: flex;
              align-items: center;
              gap: 8px;
              @media (max-width: 820px) {
                display: none;
              }
            }
            .add {
              padding: 0 12px;
              height: 32px;
              display: flex;
              align-items: center;
              gap: 5px;
              border-radius: 12px;
              border: 1px solid #757A88;
              cursor: pointer;
            }
          }
        }
        .size_button {
          width: 40px;
          min-width: 40px;
          height: 40px;

          display: flex;
          align-items: center;
          justify-content: center;

          border-radius: 16px;
          background-color: #8146FF;
          cursor: pointer;
          svg {
            width: 60%;
            height: 60%;
          }
        }
      }
      .skill_list {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        max-height: 100px;
        overflow-y: auto;
        transition: all 0.3s linear;
        &.open {
          max-height: unset;
        }
        .skill {
          padding: 7px 35px 9px 10px;
          margin-right: 5px;
          margin-bottom: 2px;
          display: flex;
          width: fit-content;
          flex-wrap: wrap;
          align-items: center;
          position: relative;
          line-height: 17px;
          background: rgba(22, 21, 27, 0.65);
          border-radius: 29px;

          span {
            font-size: 14px;
            line-height: 16px;
            color: white;
          }

          img {
            position: absolute;
            top: 50%;
            right: 4px;
            transform: translateY(-50%);
            cursor: pointer;
          }

          &.without_img {
            padding: 6px 10px;
          }
        }
      }
      .skill_list_gen {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 24px;
        background: var(--dark);
        transition: all .3s linear;
        &.open {
          max-height: unset;
        }
        .top_settings {
          padding-bottom: 15px;
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid var(--background_lighten-dark);

          .label {
            font-size: 18px;
            font-weight: 500;
          }

          .size_btn {
            padding: 4px 15px 4px 10px;
            display: flex;
            height: fit-content;
            border-radius: 24px;
            background: #8146FF;
            cursor: pointer;

            span {
              margin-right: 10px;
            }
          }

          .settings {
            padding: 4px 15px 4px 12px;
            display: flex;
            align-items: center;
            border-radius: 24px;
            background: #1D1E25;

            span {
              margin: 0 15px 0 10px;
              line-height: normal;
            }

            img:last-child {
              margin-top: 2px;
            }
          }
        }

        .info {
          /*margin-bottom: 12px;*/
          display: flex;
          align-items: center;

          .figure {
            margin-right: 10px;
            width: 10px;
            height: 10px;
            min-width: 10px;
            min-height: 10px;
            background: white;
            border-radius: 50%;

            &:nth-child(3) {
              margin: 0 10px;
              background: var(--text_color_dark);
            }
          }
        }

        .list_tags {
          padding-top: 15px;
          display: flex;
          flex-wrap: wrap;
          max-height: 100px;
          overflow-y: auto;
          transition: all .3s linear;
        }

        .btn_generate {
          width: 100%
        }

        &.open {
          .list_tags {
            max-height: 400px;
          }
        }
      }
    }
  }
  .container {
    padding: 20px;
    //padding-top: 0;

    border-radius: 24px;
    background-color: #1D1E25;
    .navigation {
      .label {
        padding: 0 4px 0 0;
        .step {
          width: fit-content;
          margin: auto;
          margin-bottom: 3px;
          line-height: 1;
          color: white;
        }
        .info {
          height: 22px;
          display: flex;
          align-items: center;
          gap: 5px;
        }
        & > svg {
          width: 22px;
          height: 22px;
        }
      }

    }
  }

  //.ant-progress-text {
  //  color: #fff !important;
  //}
  //.ant-tabs {
  //  color: #757a88;
  //}
  //.ant-tabs-tab:hover {
  //  color: #8146ff;
  //}
  //.ant-tabs-top > .ant-tabs-nav::before {
  //  border-bottom: 2px solid #343843;
  //}
  //.ant-tabs-top > .ant-tabs-nav {
  //  display: none;
  //}
  //.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  //  color: #8146ff;
  //}
  //.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  //  background: #8146ff;
  //  height: 6px;
  //  border-top-left-radius: 8px;
  //  border-top-right-radius: 8px;
  //}
  //.ant-breadcrumb {
  //  li {
  //    display: flex;
  //    align-items: center;
  //
  //    .ant-breadcrumb-link > * {
  //      color: #757a88 !important;
  //
  //      &:hover {
  //        color: #fff !important;
  //      }
  //    }
  //  }
  //}

  .fs14 {
    font-size: 14px;
  }
  .fs16 {
    font-size: 16px;
  }
  .fs20 {
    font-size: 20px;
  }
  .fs32 {
    font-size: 32px;
  }
}