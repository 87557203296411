@media (max-width: 819px) {
  .entered_code {
    .store_icons {
      margin: 0 auto !important;
      display: flex !important;
      flex-direction: column !important;
      width: fit-content !important;
    }
  }

  .step_1 {
    .store_icons {
      margin: 0 auto !important;
      display: flex !important;
      flex-direction: column !important;
      width: fit-content !important;
    }
  }
}

@media (max-width: 720px) {
  .entered_code {
    .btn_next {
      width: 100% !important;
      transition: none !important;
    }
  }
}

.step_1 {
  display: flex;
  flex-direction: column;
  align-items: center;

  .block_phone {
    position: relative;
    margin-top: 20px;
    max-width: 265px;
    width: 100%;
    z-index: 100;

    .phoneNumber {
      padding: 9px 15px 9px 40px;
      width: 100%;
      font-size: 14px;
      line-height: 17px;
      outline: none;
      background: #343843;
      border: 1px solid transparent;
      border-radius: 16px;
      color: white;
      transition: all .2s ease;
      z-index: 100;

      &.active {
        border-color: var(--text_color_dark);
        border-radius: 16px 16px 0 0;
      }
    }

    .drop_down-phone {
      max-height: 0;
      position: absolute;
      opacity: 0;
      transform: scale(0);
      width: 100%;
      overflow: auto;
      background-color: darken(#474c5a, 10%);
      border-radius: 0 0 17px 17px;
      border: 1px solid transparent;
      transition: all .2s ease;
      z-index: 4;

      ul {
        padding: 0;
        margin: 0 auto;
      }

      li {
        padding: 5px 15px 5px 14px;
        margin: 0;
        font-size: 14px;
        color: white;
        cursor: pointer;
        white-space: nowrap;

        span {
          white-space: nowrap;
        }

        &:hover {
          background-color: darken(#474c5a, 5%);
        }

        &:last-child {
          padding-top: 0;
          padding-bottom: 5px;
          border-radius: 0 0 17px 17px;
        }
      }

      &.active {
        border-color: var(--text_color_dark);
        border-top-color: transparent;
        margin-top: -3px;
        min-width: 100px;
        max-height: 140px;
        transform: scale(1);
        opacity: 1;
      }
    }

    .icon_country {
      position: absolute;
      top: 7px;
      left: 15px;

      img {
        min-width: 21px;
        min-height: 15px;
        max-width: 21px;
        max-height: 15px;
      }
    }

    .arrow {
      position: absolute;

      &_phone {
        position: absolute;
        top: 1px;
        right: 3px;
        width: 50px;
        height: 40px;
        cursor: pointer;
        border: none;

        img {
          position: absolute;
          top: 14px;
          right: 20px;
        }
      }
    }

    .smsForm {
      max-width: 240px;
      width: 100%;

      .ant-row.ant-form-item {
        margin-bottom: -2px;
      }
    }

    .store_icons {
      display: none;
    }

    .captcha {
      margin-top: 20px;
      transform: scale(0.87);
      transform-origin: 0 0;
    }
  }
}

.entered_code {
  p {
    margin: 20px 0 5px 15px;
    color: var(--text_color_dark);
  }

  .send_code-again {
    margin-left: 20px;

    span {
      color: var(--text_color_dark);
    }
  }

  input {
    width: 100%;
    padding-left: 15px;
    background-color: #343843;
    border: 1px solid #474C5A;
    border-radius: 16px;
    font-size: 16px;
    color: white;
    z-index: 3;
    outline: none;

    &:hover {
      border-color: #474C5A !important;
    }

    &:active {
      border-color: #474C5A !important;
      outline: none !important;
    }

    &:focus {
      border-color: #474C5A !important;
      outline: none !important;
      box-shadow: none;
    }

    &::placeholder {
      color: #757A88;
      font-size: 16px;
    }
  }

  .warn {
    color: red;
  }

  .store_icons {
    display: none;
  }
}

