@use "./src/styles/Variables";

@media (max-width: 1410px) {
  .search_talants {
    margin: 0 15px !important;
  }
}

@media (max-width: 1200px) {
  .search_talants {
    .preview_title {
      min-height: 750px !important;
      padding-right: 38px !important;

      .page_info {
        margin: 0 auto !important;
      }

      .page_image {
        top: 400px !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
      }
    }
  }
}

@media (max-width: 820px) {
  .search_talants {
    padding: 0 !important;

    .main {
      margin-left: 0 !important;

      .search_category {
        .h2 {
          display: none !important;
        }
      }

      .asked_questions {
        .question_drop {
          padding-bottom: 60px !important;
        }
      }
    }

    .preview_title {
      display: none !important;
    }

    .preview_title-mobile {
      display: block !important;
    }
    .asked_questions {
      padding-bottom: 100px;
    }
  }
}

@media (max-width: 665px) {
  .search_talants {
    .header {
      padding-bottom: 10px !important;

      span {
        font-size: 22px !important;
        line-height: 28px !important;
      }

      .btn {
        margin-top: 0 !important;
        padding: 3px 15px 4px 15px !important;

        span {
          font-size: 14px !important;
          line-height: 17px !important;
        }
      }
    }

    .list_category {
      flex-direction: column;

      .category {
        margin-right: 0 !important;
        padding: 15px !important;
        max-width: 100% !important;
        height: initial !important;
        justify-content: flex-start !important;
        border-radius: 24px !important;
      }
    }
  }
}

@media (max-width: 360px) {
  .search_talants {
    .header {
      .btn {
        max-width: 140px !important;
      }
    }

    .list_category {
      flex-direction: column;

      .category {
        margin-right: 0 !important;
        padding: 15px !important;
        max-width: 100% !important;
        height: initial !important;
        justify-content: flex-start !important;
        border-radius: 24px !important;
      }
    }
  }
}

.search_talants {
  padding-bottom: 20px;
  width: 100%;
  color: white;

  &.not_auth {
    padding-top: 10px;
    margin: 0 auto;
    max-width: 1310px;
    width: 100%;

    .header_not-auth {
      margin-bottom: 45px;
      display: flex;
      width: 100%;

      img {
        margin-right: auto;
      }
    }
  }

  .header {
    margin: 10px 0 25px 0;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid var(--dark);

    span {
      font-weight: 400;
      font-size: 32px;
      line-height: 38px;
    }

    .btn {
      margin-top: 5px;
      padding: 3px 6px 5px 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 200px;
      height: 35px;
      width: 100%;
      font-size: 14px;
      background: #3077FF;
      box-shadow: 0 9px 20px rgb(48 119 255 / 45%);
      border-radius: 15px;
      cursor: pointer;

      span {
        font-size: 16px;
        line-height: 19px;
      }

      &.sign_in {
        max-width: 150px;
      }
    }
  }

  .preview_title {
    padding: 38px 560px 38px 38px;
    margin-bottom: 50px;
    position: relative;
    display: flex;
    width: 100%;
    min-height: 380px;
    border-radius: 30px;
    background: #1D1E25;
    overflow: hidden;

    .page_info {
      max-width: 700px;
      width: 100%;

      .title {
        margin-bottom: 20px;
        max-width: 700px;
        width: 100%;
        font-weight: 500;
        font-size: 36px;
        line-height: 43px;
      }

      .text {
        margin-bottom: 20px;
        width: 100%;
        font-size: 16px;
        line-height: 19px;
        color: #9197A8;
      }

      .btn_find {
        padding: 0 20px;

        width: fit-content;
        height: 34px;
        display: flex;
        align-items: center;
        background: #3077FF;
        box-shadow: 0px 9px 20px rgba(48, 119, 255, 0.2);
        border-radius: 20px;
        cursor: pointer;

        span {
          margin-bottom: 2px;
          font-size: 13px;
          line-height: 16px;
        }
      }
    }

    .page_image {
      position: absolute;
      top: -110px;
      right: -80px;
      width: 638px;
      height: 638px;

      .image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: #757A88;
        border-radius: 50%;

        svg {
          width: 256px;
          height: 331px;
        }
      }
    }
  }

  .preview_title-mobile {
    margin-bottom: 20px;
    max-width: calc(100% + 30px);
    width: 100%;
    position: relative;
    display: none;

    .title {
      padding: 0 4px 4px 4px;
      margin-bottom: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 64px;
      background: #1D1E25;
      border-radius: 25px;

      span {
        font-size: 22px;
        line-height: 26px;
      }
    }

    .page_info {
      padding: 15px;
      background: #1D1E25;
      border-radius: 24px;
      /*height: 470px;*/

      .info {
        margin-bottom: 20px;
        max-width: 275px;
        width: 100%;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
      }

      .text {
        max-width: 310px;
        width: 100%;
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 17px;
        color: #9197A8;
      }

      .btn_find {
        padding: 4px 4px 4px 4px;
        max-width: 160px;
        text-align: center;
        background: #3077FF;
        box-shadow: 0 9px 20px rgba(48, 119, 255, 0.2);
        border-radius: 20px;
      }

      .page_image {
        position: absolute;
        bottom: 10px;
      }
    }
  }

  .search_category {
    margin: 0 auto 50px auto;
    max-width: 1240px;
    width: 100%;

    .h2 {
      margin-bottom: 20px;
      font-size: 24px;
      line-height: 29px;
    }

    .list_category {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      cursor: pointer;

      .category {
        padding: 20px;
        margin: 0 0 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 287px;
        width: calc(25% - 30px);
        height: 135px;
        border: 1px solid transparent;
        background: var(--dark);
        border-radius: 24px;
        transition: all .3s linear;

        .label {
          /*padding-bottom: 20px;*/
          /* margin-bottom: 20px;*/
          display: flex;
          align-items: center;
          /*  border-bottom: 1px solid var(--background_lighten-dark);*/

          .img {
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 35px;
            min-width: 35px;
            min-height: 35px;
            background: #8146FF;
            border-radius: 50%;
          }

          span {
            max-width: 225px;
            width: 100%;
            font-size: 18px;
            line-height: 21px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        .info {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .rating {
            margin-right: 10px;
            display: flex;
            align-items: center;

            img {
              margin-right: 10px;
              width: 16px;
              min-width: 16px;
              height: 16px;
              min-height: 16px;
            }

            span {
              font-size: 16px;
              line-height: 19px;
              color: #9197A8;
            }
          }

          .count_skills {
            font-size: 16px;
            line-height: 19px;
            color: #9197A8;
          }
        }

        &:hover {
          border-color: var(--text_color_dark);
        }
      }
    }
  }

  .asked_questions {
    margin: 0 auto;
    max-width: 1240px;
    width: 100%;

    .h2 {
      margin-bottom: 25px;
      font-size: 24px;
      line-height: 29px;
    }

    .question {
      padding: 9px 15px;
      position: relative;
      display: flex;
      height: 40px;
      max-height: 44px;
      justify-content: space-between;
      background: var(--dark);
      border: 1px solid var(--background_lighten-dark);
      border-radius: 16px;
      transition: all .3s linear;
      cursor: pointer;

      span {
        font-size: 18px;
        line-height: 21px;
      }

      .arrow_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        min-width: 22px;
        min-height: 22px;
        background: #1D1E25;
        box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        transition: all .3s linear;

        img {
          margin-top: 1px;
          transition: all .3s linear;
        }
      }

      .active {
        .arrow_icon {
          background: var(--dark);

          img {
            transform: rotate(180deg);
          }
        }

        border-color: var(--dark);
        background: #1D1E25;
      }

      .question_drop {
        padding-top: 15px;
        position: absolute;
        width: calc(100% - 35px);
        top: 36px;
        left: 15px;
        opacity: 0;
        border-top: 1px solid #757A88;
      }

      &.open {
        max-height: 130px;
        height: 150px;
        background: #1D1E25;
        border: 1px solid var(--dark);
        border-radius: 16px;

        .question_drop {
          opacity: 1;
          font-size: 16px;
          line-height: 19px;
          color: var(--text_color_dark);
        }
      }
    }
  }
}