@use '../../../styles/Variables';

@media (max-width: 1375px) {
  .parent_lesson {
    .progress_bar {
      margin-left: -15px !important;
      padding-left: 35px !important;
      border-radius: 0 20px 20px 0 !important;
    }
  }
}

@media (max-width: 820px) {
  .parent_lesson {
    margin: 0 auto !important;
    padding-left: 0 !important;
    flex-direction: column;

    .progress_bar {
      display: none !important;
    }

    .right_content {
      display: none !important;
    }

    .content_mobile {
      display: block !important;
    }
  }
}

@media (max-width: 380px) {
  .modal_stage-mobile {
    .ant-modal-body {
      padding: 15px !important;
    }

    .ant-modal-content {
      width: auto !important;

      .ant-collapse-item {
        min-width: inherit !important;
      }
    }
  }
}

.parent_lesson {
  display: flex;
  padding: 0;
  width: 100%;
  font-size: 16px;
  line-height: 19px;
  color: white;
  .content_mobile iframe {
    border-radius: 20px;
  }
  .stage_item {
    display: flex;
    align-items: center;
    transition: all .4s;
    cursor: pointer;

    svg {
      border: 1px solid transparent;
    }

    .icon {
      margin-right: 10px;
      display: flex;
      align-items: center;

      &.green {
        svg {
          circle {
            fill: #30E0A1;
          }
        }
      }

      &.red {
        svg {
          circle {
            fill: #eb2b2d;
          }
        }
      }
    }

    .text {
      transition: all .4s;
      color: white;
      line-height: 17px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:hover,
    &.active {
      .text {
        transition: all .4s;
        color: var(--text_color_dark);
      }
    }

    &.active {
      .text {
        font-weight: 700;
      }
    }
  }

  .progress_bar {
    margin: 0 30px 0 0;
    padding: 20px;
    max-height: calc(100vh - 20px) !important;
    min-height: calc(100vh - 20px) !important;
    min-width: 400px;
    max-width: 404px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: #201E26;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    .quest_name {
      text-align: center;

      span {
        color: var(--text_color_dark);
        font-size: 18px;
      }
    }

    .progress_header {
      margin-top: 20px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 13px;
        padding: 10px 15px;
        background: #FF3434;
        border: 1px solid #474C5A;
        border-radius: 22px;

        span {
          margin-top: -2px;
          color: white;
        }

        &.red {
          background: #FF3434;
        }

        &.yellow {
          background: #FFD23B;

        }

        &.fiolet {
          background: #8146FF;
        }
      }

      .group {
        position: relative;
        display: flex;

        .procentague {
          position: absolute;
          top: -5px;
          right: 0;
        }

        svg {
          margin-right: 10px;

          &.red {

            path:nth-child(4),
            path:nth-child(6) {
              fill: #FF3434;
            }
          }

          &.yellow {

            path:nth-child(4),
            path:nth-child(6) {
              fill: #FFD23B;
            }
          }

          &.fiolet {

            path:nth-child(4),
            path:nth-child(6) {
              fill: #8146FF;
            }
          }
        }

        .progress_antd {
          margin-top: 7px;
          align-self: self-end;
        }
      }

    }

    .collapse_antd {
      width: 100%;
      color: white;

      .ant-collapse-header {
        padding-right: 0;
        padding-left: 0;
        font-size: 16px;
        line-height: 19px;
        color: white;

        svg {
          position: absolute;
          top: 23px;
          right: 11px;
          z-index: 1;

          path {
            stroke: #343843;
          }

          &.active {
            path {
              stroke: white;
            }
          }

          &.open {
            transform: rotate(180deg);
            top: 19px;
          }

          &.marginR {
            right: 30px;
          }
        }
      }

      .stage_span {
        padding: 2px 30px 2px 15px;
        position: relative;
        font-size: 14px;
        line-height: 17px;
        background: #30E0A1;
        border-radius: 20px;
        color: #27292F;
        white-space: nowrap;
        user-select: none;

        .countDenyHomework {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          top: -10px;
          right: -2px;
          width: 14px;
          height: 14px;
          font-size: 12px;
          background-color: #eb2b2d;
          color: white;
          border-radius: 50%;
        }

        .spin {
          position: absolute;
          right: -30px;
          top: 0;
        }

        &.disabled {
          background: #16151B;
          color: var(--dark)
        }

        &.active {
          background: #3077FF;

          span:first-child {
            color: white;
          }
        }

        &.activeLoader {
          margin-right: 20px;
        }
      }

      .ant-collapse-content-box {
        background: #343843;
        border: 1px solid #474C5A;
        box-shadow: 0 15px 20px rgba(0, 0, 0, 0.11);
        border-radius: 22px;
      }

      .line {
        margin: 10px 0 10px 17px;
        height: 25px;
        width: 1px;
        background-color: var(--text_color_dark);

        &.hide {
          display: none;
        }
      }

      .loading_matherial {
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--text_color_dark);
        background: rgba(32, 30, 38, 0);
        border-radius: 8px;
        padding: 3px 8px;

        .ant-spin {
          margin-top: 8px;
        }
      }

      .certificate {
        padding: 5px 15px;
        text-align: center;
        border-radius: 16px;
        background-color: var(--blue);
        width: 100%;
        opacity: 0.7;
        cursor: pointer;
        transition: all .3s linear;

        &:hover {
          opacity: 1;
        }
      }
    }

    .steps_antd {
      .ant-steps-item-content {
        display: flex;
        min-height: 34px;

        .ant-steps-item-title {
          align-self: center;
          font-size: 14px;
          line-height: 17px;
          color: white;
        }

      }

      .ant-steps-item-container {
        display: flex;
        align-items: center;

        .ant-steps-item-tail {
          display: none !important;
        }

        .ant-steps-item-icon {
          position: relative;
          width: 35px;
          margin-right: 10px;
        }

        &:hover {
          .ant-steps-item-title {
            color: white !important;
          }
        }
      }

      .ant-steps-icon {
        width: 35px;
        height: 35px;

        svg {
          &.end {
            circle {
              fill: #30E0A1;
            }
          }
        }
      }

      .ant-steps-item.ant-steps-item-wait.ant-steps-item-custom {
        margin-bottom: 15px;
      }

      .ant-steps-item.ant-steps-item-process.ant-steps-item-custom {
        min-height: 37px;
        position: relative;

        &:not(:last-child) {
          margin-bottom: 25px;

          &:not(:last-child)::after {
            content: '';
            position: absolute;
            left: 18px;
            width: 1px;
            height: calc(100% - 30px);
            background-color: white;
          }
        }

        .ant-steps-item-container {
          .ant-steps-item-icon {
          }
        }
      }

      .ant-steps-item-tail {
        left: 17px;
      }

      .ant-steps-item-icon {
        display: flex;
      }

      .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
        min-height: 32px;
      }

      .ant-steps-item-custom {
        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    }

    &.hide {
      position: absolute;
      left: -40px;
      width: 100%;
      margin-right: 0;
      transition: max-width .4s;
      max-width: 100px;
      min-width: inherit;

      .progress_header,
      .ant-collapse {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  .right_content {
    display: flex;
    flex-direction: column;
    //max-height: calc(100vh - 30px) !important;
    min-height: calc(100vh - 30px) !important;
    max-width: 1060px;
    width: 100%;
    color: white;
    overflow-y: auto;
    overflow-x: hidden;

    .group_buttons {
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      .button {
        padding: 4px 30px 5px 15px;
        position: relative;
        max-width: 92px;
        width: 100%;
        background: var(--dark);
        border-radius: 20px;
        font-size: 14px;
        cursor: pointer;
        transition: border-radius .3s;
        box-shadow: none;

        span {
          user-select: none;
        }

        svg {
          position: absolute;
          top: 11px;
          right: 10px;
          transition: transform .3s ease;
        }

        .drop_down {
          position: absolute;
          top: 28px;
          left: 0;
          width: 100%;
          max-height: 1px;
          overflow: auto;
          opacity: 0;
          transform: scale(0);
          color: #757A88;
          background-color: var(--dark);
          border-radius: 0 0 15px 15px;
          transition: opacity .3s, transform .3s, max-height .3s, z-index .3s;
          z-index: -2;

          .item {
            padding: 4px 0 4px 15px;

            &:hover {
              background-color: lighten(#343843, 5%);
            }
          }

          &.open {
            transition: opacity .3s, transform .3s, max-height .3s, z-index .3s;
            max-height: 130px;
            opacity: 1;
            transform: scale(1);
            z-index: 6;
          }
        }

        &.open {
          border-radius: 20px 20px 0 0;

          svg {
            transition: transform .3s ease;
            transform: rotate(180deg);
          }
        }

        &.prev {
          padding: 4px 15px 5px 30px;
          width: fit-content;
          box-shadow: none;

          svg {
            position: absolute;
            top: 11px;
            left: 10px;
            transform: rotate(90deg);
          }
        }

        &:not(:last-child) {
          margin-right: 25px;
        }
      }
    }

    .no_data {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 20px;
        color: var(--text_color_dark)
      }
    }

    .error_fetch {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      img {
        margin-bottom: 20px;
      }

      span {
        font-size: 20px;
        color: var(--text_color_dark);
      }
    }

    &.full_screen {
      max-width: 1395px;
      margin-left: 20px;
    }
  }

  .archive_row {
    margin: auto;
    max-width: fit-content;
    color: var(--text_color_dark);
    font-size: 20px;

    img {
      margin-bottom: 5px;
      margin-left: 15px;
      width: 18px;
      height: 19px;
      cursor: pointer;
    }
  }

  pre {
    padding: 1px;
    min-height: 30px;
    max-height: 100%;
    overflow-x: hidden;
  }
}

.modal_stage-mobile {
  margin-top: -80px;

  .ant-steps-item.ant-steps-item-wait.ant-steps-item-custom {
    &:not(:last-child) {
      margin-bottom: 15px !important;
    }
  }

  .stage_item {
    display: flex;
    align-items: center;
    transition: all .4s;
    cursor: pointer;

    .icon {
      margin-right: 10px;
      display: flex;
      align-items: center;

      &.green {
        svg {
          circle {
            fill: #30E0A1;
          }
        }
      }

      &.red {
        svg {
          circle {
            fill: #eb2b2d;
          }
        }
      }
    }

    .text {
      color: white;
      line-height: 17px;
    }

    &.active {
      .text {
        transition: all .4s;
        font-weight: 700;
        color: var(--text_color_dark);
      }
    }
  }

  .line {
    margin: 10px 0 10px 17px;
    height: 25px;
    width: 1px;
    background-color: var(--text_color_dark);

    &.hide {
      display: none;
    }
  }

  .ant-modal-content {
    margin: 0 auto;
    width: fit-content;
  }

  .collapse_antd-mobile {
    color: white;

    .ant-collapse-header {
      padding-right: 0px;
      padding-left: 0px;
      font-size: 16px;
      line-height: 19px;
      color: white;

      svg {
        position: absolute;
        top: 23px;
        right: 11px;

        path {
          stroke: #343843;
        }

        &.active {
          path {
            stroke: white;
          }
        }

        &.open {
          transform: rotate(180deg);
          top: 19px !important;
        }
      }
    }

    .ant-collapse-item {
      padding: 0px 10px 0px 10px;
      margin-bottom: 15px;
      min-width: 315px;
      background-color: #27292F;
      border-radius: 17px !important;

      span {
        font-size: 14px;
        line-height: 17px;
      }

      svg.ant-collapse-arrow {
        top: 23px;
        right: 12px;
        z-index: 1;
      }
    }

    .stage_span {
      padding: 2px 30px 2px 15px;
      position: relative;
      font-size: 14px;
      line-height: 17px;
      background: #30E0A1;
      border-radius: 20px;
      color: #27292F;
      white-space: nowrap;

      .countDenyHomework {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -10px;
        right: -2px;
        width: 14px;
        height: 14px;
        font-size: 12px;
        background-color: #eb2b2d;
        color: white;
        border-radius: 50%;
      }

      &.disabled {
        background: #16151B;
        color: var(--dark)
      }

      &.active {
        background: #3077FF;

        span:first-child {
          color: white;
        }
      }
    }

    .ant-collapse-content-box {
      padding: 0 !important;
      background: transparent;
    }
  }

  .steps_antd-mobile {
    .ant-steps-item-content {
      display: flex;
      min-height: 34px;

      .ant-steps-item-title {
        align-self: center;
        font-size: 14px;
        line-height: 17px;
        color: white;
      }

    }

    .ant-steps-item-container {
      &:hover {
        .ant-steps-item-title {
          color: white !important;
        }
      }
    }

    .ant-steps-icon {
      svg {
        &.end {
          circle {
            fill: #30E0A1;
          }
        }
      }
    }

    .ant-steps-item.ant-steps-item-process.ant-steps-item-custom {
      min-height: 37px;

      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }

    .ant-steps-item-tail {
      left: 17px;
    }

    .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
      min-height: 32px;
    }
  }

  .btn_close {
    margin: 0 auto;
    margin-top: 30px;
    width: fit-content;
    font-size: 14px;
    line-height: 17px;
    color: white;
    cursor: pointer;
  }

}

.ant-image-mask {
  display: none;
}