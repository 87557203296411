.navigation_header {
  margin-bottom: 20px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--background_lighten-dark);

  .h1 {
    display: flex;
    align-items: center;
    font-size: 32px;
    line-height: 38px;

    div:last-child {
      max-width: 550px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    div:last-child {
      color: var(--background_lighten-dark);
    }
  }

  .buttons_navigate {
    margin-top: 5px;
    display: flex;
    max-width: 365px;
    width: 100%;
    height: fit-content;
    background: rgba(162, 170, 202, 0.2);
    border: 1px solid var(--text_color_dark);
    border-radius: 15px;
    user-select: none;

    div {
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: fit-content;
      text-align: center;
      color: #757A88;
      transition: background .3s ease;
      white-space: nowrap;
      cursor: pointer;
      border-radius: 26px;

      span {
        font-size: 13px;
      }

      &.active {
        background: #3077FF;
        color: white;
      }
    }
  }

  .block {
    display: flex;
    flex-direction: column;

    .btn_prev {
      padding: 1px 15px 2px 30px;
      margin-top: 17px;
      margin-bottom: 10px;
      height: 25px;
      width: fit-content;
      position: relative;
      background: var(--dark);
      border-radius: 20px;

      svg {
        position: absolute;
        top: 9px;
        left: 12px;
        transform: rotate(90deg);
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 500px) {
  .navigation_header {
    flex-direction: column;
  }
}