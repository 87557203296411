@media (max-width: 1318px) {
  .modal_welcome {
    width: 60% !important;
  }
}

@media (max-width: 819px) {
  .modal_welcome {
    top: 15px !important;
    margin-top: 0 !important;
    border-radius: 24px !important;

    .ant-modal-content {
      padding-top: 15px !important;
      border-radius: 24px !important;

      p {
        font-size: 24px !important;
        line-height: 29px !important;
      }

      button {
        width: auto !important;
        align-self: auto !important;
      }
    }
  }
  .image_modal {
    display: none !important;
  }
  .image_modal-mobile {
    display: block !important;
  }

}

@media (max-width: 720px) {
  .modal_welcome {
    width: 100% !important;

    img {
      width: 100% !important;
      margin-left: auto !important;
      margin-right: auto !important;
      align-self: auto !important;
      max-width: none !important;
      max-height: none !important;
    }
  }
}

@media (max-width: 280px) {
  .image_modal-mobile {
    .right_bottom {
      top: 43% !important;
    }

    .left_middle {
      top: 41% !important;
    }

    .left_top {
      top: 15px !important;
    }
  }
}

.modal_welcome {
  margin-top: 80px;
  padding-bottom: 0 !important;
  background: #201E26;
  border: 1px solid #343843;
  box-shadow: 0 20px 160px rgba(0, 0, 0, 0.3);
  border-radius: 40px;

  .ant-modal-content {
    padding-top: 40px;
    padding-bottom: 40px;
    background: #201E26;
    box-shadow: none !important;
    border-radius: 40px;
  }
}

.content_modal {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  text-align: center;

  img {
    margin-bottom: 25px;
    width: 40vw;
    max-width: 350px;
    max-height: 350px;
    align-self: center;
  }

  p {
    margin-bottom: 25px;
    color: white;
    font-size: 40px;
    line-height: 48px;
  }

  button {
    width: 320px;
    padding: 8px 15px;
    align-self: center;
    z-index: 1;

    &:focus {
      outline: none;
    }
  }
}

.image_modal {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}