@use 'Variables';

@media (max-width: 820px) {
  .btn_scroll-up {
    display: block !important;
  }
  .block_404 {
    .img {
      span {
        font-size: 20px !important;
        line-height: 20px !important;
      }
    }
  }
}

/* CustomCheckbox */
.form-group {
  display: block;
  margin-bottom: 15px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group label:before {
  content: '';
  -webkit-appearance: none;
  background: rgba(129, 70, 255, 0.2);
  border: 1px solid #8146FF;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 5px;
}

.form-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3.5px;
  left: 8px;
  width: 6px;
  height: 12px;
  border: solid #8146FF;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* CustomCheckboxToggle */
.checkbox {
  margin-bottom: 15px;

  .slider:before {
    position: absolute;
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 2px;
    content: "";
    background-color: var(--dark);
    border: 1px solid #FFFFFF;
    box-shadow: 0px 7px 9px rgba(0, 0, 0, 0.43);
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #27292F;
  }

  &.theme {
    .switch input:checked + .slider:before {
      transform: translateX(26px);
      background-color: lighten(#343843, 10%);
    }

    input:checked + .slider {
      background-color: #54545b;
    }
  }
}

.switch {
  display: inline-block;
  position: relative;
  margin-top: 2px;
  height: 20px;
  width: 48px;

  input {
    display: none;
  }

  .slider {
    background-color: darken(#343843, 5%);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    transition: .4s;
    cursor: pointer;
  }

  .slider:before {
    position: absolute;
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 2px;
    content: "";
    background-color: #3077FF;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 7px 9px rgba(0, 0, 0, 0.43);
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #343843;
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

/* DangerSkill */

.danger_skill {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  background-color: transparent;

  .skill {
    padding: 3px 5px 4px 12px;
    margin: 0 5px 5px 0;
    position: relative;
    display: flex;
    align-items: center;
    height: 32px;
    width: fit-content;
    text-align: center;
    background: rgba(22, 21, 27, 0.65);
    border: 1px solid rgba(207, 26, 26, 0.6);
    border-radius: 34px;

    img {
      margin: 1px 0 0 5px;
    }
  }
}

/* ParentBlockProgress */
.analytics_block-gorizontal {
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-bottom: 35px;
  margin-left: 20px;

  .diapazon {
    position: absolute;
    bottom: -10px;
    font-size: 12px;
    line-height: 10px;
    color: #474C5A;
    white-space: nowrap;
  }

  .vertical_line {
    margin-right: 125px;
  }
}

.analytics_block-vertical {
  /*height: 380px;*/
  height: 240px;

  .block_diapazon {
    position: absolute;
    top: 0;
    left: 0;
    /*height: 380px;*/
    height: 240px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .line_row {
    display: flex;
    position: relative;
    max-width: 495px;
    width: 100%;

    .company {
      min-width: 45px;
      margin-right: 10px;
      font-size: 10px;
      line-height: 12px;
      color: white;
      white-space: nowrap;
    }

    img {
      position: absolute;
      top: 7px;
      right: 0;
      width: calc(100% - 45px);
      height: 2px;
    }
  }

  .vertical_progress {
    padding-left: 50px;
    display: flex;
    justify-content: space-around;
    height: 240px;

    .progress {
      background-color: #f5f5f5;
      border-radius: 3px;
      box-shadow: none;
    }

    .progress.vertical {
      position: relative;
      width: 20px;
      /*height: 380px;*/
      height: 240px;
      overflow: inherit;
      display: inline-block;
      margin-right: 10px;

      &.blue {
        .progress-bar {
          background-color: #2661D3;

          &::after {
            position: absolute;
            content: '';
            // content: url("../images/icons/middle_green-vertical.svg");
            background-image: url("../images/icons/middle_blue-vertical.svg");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            width: 21px;
            height: 16px;
            top: -3px;
            right: -1px;
            z-index: 3;
          }

          &.hidden {
            &::before {
              position: absolute;
              content: '';
              // content: url("../images/icons/middle_green-vertical.svg");
              background-image: url("../images/icons/middle_blue-vertical.svg");
              background-size: 100% 100%;
              background-repeat: no-repeat;
              width: 21px;
              height: 16px;
              top: -16px;
              right: 0px;
              z-index: 3;
            }

            &::after {
              content: none;
            }
          }
        }
      }
    }

    .progress {
      height: 20px;
      margin-bottom: 20px;
      overflow: hidden;
      background-color: #565F71;
      -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      z-index: 0;

      &::after {
        position: absolute;
        content: "";
        background-image: url("../images/icons/top_grey-vertical.svg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        top: -2px;
        right: 0;
        width: 21px;
        height: 16px;
        z-index: -1;
      }

      &.blue {
        .progress-bar {
          &::before {
            position: absolute;
            content: '';
            // content: url("../images/icons/middle_green-vertical.svg");
            background-image: url("../images/icons/top_blue-vertical.svg");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            width: 20px;
            height: 17px;
            bottom: -2px;
            right: 0px;
            z-index: -1;
          }
        }
      }
    }

    .progress.vertical > .progress-bar {
      width: 100% !important;
      position: absolute;
      bottom: 0px;
    }

    .progress-bar {
      float: left;
      width: 0;
      height: 100%;
      font-size: 12px;
      line-height: 20px;
      color: #fff;
      text-align: center;
      background-color: #28C98F;
      border-radius: 10px;
      -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
      box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
      -webkit-transition: width 0.6s ease;
      -o-transition: width 0.6s ease;
      transition: width 0.6s ease;

      &::after {
        position: absolute;
        content: '';
        // content: url("../images/icons/middle_green-vertical.svg");
        background-image: url("../images/icons/middle_green-vertical.svg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 20px;
        height: 16px;
        top: -3px;
        right: 0px;
        z-index: 3;
      }

      &::before {
        position: absolute;
        content: '';
        // content: url("../images/icons/middle_green-vertical.svg");
        background-image: url("../images/icons/middle_green-vertical.svg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 20px;
        height: 16px;
        bottom: -2px;
        right: 0px;
        z-index: -1;
      }

      &.hidden {
        background-color: transparent;

        &::after {
          content: none;
        }

        &::before {
          position: absolute;
          content: '';
          // content: url("../images/icons/middle_green-vertical.svg");
          background-image: url("../images/icons/middle_green-vertical.svg");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 20px;
          height: 16px;
          top: -14px;
          right: 0px;
          z-index: 3;
        }
      }
    }

    .name_employer {
      max-width: 35px;
      bottom: -15px;
      left: -3px;
      /* left:50%;
       transform:translate(-50%, 0);*/
      overflow: hidden;
      /* Обрезаем все, что не помещается в область */
      text-overflow: ellipsis;
      font-size: 10px;
      color: #A2AACA;
      white-space: nowrap;
    }


  }

  .ant-progress-inner {
    width: inherit;

    &::after {
      content: url("../images/icons/top_grey-vertical.svg");
      position: absolute;
      transform: rotate(-90deg);
      top: -1px;
      right: -3px;
      z-index: 0;
    }
  }

  .ant-progress-bg {
    z-index: 1;

    &::after {
      content: url("../images/icons/middle_green-vertical.svg");
      position: absolute;
      transform: rotate(-90deg);
      top: -1px;
      right: -5px;
      z-index: 1;
    }
  }

}

.chart {
  // position: relative;
  //  width: 7%;
  color: white;
  font-size: 10px;
  line-height: 12px;

  .progress_parent {
    position: absolute;
    top: 17px;
    left: 5px;
    width: 100%;
  }

  .gorizontal_progress {
    position: relative;
    margin-bottom: 40px;
    width: 100%;

    .index {
      font-size: 12px;
      line-height: 10px;
    }

  }

  .ant-progress-outer {
    padding-right: 0;
  }
}

/* SkillAnimation */
@keyframes backInUp {
  0% {
    /* transform: translateY(0px);*/
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  70% {
    opacity: 0.6;
  }

  80% {
    opacity: 0.6;
  }

  100% {
    opacity: 0;
    top: 120px;
    left: 58%;
    /*transform: translateY(-335px);*/
  }
}

.my_animation {
  // min-width: 100px;
  // min-height: 25px;
  text-align: center;
  animation-name: backInUp;
  animation-duration: .8s;
  z-index: 10;

  &-hidden {
    /* display: none !important;*/
  }
}

/* SkillBlockProfession */
.tags_block {
  padding: 20px 20px 10px 20px;
  background: var(--dark);
  border-radius: 20px;
  border: 1px solid var(--background_lighten-dark);

  .h3 {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 19px;
  }

  .have_skill {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    background-color: transparent;

    .skill {
      padding: 3px 13px 4px 13px;
      margin: 0 5px 5px 0;
      width: fit-content;
      height: 31px;
      text-align: center;
      font-size: 14px;
      background: rgba(22, 21, 27, 0.65);
      border: 1px solid rgba(48, 224, 161, 0.6);
      border-radius: 29px;
      white-space: nowrap;
    }
  }
}

/* SkillBlockVacancy */
.tags_block {
  padding: 20px 20px 10px 20px;
  background: var(--dark);
  border-radius: 20px;

  .h3 {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 17px;
  }

  .left_skill {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    background-color: transparent;

    .skill {
      padding: 3px 13px 4px 13px;
      margin: 0 5px 5px 0;
      width: fit-content;
      height: 31px;
      text-align: center;
      font-size: 14px;
      background: rgba(22, 21, 27, 0.65);
      border: 1px solid rgba(48, 224, 161, 0.6);
      border-radius: 29px;
      white-space: nowrap;
    }
  }
}

/* item course */
.reuse_course-item {
  min-width: 297px;
  width: calc(25% - 30px);
  margin: 0 0 35px 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  background: #343843;
  border-radius: 30px;
  order: 4;

  .preview_image {
    width: 100%;
    aspect-ratio: 1.77;
    border-radius: 30px;
  }

  .rate {
    position: absolute;
    padding: 4px 14px;
    top: 25px;
    left: 22px;
    background: rgba(32, 30, 38, 0.6);
    border-radius: 34px;

    ul {
      flex-wrap: nowrap !important;
      max-width: inherit !important;
    }

    .ant-rate {
      font-size: 10px;


    }

    li.ant-rate-star {
      margin-right: 3px;
      margin-bottom: 0;
      background-color: transparent;
    }
  }

  .popular {
    position: absolute;
    top: 23px;
    right: 22px;
    display: flex;
    padding: 2px 3px;
    background: rgba(32, 30, 38, 0.6);
    border-radius: 34px;

    span {
      margin: 0 10px;
      font-size: 14px;
    }
  }

  .category {
    display: flex;
    margin: 0 20px 15px 20px;
    padding: 4px 10px;
    width: fit-content;
    border-radius: 34px;

    img {
      margin-right: 10px;
      align-self: center;
    }

    span {
      font-size: 14px;
    }
  }

  .info {
    margin: 0 20px 7px 20px;
    max-width: 280px;
    font-size: 20px;
    line-height: 24px;
    overflow-wrap: break-word;
  }


  .organization {
    margin: 5px 20px 7px 20px;
    max-width: 280px;
    font-size: 16px;
    line-height: 16px;
    color: var(--text_color_dark);
  }

  .stage {

    ul,
    li {
      padding: 0;
      margin: 0;
    }

    ul {
      margin: 0 20px 5px 20px !important;
      justify-content: flex-start;
    }

    li {
      margin-right: 15px !important;
      display: flex;
      align-items: center;
      white-space: nowrap;
      font-size: 16px;
      color: #A2AACA;
      cursor: help;
      white-space: nowrap;
      font-size: 16px;
      color: var(--text_color_dark);

      span {
        display: flex;
        align-items: center;
      }

      svg {
        margin-left: 5px;
        width: 25px;

        circle {
          fill: #201E26;
        }
      }

      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }

  .title_skill {
    margin: 0 20px 10px 20px;
    font-size: 16px;
  }

  .key_skills {
    margin: 0 20px 10px 20px;
    max-height: 140px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;

    .skills {
      display: flex;
      padding: 4px 10px;
      margin-bottom: 5px;
      position: relative;
      flex-wrap: wrap;
      color: var(--text_color_dark);
      font-size: 14px;
      background: rgba(162, 170, 202, 0.2);
      border: 1px solid transparent;
      border-radius: 16px;

      img {
        position: absolute;
        top: 8px;
        right: 5px;
        cursor: pointer;
      }

      &.active {
        background: rgba(48, 224, 161, 0.2) !important;
        border-color: #30E0A1;

        span {
          color: #30E0A1;
        }
      }

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  .date_course {
    padding: 3px;
    margin: 0 20px 10px 20px;
    width: fit-content;
    background: rgba(32, 30, 38, 0.6);
    border-radius: 34px;

    img,
    svg {
      width: 19px;
      height: 19px;
      vertical-align: sub;
    }

    span {
      margin: 0 7px;
      font-size: 14px;
    }
  }

  .bottom_block {
    margin-top: auto;

    a {
      color: white;
    }
  }

  .cost {
    margin: 0 20px 15px 20px;
    font-size: 16px;

    span {
      margin-right: 5px;
    }
  }

  .status {
    padding: 2px 7px 4px 7px;
    margin: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background: #1D1E25;

    span {
      font-size: 11px;
    }

    span:last-child {
      color: var(--text_color_dark);

      &.discard {
        color: #CF1A1A;
      }
    }
  }

  .group_redirect {
    display: flex;
    margin: 0 20px 15px 20px;

   a {
     width: 100%;
     &.disabled {
       pointer-events: none;
     }
   }

    .view {
      position: relative;
      margin-left: 10px;
      min-width: 46px;
      min-height: 46px;
      width: 46px;
      height: 46px;
      border-radius: 15px;
      box-shadow: 0 9px 20px rgba(48, 119, 255, 0.45);
      background: #3077FF;
      cursor: pointer;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

  }

  .btn_set-rate {
    margin: -5px 20px 25px 20px;
    padding: 5px;
    text-align: center;
    background: #757A88;
    border-radius: 20px;
    cursor: pointer;
  }

  video,
  iframe {
    border-radius: 30px;
  }
}

.progress_block {
  display: flex;
  position: relative;
  margin-left: 20px;

  svg {
    margin-top: 3px;

    &.red {

      path:nth-child(4),
      path:nth-child(6) {
        fill: #FF3434;
      }
    }

    &.fiolet {

      path:nth-child(4),
      path:nth-child(6) {
        fill: #8146FF;
      }
    }
  }

  .progress {
    align-self: flex-end;
    width: 100%;
    margin: 10px;

    .procentague {
      position: absolute;
      top: -5px;
      right: 20px;
    }

    .ant-progress-outer {
      padding-right: 15px;
    }
  }
}

/* input */
.input_classic {
  width: 100%;
  padding-left: 15px;
  background-color: #343843;
  border: 1px solid #474C5A;
  border-radius: 16px;
  font-size: 16px;
  color: white;
  z-index: 3;
  outline: none;

  &:hover {
    border-color: #474C5A !important;
  }

  &:active {
    border-color: var(--text_color_dark) !important;
    outline: none !important;
  }

  &:focus {
    border-color: var(--text_color_dark) !important;
    outline: none !important;
    box-shadow: none;
  }

  &::placeholder {
    color: #757A88;
    font-size: 16px;
  }
}

@media (max-width: 820px) {
  .parent_navbar {
    display: none !important;

    .nav_bar {
      display: none;
    }

    .nav_bar-mobile {
      display: block;
    }
  }
}

/* scroll top */
.btn_scroll-up {
  position: fixed;
  display: none;
  width: 40px;
  height: 40px;
  bottom: 25%;
  right: 20px;
  opacity: 0.8;
  border-radius: 50%;
  background-color: lighten(#474c5a, 5%);
  z-index: 7;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-48%, -50%) rotate(180deg);
  }
}

/* 404_block */
.block_404 {
  width: 100%;
  height: 100vh;
  background: #FFFFFF;

  .img {
    position: relative;
    width: 100%;
    height: 100%;
    background: url("../images/icons/error_404.svg") no-repeat;
    background-position: 100% 100%;
    background-size: cover;
    border-radius: 60px;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 40px;
      line-height: 48px;
      color: white;
    }
  }
}