.content_lesson {
  padding: 1px 30px 1px 1px;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
  //height: 100%;
  /*background: grey;*/
  .title {
    margin-bottom: 20px;
    font-size: 40px;
    line-height: normal;
    overflow-wrap: break-word;
  }

  .h2 {
    margin: 20px 0 15px 0;
    font-size: 24px;
    line-height: normal;
  }

  .image_file {
    max-height: 500px;
    width: 100%;
    max-width: fit-content;
    border-radius: 24px;
    cursor: pointer;
  }

  .task_file {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 18px;
    line-height: normal;
    color: var(--text_color_dark);

    img {
      margin-right: 10px;
    }


    &:hover {
     /* color: white;*/
    }
  }

  .react-player {
    margin-bottom: 15px;
    border-radius: 24px;

    video, iframe {
      border-radius: 24px;
    }
  }

  .text {
    margin-bottom: 15px;
    font-size: 20px;
    line-height: normal;
    /*color: var(--text_color_dark);*/
    color: white;
    overflow-wrap: break-word;
    white-space: pre-wrap;

    a {
      color: var(--blue);
    }
  }

  .links {
    margin-bottom: 20px;
    white-space: pre-wrap;
    overflow: initial;
    font-size: 20px;
    /*color: var(--text_color_dark);*/
    color: white;

    a {
      color: var(--blue);

      &:hover {
        color: white;
      }
    }
  }

  .scorm {
    display: flex;
    flex-direction: column;
    height: 100%;

    iframe {
      min-height: 301px;
      margin-bottom: 20px;
    }
  }

  .homework_answers-list {
    margin: 10px 0 20px;
    width: 100%;

    .lable {
      margin: 0 0 5px 15px;
      color: rgb(162, 170, 202);
      font-size: 16px;
    }

    .ant-upload.ant-upload-drag {
      border-radius: 24px;
      background: var(--dark);
      border: 1px solid var(--background_lighten-dark);

      .ant-upload-text {
        color: white;
      }

      &:hover {
        border-color: var(--text_color_dark);
      }
    }

    .input_answer {
      margin-bottom: 20px;
      padding: 10px 15px 12px;
      transition: all .3s linear;

      &:hover {
        border-color: var(--text_color_dark) !important;
      }
    }

    .homework_answer {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid var(--background_lighten-dark);

      .student_answer {
        padding: 20px;
        margin-bottom: 20px;
        border-radius: 24px;
        border: 1px solid var(--background_lighten-dark);
        background: var(--dark);

        .title {
          margin-bottom: 15px;
          padding-bottom: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid var(--background_lighten-dark);

          span:first-child {
            font-size: 24px;
            line-height: normal;
          }

          span:last-child {
            color: #757A88;
            font-size: 16px;
            line-height: normal;
          }
        }

        .answer {
          display: flex;

          span:first-child {
            margin-right: 7px;
            color: #757A88;
            font-size: 16px;
          }

          span:last-child {
            font-size: 16px;
          }
        }

        .attached_file {
          display: flex;
          margin-top: 15px;

          span {
            margin-right: 7px;
            color: #757A88;
            font-size: 16px;
          }

          a {
            text-decoration: none;
            font-size: 16px;
            color: white;
          }
        }
      }

      .teacher_answer {
        padding: 20px;
        margin-bottom: 20px;
        border-radius: 24px;
        border: 1px solid transparent;
        background: var(--dark);

        .answer {
          display: flex;

          span:first-child {
            margin-right: 7px;
            color: #757A88;
            font-size: 16px;
          }

          span:last-child {
            font-size: 16px;
          }
        }

        .attached_file {
          margin-top: 15px;

          span {
            margin-right: 7px;
            color: #757A88;
            font-size: 16px;
          }

          a {
            text-decoration: none;
            font-size: 16px;
            color: white;
          }
        }

        .time_teachers_answer {
          display: flex;
          margin-top: 15px;

          span:first-child {
            margin-right: 7px;
            color: #757A88;
            font-size: 16px;
          }

          span:last-child {
            font-size: 16px;
          }
        }

        &.denied {
          border-color: #FF3434;

          .answer {
            span:last-child {
              color: #FF3434;
            }
          }
        }

        &.success {
          border-color: #30E0A1;

          .answer {
            span:last-child {
              color: #30E0A1;
            }
          }
        }
      }

      &:first-child {
        padding-top: 0;
        border: none;
      }
    }

    .status {
      margin: 20px 0;
      color: #faad14;
      font-weight: 600;
    }
  }
}

