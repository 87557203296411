.scorm {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 30px) !important;
  min-height: calc(100vh - 30px) !important;
  & > .fullscreen_btn {
    position: absolute;
    top: 10px;
    right: 5px;
    padding: 14px;
    border-radius: 15px;
    background-color: rgb(48, 119, 255);
    font-size: 14px;
    line-height: 1;
    cursor: pointer;
  }
  iframe {
    min-height: 600px;
    margin-bottom: 20px;
  }

  .title {
    margin-bottom: 20px;
    font-size: 40px;
    line-height: normal;
    overflow-wrap: break-word;
  }

  &.fullscreen {
    iframe {
      min-height: 800px;
      height: 100%;
    }
    .title {
      line-height: 1;
    }
  }
}