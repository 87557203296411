@use 'src/styles/Variables';

@media (max-width: 1620px) {
  .auth {
    .main {
      min-width: 655px !important;
    }
  }

}

@media (max-width: 1318px) {
  .auth {
    .main {
      z-index: 1;
    }
  }
}

@media (max-width: 1318px) {
  .block_parralax {
    // display: none !important;
    z-index: -1 !important;
  }
  .no_parallax {
    display: block !important;
    opacity: 1 !important;
  }
}

@media (max-width: 819px) {
  .qr_block {
    display: none !important;
  }
  .line_ore {
    display: none !important;
  }
  .smsForm {
    margin-top: 20px !important;
  }
  // modal window
}

@media (max-width: 720px) {
  .auth {
    .block_parralax {
      display: none !important;
    }

    .main {
      margin: 15px auto auto auto !important;
      max-width: 400px !important;
      width: 100% !important;
      min-width: initial !important;
      padding: 20px;

      .input_classic {
        max-width: inherit;
      }

      .inner {
        margin: 0;
        width: 100%;
      }
    }

    .no_parallax {
      display: none !important;
    }

    h1 {
      margin-bottom: 22px !important;
      font-size: 24px !important;
      line-height: 29px !important;
      width: fit-content !important;
    }

    .mobile_block-image {
      opacity: 1 !important;
    }

    .entered_code {
      .button {
        max-width: 100% !important;
      }
    }

    .line_ore {
      display: none !important;
    }

    .step_first,
    .step_third,
    .step_fifth,
    .step_seventh {
      max-width: 32px !important;
      max-height: 32px !important;
      min-width: 32px !important;
      min-height: 32px !important;
      line-height: 32px !important;
      padding-bottom: 20px !important;

      span {
        font-size: 14px !important;
        line-height: 17px;
      }
    }

    .drop_down-language {
      top: 20px !important;
      right: 20px !important;
    }

    .form_item-send-sms {
      margin-bottom: 0 !important;
    }

    .profile_block {
      flex-direction: column !important;
      margin-bottom: 0 !important;
      margin-top: 20px !important;

      .left_block {
        margin-bottom: 20px !important;

        .button_next {
          display: none !important;
        }
      }

      .right_block {
        margin-left: 0 !important;

        .fio {
          div:first-child {
            margin-bottom: 20px !important;
          }
        }

        .button_next {
          display: block !important;
        }
      }

      .mailing {
        margin-top: 22px !important;

        span {
          margin-left: 15px !important;
        }
      }
    }

    .step_3,
    .step_4 {
      .top_block {
        flex-direction: column;

        .right_block {
          margin-top: 20px;
          margin-left: 0;
        }
      }

      .next_button {
        width: auto !important;
        padding-bottom: 0 !important;
      }
    }

  }
}

@media (max-width: 500px) {
  .smsForm {
    margin-top: 20px;
  }
  .qrCodeList {
    text-align: center;
    border: 1px solid black;
    display: none;
  }
}

@media (max-width: 425px) {
  .auth {
    .main {
      padding: 15px 15px 5px 15px !important;
      margin: 15px !important;

      .drop_down-language {
        top: 15px !important;
      }
    }
  }
}

@media (max-width: 315px) {
  .auth {
    h1 {
      margin: 0 auto 50px auto !important;
    }

    .drop_down-language {
      top: 52px !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
    }
  }
}

@media (max-width: 280px) {
  .auth {
    overflow: inherit !important;
  }
  .profile_block {
    .left_block {
      min-width: auto !important;
    }
  }
  .step_3,
  .step_4 {
    .top_block {
      .left_block {
        min-width: auto !important;
      }
    }
  }
  .mobile_block-image {
    .right_bottom {
      left: 180px !important;
    }
  }
}

.auth {
  display: flex;
  height: 100vh;
  width: 100%;
  /*overflow: auto;*/
  /*overflow-x: hidden;*/
  background: #16151B;

  .main {
    position: relative;
    margin: auto;
    min-width: 40%;
    max-width: 716px;
    width: 100%;
    height: fit-content;
    padding: 20px 20px 5px 20px;
    border-radius: 40px;
    box-shadow: 0 10px 60px rgba(0, 0, 0, 0.13);
    border: 1px solid #343843;
    background-color: #201E26;
    z-index: 3;

    .inner {
      width: 95%;
      margin: 0 auto;

      h1 {
        font-size: 42px;
        color: white;
        text-align: center;

        .freelance_info {
          margin: 20px auto 0 auto;
          max-width: 635px;
          width: 100%;
          font-size: 16px;
          line-height: 19px;
          color: #757A88;
        }
      }

      .drop_down-language {
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 95px;
        width: 100%;
        right: 40px;
        top: 40px;
        padding: 4px 9px;
        background: var(--dark);
        border: 1px solid var(--background_lighten-dark);
        border-radius: 17px;
        color: white;
        cursor: pointer;
        z-index: 5;

        img {
          max-height: 15px;
          max-width: 21px;
        }

        svg {
          transition: all .5s;
          min-height: 5px;
          min-width: 10px;
        }

        .drop_down {
          position: absolute;
          top: 18px;
          left: -1px;
          padding-top: 10px;
          opacity: 0;
          transform: scale(0);
          max-height: 0;
          border-radius: 0 0 17px 17px;
          width: calc(100% + 2px);
          border: 1px solid var(--background_lighten-dark);
          background-color: var(--dark);
          border-top: 1px solid transparent;
          transition: all .3s;
          z-index: -2;

          .language {
            display: flex;
            padding: 4px 10px 4px 9px;
            align-items: center;
            cursor: pointer;

            img {
              max-height: 15px;
              max-width: 21px;
              margin-right: 9px;
            }

            &:hover {
              background-color: var(--background_lighten-dark);
            }

            &:last-child {
              border-radius: 0 0 17px 17px;
            }
          }

          &.open {
            transition: all .3s;
            opacity: 1;
            transform: scale(1);
            max-height: 150px;
          }
        }

        &.open {
          svg {
            transition: all .5s;
            transform: rotate(180deg);
          }
        }
      }

      .input_classic {
        width: 100%;
      }

      .qrCodeList {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: center;
        width: 100%;
        flex-wrap: wrap;
        z-index: 3;
      }
    }
  }

  .overlay {
    position: fixed;
    background-color: transparent;
    left: -1300px;
    top: -355px;
    width: 1px;
    height: 1px;
    content: '';

    &.active {
      width: 500vw;
      height: 500vh;
    }
  }

  // шаги style
  .step_custom {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .step_first,
    .step_third,
    .step_fifth,
    .step_seventh {
      position: relative;
      min-width: 50px;
      min-height: 50px;
      text-align: center;
      line-height: 43px;
      background: #343843;
      border: 1px solid #474C5A;
      border-radius: 40px;

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 24px;
      }

      &.active {
        background: #3077FF;
        box-shadow: 0 9px 10px rgba(48, 119, 255, 0.27);
        border-color: transparent;
      }
    }

    .step_second,
    .step_fourth,
    .step_six {
      position: relative;
      width: 100%;
      margin: 0 10px;
      align-self: center;

      .line {
        width: 100%;
        height: 1px;
        background-color: #343843;
      }
    }
  }

  // step_1 текст
  .ant-list-header {
    padding-bottom: 5px !important;
  }

  .ant-list-split {
    .ant-list-item,
    .ant-list-header {
      padding: 17px 0;
      border-color: var(--dark);
    }

    .ant-list-item {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  // antd statisctic
  .ant-statistic {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    &-title {
      font-size: 20px;
      color: #757A88;
      align-self: center;
    }
  }

  // line или
  .line_ore {
    margin: 25px 0 20px 0;
    width: 100%;
    display: flex;
    justify-content: space-around;

    span {
      margin: 0 10px;
      font-size: 18px;
      color: white;
    }

    .line {
      width: 100%;
      height: 1px;
      align-self: center;
      background-color: #343843;
    }
  }

  /* step_2 */
  // custom checkbox toggle
  .switch {
    display: inline-block;
    position: relative;
    margin-top: 2px;
    height: 20px;
    width: 48px;

    input {
      display: none;
    }

    .slider {
      background-color: darken(#343843, 5%);
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      left: 0;
      transition: .4s;
      cursor: pointer;
    }

    .slider:before {
      position: absolute;
      height: 16px;
      width: 16px;
      left: 4px;
      bottom: 2px;
      content: "";
      background-color: #3077FF;
      border: 1px solid #FFFFFF;
      box-shadow: 0 7px 9px rgba(0, 0, 0, 0.43);
      transition: .4s;
    }

    input:checked + .slider {
      background-color: #343843;
    }

    input:checked + .slider:before {
      transform: translateX(26px);
    }

    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }

  /* step_3 */
  .step_3,
  .step_4 {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    .top_block {
      display: flex;
      transition: all .4s ease;

      .left_block {
        width: 100%;
        margin-right: 12px;
        z-index: 2;

        p {
          margin-left: 15px;
          margin-bottom: 5px;
          color: var(--text_color_dark);
          font-size: 14px;
          line-height: 17px;
          white-space: nowrap;
        }

        .drop_down {
          position: relative;
          padding: 8px 15px 8px 15px;
          background: #8146FF;
          border-radius: 16px;
          cursor: pointer;
          color: white;
          font-size: 14px;
          z-index: 1;

          img {
            margin-right: 14px;
            width: 16px;
            height: 16px;
          }

          span {
            vertical-align: middle;
          }

          .arrow {
            position: absolute;
            top: 9px;
            right: 18px;
            transition: transform .3s;

            &.tranform {
              top: 12px;
              transform: rotate(180deg);
            }
          }

          &-list {
            width: 100%;
            padding-right: 17px;
            max-height: 0;
            background-color: #8146FF;
            border-radius: 0 0 16px 16px;
            transition: all .3s ease;

            ul {
              margin: 0;
              padding: 10px 0 0 0;
            }

            li {
              padding: 11px 0;
              border-top: 1px solid rgba(255, 255, 255, 0.2);
              color: white;
              font-size: 14px;
              cursor: pointer;

              &:last-child {
                padding-bottom: 0;
              }
            }

            &.active {
              max-height: 260px;
              opacity: 1 !important;
              transform: scale(1) !important;
            }
          }

          &.open {
            height: 98%;
          }
        }
      }

      .right_block {
        width: 100%;
        position: relative;
        z-index: 3;

        p {
          margin-left: 15px;
          margin-bottom: 5px;
          color: var(--text_color_dark);
          font-size: 14px;
          line-height: 17px;
          white-space: nowrap;
        }

        .add_activity-icon {
          position: absolute;
          top: 28.5px;
          right: 8px;
          z-index: 3;
        }
      }
    }

    .popular {
      margin: 20px 0;
      color: white;
      font-size: 20px;
      line-height: 24px;
    }

    .bottom_block {
      margin-bottom: 20px;
      padding: 12px 12px 8px 12px;
      background-color: #343843;
      border-radius: 20px;

      .bottom_tags {
        display: flex;
        flex-wrap: wrap;
        max-width: fit-content;

        div {
          padding: 3px 25px 5px 10px;
          margin-bottom: 5px;
          position: relative;
          white-space: nowrap;
          color: var(--text_color_dark);
          font-size: 14px;
          line-height: 17px;
          background: rgba(162, 170, 202, 0.2);
          border-radius: 34px;

          img {
            position: absolute;
            top: 5px;
            right: 5px;
          }

          &:not(:last-child) {
            margin-right: 7px;
          }
        }
      }
    }

    .btn_block {
      margin-bottom: 15px;
      width: 100%;
      z-index: 3;
      display: flex;
    }
  }

  // antd styles drop_down
  .activity_select {
    display: block;
    transition: all .4s ease;
    min-width: -webkit-fill-available;

    &:hover {
      border: none;
      outline: none;
      box-shadow: none !important;
    }

    .ant-select-selector {
      padding: 4px 37px 0 5px;
      min-height: 39px;
      max-width: 690px;
      border-radius: 16px !important;
      background-color: var(--dark) !important;
      border: 1px solid var(--background_lighten-dark) !important;
      box-shadow: none !important;
      color: white;
    }

    &.active {
      .ant-select-selector {
        border-radius: 16px 16px 0 0 !important;
        border-color: transparent !important;
      }
    }

    input#rc_select_0,
    input#rc_select_1 {
      color: white;
    }
  }

  .ant-select-dropdown {
    box-shadow: none !important;
    border-radius: 0 0 16px 16px;
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-grouped {
    color: white !important;
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-grouped.ant-select-item-option-active {
    background-color: var(--text_color_dark);
  }

  .ant-select-item.ant-select-item-group {
    color: var(--text_color_dark) !important
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-grouped.ant-select-item-option-selected {
    background-color: darken(#A2AACA, 5%) !important;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: white !important;
  }

  /* step_4 */
  .step_4 {
    .top_block {
      p {
        margin-left: 15px;
      }

      .right_block {
        margin-left: 0;
      }
    }
  }

  .ant-result-title {
    color: white;
  }
}

.left_top {
  position: absolute;
  top: 63px;
  left: 45px;
}

.left_middle {
  position: absolute;
  top: 486px;
  left: 0;
}

.left_bottom {
  position: absolute;
  bottom: 0;
  left: 87px;
}

.right_top {
  position: absolute;
  top: 50px;
  right: 77px;
}

.right_middle {
  position: absolute;
  top: 445px;
  right: 0;
}

.right_bottom {
  position: absolute;
  bottom: 0;
  right: 102px;
}

